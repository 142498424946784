export const carrierFilters = [
  {
    label: "Carrier Owners",
    value: "owner",
  },
  { label: "Carrier Name", value: "name" },
  { label: "MC#", value: "mc_number" },
  { label: "DOT#", value: "dot_number" },
  {
    label: "Description",
    value: "description",
  },
  { label: "Email", value: "email" },
  { label: "Phone", value: "formatted_phone" },
  { label: "Phone Ext", value: "phone_ext" },
  {
    label: "Last Activity Date",
    value: "last_activity_date",
  },
  { label: "Fleet Size", value: "fleet_size" },
  {
    label: "Created Date",
    value: "created_date",
  },
  { label: "Status", value: "status_id" },
  { label: "Home Base", value: "address" },
  {
    label: "Last Edit Date",
    value: "last_updated",
  },
  {
    label: "Batch",
    value: "batch",
  },
  {
    label: "Age",
    value: "age",
  },
];

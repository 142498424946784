import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import axios from "axios";
import DropdownDownload from "../../Dropdown/DropdownDownload";
import { NotificationManager } from "react-notifications";
class LineChart extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      xAxis: {
        categories: ["A", "B", "C"],
      },
      yAxis: {
        title: {
          text:
            this.props.yAxis === "total_revenue"
              ? "Total Revenue"
              : this.props.yAxis === "total_shipments"
              ? "Total Shipments"
              : `Number of ${this.props.type}`,
        },
      },
      credits: {
        enabled: false,
      },
      series: [{ data: [1, 2, 3] }],
      title: {
        text: "Line Chart",
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: this.setHoverData,
            },
          },
          lang: { thousandsSep: "," },
          dataLabels: {
            enabled: true,
            // eslint-disable-next-line no-template-curly-in-string
            format: `{y} ${
              this.props.yAxis === "total_revenue" ? this.props.currency : ""
            }`,
          },
        },
      },
    },
    hoverData: null,
    downloadLoader: false,
  };

  componentDidMount() {
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        series: [
          {
            name: this.props.type,
            data:
              this.props.yAxis === "total_revenue"
                ? this.props.data?.map((d) => d.revenue)
                : this.props.yAxis === "no_of_carrier"
                ? this.props.data?.map((d) => d.no_of_carrier)
                : this.props.data?.map((d) => d.count),
          },
        ],
        dataLabels: {
          enabled: true,
          // eslint-disable-next-line no-template-curly-in-string
          format: `{y} ${
            this.props.yAxis === "total_revenue" ? this.props.currency : ""
          }`,
        },
        yAxis: {
          title: {
            text:
              this.props.yAxis === "total_revenue"
                ? "Total Revenue"
                : this.props.yAxis === "total_shipments"
                ? "Total Shipments"
                : `Number of ${this.props.type}`,
          },
        },
        xAxis: {
          categories: this.props.data?.map((d) => {
            let name = d.source;
            name =
              this.props.segmentBy === "source"
                ? d.source
                : this.props.segmentBy === "account_owner"
                ? d.account_owner
                : this.props.segmentBy === "account_name"
                ? d.name
                : this.props.segmentBy === "last_shipment_date"
                ? d.last_shipment_date
                : this.props.segmentBy === "contact_owner"
                ? d.contact_owner
                : this.props.segmentBy === "carrier_owner"
                ? d.carrier_owner
                : this.props.segmentBy === "opportunity_owner"
                ? d.name
                : this.props.segmentBy === "created_date"
                ? d.created_date
                : this.props.segmentBy === "close_date"
                ? d.close_date
                : this.props.segmentBy === "won_date"
                ? d.close_date
                : this.props.segmentBy === "stage"
                ? d.name
                : this.props.segmentBy === "duration"
                ? d.name
                : this.props.segmentBy === "type"
                ? d.type
                : this.props.segmentBy === "activity_owner"
                ? d.activity_owner
                : this.props.segmentBy === "completion_date"
                ? d.completion_date
                : this.props.segmentBy === "result"
                ? d.result
                : this.props.segmentBy === "status"
                ? d.status
                : d.cf_value;
            return name === null
              ? Number.isInteger(parseInt(this.props.segmentBy))
                ? `No value`
                : `No ${this.props.segmentBy}`
              : name;
          }),
        },
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: [
            {
              name: this.props.type,
              data:
                this.props.yAxis === "total_revenue"
                  ? this.props.data?.map((d) => d.revenue)
                  : this.props.yAxis === "no_of_carrier"
                  ? this.props.data?.map((d) => d.no_of_carrier)
                  : this.props.data?.map((d) => d.count),
            },
          ],
          dataLabels: {
            enabled: true,
            // eslint-disable-next-line no-template-curly-in-string
            format: `{y} ${
              this.props.yAxis === "total_revenue" ? this.props.currency : ""
            }`,
          },
          yAxis: {
            title: {
              text:
                this.props.yAxis === "total_revenue"
                  ? "Total Revenue"
                  : this.props.yAxis === "total_shipments"
                  ? "Total Shipments"
                  : `Number of ${this.props.type}`,
            },
          },
          xAxis: {
            categories: this.props.data?.map((d) => {
              let name = d.source;
              name =
                this.props.segmentBy === "source"
                  ? d.source
                  : this.props.segmentBy === "account_owner"
                  ? d.account_owner
                  : this.props.segmentBy === "account_name"
                  ? d.name
                  : this.props.segmentBy === "last_shipment_date"
                  ? d.last_shipment_date
                  : this.props.segmentBy === "contact_owner"
                  ? d.contact_owner
                  : this.props.segmentBy === "carrier_owner"
                  ? d.carrier_owner
                  : this.props.segmentBy === "opportunity_owner"
                  ? d.name
                  : this.props.segmentBy === "created_date"
                  ? d.created_date
                  : this.props.segmentBy === "close_date"
                  ? d.close_date
                  : this.props.segmentBy === "won_date"
                  ? d.close_date
                  : this.props.segmentBy === "stage"
                  ? d.name
                  : this.props.segmentBy === "duration"
                  ? d.name
                  : this.props.segmentBy === "type"
                  ? d.type
                  : this.props.segmentBy === "activity_owner"
                  ? d.activity_owner
                  : this.props.segmentBy === "completion_date"
                  ? d.completion_date
                  : this.props.segmentBy === "result"
                  ? d.result
                  : this.props.segmentBy === "status"
                  ? d.status
                  : d.cf_value;
              return name === null
                ? Number.isInteger(parseInt(this.props.segmentBy))
                  ? `No value`
                  : `No ${this.props.segmentBy}`
                : name;
            }),
          },
        },
      });
    }
  }

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };
  download = (typeParam) => {
    let downloadType =
      typeParam === "pdf"
        ? "application/pdf"
        : typeParam === "png"
        ? "image/png"
        : typeParam === "svg+xml"
        ? "image/svg+xml"
        : "image/jpeg";
    let data = {
      options: JSON.stringify(this.state.chartOptions),
      filename: "LineChart",
      type: downloadType,
      b64: true,
    };
    this.setState({
      downloadLoader: true,
    });

    axios({
      method: "post",
      url: "https://export.highcharts.com/",
      data: data,
    })
      .then((response) => {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:${downloadType};base64,${response.data}`; //Image Base64 Goes here
        a.download =
          typeParam === "svg+xml" ? `Chart.xml` : `Chart.${typeParam}`; //File name Here
        a.click();
        this.setState({
          downloadLoader: false,
        });
      })
      .catch((error) => {
        //handle error
        NotificationManager.error("Error downloading chart");
        console.log(error);
        this.setState({
          downloadLoader: false,
        });
      });
  };

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        {!this.props.fromDashboard && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "37px",
            }}
          >
            <DropdownDownload
              download={this.download}
              downloadLoader={this.state.downloadLoader}
            />
          </div>
        )}
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default LineChart;

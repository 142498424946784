import React, { Component } from "react";
import axios from "axios";
import { debounce } from "lodash";
import OppModal from "../OppModal";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import StageReasonModal from "./StageReasonModal";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
import CreateDealModal from "../CreateModal/CreateDealModal";
import { CurrencyList } from "../../constants/currencylist";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import { Plus } from "lucide-react";

class DealsTabList extends Component {
  state = {
    opportunities: [],
    activePage: 1,
    total: 0,
    showContactModal: false,
    showNewOpportunityModal: false,
    showStageModal: false,
    stageId: this.props.stageId ? this.props.stageId : "",
    userId: this.props.userId ? this.props.userId : "",
    stages: [],
    sortDirection: true,
    currentStage: {},
    isApplying: false,
    closeDate: this.props.closeDatePeriod
      ? this.props.closeDatePeriod
      : "all_days",
    funnelId: this.props.funnelId ? this.props.funnelId : "",
    totalRevenue: 0,
    created: this.props.period,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
    },
    currentStageId: "",
    currentDealData: {},
    currentStageType: "",
    userName: this.props.authUser.name,
    user_ids: this.props.userId,
    displayMenu: false,
    displayUserName: this.props.userName,
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
      },
      () => this.fetchDeals()
    );
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: true });
  };

  handleCloseNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: false });
  };
  handleOpenModal = (propertyValue, dealData, type) => {
    this.setState(
      {
        showStageModal: true,
      },
      () => this.openStageModal(propertyValue, dealData, type)
    );
  };
  handleCloseModal = () => {
    this.setState({
      showStageModal: false,
    });
  };
  tableHeaders = [
    {
      value: "opportunity_owner",
      label: "Deal Owner",
      sort: true,
      editable: false,
      type: "text",
    },
    {
      value: "name",
      label: "Deal Name",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "account_name",
      label: "Account Name",
      sort: true,
      editable: false,
      type: "text",
    },
    {
      value: "close_reason",
      label: "Close Reason",
      sort: false,
      editable: false,
      type: "text",
    },
    {
      value: "stage_name",
      label: "Stage",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "revenue",
      label: "Revenue",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "close_date",
      label: "Close Date",
      sort: true,
      editable: true,
      type: "date",
    },
    {
      value: "last_activity_date",
      label: "Last Activity Date",
      sort: true,
      editable: false,
      type: "date",
    },
    {
      value: "next_step",
      label: "Next Step",
      sort: false,
      editable: true,
      type: "text",
    },
    {
      value: "stage_age",
      label: "Stage age",
      sort: false,
      editable: false,
      type: "text",
    },
  ];
  renderTableData = (dealData, tableData, name, value, dateFormat) => {
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    let newTabData = {
      type: "opportunity",
      id: dealData.id,
      name: dealData.name,
      account_id: dealData.account_id,
    };
    let accountTabData = {
      type: "account",
      id: dealData.account_id,
      name: dealData.account_name,
    };
    let renderItem = <td>{value}</td>;
    if (!tableData.editable) {
      if (tableData.type === "date") {
        renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
      } else if (name === "account_name") {
        renderItem = value ? (
          <td>
            <span
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({
                    ...accountTabData,
                    blank: true,
                  });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(accountTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
          </td>
        ) : (
          <td></td>
        );
      } else if (name === "close_reason") {
        renderItem =
          this.state.currentStage?.type === "won" ||
          this.state.currentStage?.type === "lost" ? (
            <td>{value}</td>
          ) : null;
      } else {
        renderItem = <td>{value}</td>;
      }
    } else if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: dealData.id,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === dealData.id &&
                this.state.showNameInput.isTrue === true
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(dealData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {name === "revenue" && value && currencySymbol.symbol}{" "}
            {name === "revenue" && value
              ? getCurrencyValue(value)?.toLocaleString("en-US")
              : value}
          </span>
          <input
            className="inlineReportEdit-input"
            type="text"
            value={value}
            onChange={(e) =>
              name === "revenue"
                ? this.fieldRevenueChangeHandler(dealData, name, e.target.value)
                : this.fieldChangeHandler(dealData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      const newDate = value ? new Date(value) : new Date();
      const offsetDateCalc =
        newDate === null || newDate === ""
          ? new Date()
          : newDate.getTimezoneOffset() < 0
          ? new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
          : new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
      const offsetDate = offsetDateCalc;
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value && moment(value).format(dateFormat)}
          </span>
          <DatePicker
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
            selected={offsetDate}
            autoComplete="off"
            todayButton="Today"
            utcOffset={0}
            value={offsetDate}
            onChange={(date) => {
              this.fieldChangeHandler(dealData, name, date);
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              });
            }}
            placeholderText="Date"
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            className="inlineReportEdit-input"
          />
        </td>
      );
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={dealData.stage_id}
            onChange={(e) =>
              this.fieldChangeHandler(dealData, "stage_id", e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            {this.state.stages?.map((stage) => (
              <option value={stage.id}>{stage.name}</option>
            ))}
          </select>
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (deal, name, value) => {
    let dealData = deal;
    dealData = { ...deal, [name]: value === "" ? null : value };
    this.setState({
      opportunities: this.state.opportunities?.map((opp) => {
        if (opp.id === deal.id) {
          if (name === "stage_id") {
            const stage_name = this.state.stages?.find(
              (stage) => stage.id.toString() === value.toString()
            );
            opp = { ...deal, [name]: value, stage_name: stage_name.name };
          } else {
            opp = { ...deal, [name]: value === "" ? null : value };
          }
        }
        return opp;
      }),
    });
    this.submitHandler(dealData, name, value);
  };
  fieldRevenueChangeHandler = (deal, name, value) => {
    let dealData = deal;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      dealData = { ...deal, [name]: value === "" ? null : value };
      this.setState({
        opportunities: this.state.opportunities?.map((opp) => {
          if (opp.id === deal.id) {
            opp = { ...deal, [name]: value === "" ? null : value };
          }
          return opp;
        }),
      });
      this.submitHandler(dealData, name, value);
    }
  };
  submitHandler = (dealData, propertyName, propertyValue) => {
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (propertyName === "stage_id") {
      const newStage = this.state.stages.find(
        (stage) => stage.id === parseInt(propertyValue)
      );
      if (newStage.type === "won" || newStage.type === "lost") {
        return this.setState(
          {
            currentStageId: propertyValue,
            currentDealData: dealData,
            currentStageType: newStage.type,
          },
          () => this.handleOpenModal(propertyValue, dealData, newStage.type)
        );
      }
    }
    if (
      dealData.name === null ||
      dealData.close_date === null ||
      dealData.name === "" ||
      dealData.close_date === ""
    ) {
      return;
    }
    if (
      (this.state.currentStage?.type === "won" ||
        this.state.currentStage?.type === "lost") &&
      (dealData.close_reason_value === "" ||
        dealData.close_reason_value === null)
    ) {
      return false;
    }
    if (
      dealData.email !== null &&
      dealData.email !== "" &&
      !emailValidator.test(dealData.email)
    ) {
      return false;
    }
    const calculatedRevenue = getCurrencyValue(dealData.revenue);
    axios({
      method: "PUT",
      url: `/opportunities/${dealData.id}`,
      data: {
        ...(dealData.account_id && { account_id: dealData.account_id }),
        name: dealData?.name,
        revenue: calculatedRevenue,
        stage_id: dealData?.stage_id,
        funnel_id: dealData?.funnel_id || this.state.funnelId,
        close_date: dealData.close_date,
        description: dealData.description,
        opportunity_owner_id: dealData.opportunity_owner_id,
        close_reason: dealData?.close_reason_id,
        custom_fields: dealData.custom_fields,
        email: dealData.email,
        next_step: dealData.next_step,
        formatted_phone:
          dealData.formatted_phone === undefined ||
          dealData.formatted_phone === "undefined"
            ? null
            : dealData.formatted_phone !== null
            ? dealData.formatted_phone.toString()
            : dealData.formatted_phone,
        website:
          dealData.website === null || dealData.website === undefined
            ? null
            : dealData.website?.startsWith("https://") ||
              dealData.website?.startsWith("http://")
            ? dealData.website
            : "https://" + dealData.website,
      },
    })
      .then((response) => {
        // NotificationManager.success("Deal edited successfully.");
      })
      .catch((error) => {
        NotificationManager.error("Error editing deal.");
      });
  };
  openStageModal = (stage_id, dealData, currentStageType) => {
    return (
      <StageReasonModal
        showOpportunityModal={this.state.showStageModal}
        setShowOpportunityModal={this.handleOpenModal}
        handleCloseModal={this.handleCloseModal}
        stage_id={stage_id}
        opportunityId={dealData?.id}
        customerId={dealData?.account_id}
        contactId={dealData?.contact_id}
        state={{
          ...dealData,
          customFields: dealData.custom_fields,
          funnel_id: this.state.funnelId,
        }}
        currentStageType={currentStageType}
        fetchOpportunitiesData={this.fetchDeals}
        getConfetti={() => console.log("hi")}
        funnels={this.state.allFunnels}
        funnelId={this.state.funnelId}
        users={this.props.allUsers}
        ownerId={dealData?.opportunity_owner_id}
      />
    );
  };
  fetchDeals = debounce((query, page = 1, sortKey = "created_date") => {
    let url = `/opportunities?_limit=30&_page=${page}`;
    if (this.state.user_ids !== "") {
      url += `&user_ids=${this.state.user_ids}`;
    }
    if (query) {
      url += `&name=${query}`;
    }
    if (this.state.stageId === "showActiveStages") {
      const stageId = this.state.stages
        ?.filter((stage) => stage.type !== "won" && stage.type !== "lost")
        .map((stage) => stage.id)
        .join();
      url += `&stage_id=${stageId}`;
    } else if (this.state.stageId !== "") {
      url += `&stage_id=${this.state.stageId}`;
    } else {
      const stageId = this.state.stages?.map((stage) => stage.id).join();
      url += `&stage_id=${stageId}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (this.props.period) {
      let activityPeriod = "";
      if (this.state.created === "daily") {
        activityPeriod = "today";
      } else if (this.state.created === "weekly") {
        activityPeriod = "this_week";
      } else if (this.state.created === "monthly") {
        activityPeriod = "this_month";
      } else if (this.state.created === "quarterly") {
        activityPeriod = "this_quarter";
      } else if (this.state.created === "yearly") {
        activityPeriod = "this_year";
      }
      url += `&created=${activityPeriod}`;
    }
    if (this.state.closeDate) {
      url += `&total_days=${this.state.closeDate}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        this.setState({
          opportunities: res.data.opportunities,
          total: res.data.total,
          isApplying: false,
          totalRevenue:
            res.data.total_revenue === null ? 0 : res.data.total_revenue,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  componentDidMount() {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel =
        this.state.funnelId !== ""
          ? res.data.funnels
              ?.map((funnel) => {
                return funnel.id === parseInt(this.state.funnelId) && funnel;
              })
              .filter((funnel) => funnel !== false)
          : res.data.funnels
              ?.map((funnel) => funnel.is_default === true && funnel)
              .filter((funnel) => funnel !== false);
      this.setState(
        {
          allFunnels: res.data.funnels,
          selectedFunnel: selectedFunnel[0],
          funnelId: selectedFunnel[0].id,
          stages: selectedFunnel[0].stages.map((stage) => {
            stage.destroy = false;
            return stage;
          }),
        },
        () => (this.state.stageId !== "" ? this.findStage() : this.fetchDeals())
      );
    });
  }
  funnelChangeHandler = (e) => {
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(value) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState(
        {
          allFunnels: res.data.funnels,
          selectedFunnel: selectedFunnel[0],
          selectedFunnelName: selectedFunnel[0].name,
          stages: selectedFunnel[0].stages?.map((stage) => {
            stage.destroy = false;
            return stage;
          }),
          stageId: "",
          funnelId: value,
        },
        this.fetchDeals
      );
    });
  };
  findStage = () => {
    const checkStage = this.state?.stages.find(
      (stage) => stage.id === parseInt(this.state.stageId)
    );
    this.setState({ currentStage: checkStage }, () => this.fetchDeals());
  };
  stageChangeHandler = (e) => {
    const { value } = e.target;
    const checkStage = this.state.stages.find(
      (stage) => stage.id === parseInt(value)
    );
    this.setState({ stageId: value, currentStage: checkStage }, () =>
      this.fetchDeals()
    );
  };
  closeDateChangeHandler = (e) => {
    const { value } = e.target;
    this.setState({ closeDate: value }, () => this.fetchDeals());
  };
  createdDateChangeHandler = (e) => {
    const { value } = e.target;
    this.setState({ created: value }, () => this.fetchDeals());
  };
  render() {
    const { opportunities, total, currentStage, totalRevenue } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Deals ({this.state.displayUserName}){" "}
          <span style={{ color: "#4A9876", marginRight: "6px" }}>{total}</span>{" "}
          | <span style={{ marginLeft: "6px" }}>Revenue</span>{" "}
          <span style={{ color: "#4A9876" }}>
            {currencySymbol.symbol}
            {parseFloat(totalRevenue)?.toLocaleString("en-US")}
          </span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        {this.state.showContactModal && (
          <OppModal
            showOpportunityModal={this.state.showContactModal}
            setShowOpportunityModal={this.handleOpenContactModal}
            handleCloseModal={this.handleCloseContactModal}
            fromNav={true}
          />
        )}
        <CreateDealModal
          showOpportunityModal={this.state.showNewOpportunityModal}
          setShowOpportunityModal={this.handleOpenNewOpportunityModal}
          handleCloseModal={this.handleCloseNewOpportunityModal}
          fromNav={true}
        />
        {this.openStageModal(
          this.state.currentStageId,
          this.state.currentDealData,
          this.state.currentStageType
        )}
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Deals"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchDeals(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            className="button-md"
            type="button"
            onClick={
              this.props.isDealFieldsModified
                ? this.handleOpenNewOpportunityModal
                : this.handleOpenContactModal
            }
            style={{ float: "right" }}
          >
            Add Deal
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
        </div>
        <div style={{ margin: "10px 0" }}>
          <span style={{ fontWeight: "500" }}>
            Select Funnel : &nbsp;&nbsp;
          </span>
          <select
            value={this.state.funnelId}
            onChange={this.funnelChangeHandler}
            className="c-icon-1"
            style={{
              background: "#EEF6F2",
              color: "#FFB202",
              width: "130px",
              outline: "none",
            }}
          >
            {this.state.allFunnels?.map((funnel) => (
              <option key={funnel.id} value={funnel.id}>
                {funnel.name}
              </option>
            ))}
          </select>
          <div className="dealsList-closeDate">
            <span style={{ fontWeight: "500" }}>
              Filter by Stage : &nbsp;&nbsp;
            </span>
            <select
              value={this.state.stageId}
              onChange={this.stageChangeHandler}
              className="c-icon-1"
              style={{
                background: "#EEF6F2",
                color: "#FFB202",
                width: "130px",
                outline: "none",
              }}
            >
              <option value="">All Deals</option>
              <option value="showActiveStages">Active Deals</option>
              {this.state.stages.map((stage) => (
                <option key={stage.id} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </select>
          </div>
          {this.props.period && (
            <div className="dealsList-closeDate">
              <span style={{ fontWeight: "500" }}>
                Filter by Created Date &nbsp;&nbsp;
              </span>
              <select
                className="c-icon-1"
                style={{
                  background: "#EEF6F2",
                  color: "#FFB202",
                  width: "130px",
                  outline: "none",
                }}
                value={this.state.created}
                onChange={this.createdDateChangeHandler}
              >
                <option value="daily">Today</option>
                <option value="weekly">This Week</option>
                <option value="monthly">This Month</option>
                <option value="quarterly">This Quarter</option>
                <option value="yearly">This Year</option>
              </select>
            </div>
          )}
          <div className="dealsList-closeDate">
            <span style={{ fontWeight: "500" }}>
              Filter by Close Date &nbsp;&nbsp;
            </span>
            <select
              className="c-icon-1"
              style={{
                background: "#EEF6F2",
                color: "#FFB202",
                width: "130px",
                outline: "none",
              }}
              value={this.state.closeDate}
              onChange={this.closeDateChangeHandler}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="yearly">Yearly</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              <option value="all_days">All Time</option>
            </select>
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchDeals(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : opportunities.length === 0 ? (
          <div
            style={{
              marginTop: "25px",
              fontSize: "16px",
              width: "auto",
              fontWeight: "500",
              marginLeft: "30vw",
            }}
          >
            No Deal available for {this.state.displayUserName}.
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                {this.tableHeaders.map((headers) =>
                  headers.value === "close_reason" &&
                  !(
                    currentStage?.type === "won" ||
                    currentStage?.type === "lost"
                  ) ? null : (
                    <th
                      style={
                        headers.label === "Revenue"
                          ? { background: "#DBF3E8" }
                          : {}
                      }
                    >
                      <Row>
                        <Col lg={9} xl={9}>
                          {headers.label}
                        </Col>
                        <Col lg={3} xl={3}>
                          {headers.sort && (
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                                color: "#657885",
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    sortDirection: !this.state.sortDirection,
                                  },
                                  this.fetchDeals(
                                    null,
                                    this.state.activePage,
                                    headers.value
                                  )
                                )
                              }
                            >
                              <i className="fa fa-long-arrow-up"></i>
                              <i className="fa fa-long-arrow-down"></i>
                            </button>
                          )}
                        </Col>
                      </Row>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {opportunities.map((opp) => {
                return (
                  <tr key={opp.id}>
                    {this.tableHeaders?.map((header) =>
                      this.renderTableData(
                        opp,
                        header,
                        header.value,
                        opp[header.value],
                        dateFormat
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchDeals(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    isDealFieldsModified: state.allDealFields.isModified,
    allUsers: state.allUsers.users,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(DealsTabList);

import axios from "axios";
import { useState } from "react";
import ReactModal from "react-modal";
import { NotificationManager } from "react-notifications";

const ConnectMcleodModal = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  companySettings,
  getCompanySettings,
}) => {
  const [mcleodToken, setmcleodToken] = useState(null);
  const [mcleodBaseUrl, setmcleodBaseUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  const submitHandler = () => {
    if (
      !mcleodToken ||
      mcleodToken === "" ||
      !mcleodBaseUrl ||
      mcleodBaseUrl === ""
    ) {
      setLoading(false);
      return false;
    }
    setLoading(true);
    const {
      currency,
      timezone,
      country,
      date_format,
      is_round_robin_enabled,
      is_multiple_account_owner_enabled,
      logo,
      email,
      formatted_mobile,
    } = companySettings;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios({
      method: "PATCH",
      url: `/company/settings`,
      data: {
        currency: currency ? currency : "USD",
        timezone: timezone ? timezone : userTimezone,
        country: country ? country : "United States",
        date_format: date_format ? date_format : "MM/DD/YYYY",
        is_round_robin_enabled,
        is_multiple_account_owner_enabled,
        ...(logo && { logo }),
        ...(email && { email }),
        ...(formatted_mobile && { formatted_mobile }),
        tms_integrated: "mcleod",
        mcleod_token: mcleodToken,
        mcleod_base_url: mcleodBaseUrl,
      },
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        getCompanySettings();
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Error connecting to McLeod. Please recheck your McLeod Token and Base URL."
        );
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <div className="modal__head">
                <h2 className="modal__heading">Connect McLeod</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <label>McLeod Token</label>
            <input
              value={mcleodToken}
              onChange={(e) => setmcleodToken(e.target.value)}
            />
            <label>McLeod Base URL</label>
            <input
              value={mcleodBaseUrl}
              onChange={(e) => setmcleodBaseUrl(e.target.value)}
            />
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={submitHandler}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ConnectMcleodModal;

import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Popper } from "@mui/material";
import Portal from "@mui/material/Portal";
import axios from "axios";
import AccountIcon from "../images/AccountsIcon.svg";
import CarrierGreenIcon from "../images/CarrierGreenIcon.svg";
import ReportNavbar from "../images/ReportsLogo.svg";
import ContactIcon from "../images/ContactsIcon.svg";
import OpportunityIcon from "../images/DealsIntroLogo.svg";
import { useHistory } from "react-router-dom";
import "../sass/Header.scss";
// Redux Items
import { connect } from "react-redux";
import { setTab, setActiveTabIndex } from "../redux";

const CustomPopper = function (props) {
  return (
    <Portal>
      <Popper
        {...props}
        style={{
          ...props.style,
          zIndex: 10000000,
          position: "fixed",
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: "0",
        }}
      />
    </Portal>
  );
};

const NavbarSearch = ({ setTab, setActiveTabIndex, totalTabs }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (inputValue && inputValue.length > 2) {
      axios
        .get(`/dashboard/search?query=${encodeURIComponent(inputValue)}`, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("authToken"))?.access_token,
          },
        })
        .then((res) => {
          setSearchItems(res.data.data);
        });
    } else {
      setSearchItems([]);
    }
  }, [inputValue]);

  const handleSelect = (event, newValue) => {
    if (newValue) {
      setActiveTabIndex(totalTabs + 1);
      setTab({ type: newValue.type, id: newValue.id, name: newValue.name });
      history.push("/active-tabs");
      setValue(null);
      setInputValue("");
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case "account":
        return AccountIcon;
      case "report":
        return ReportNavbar;
      case "contact":
        return ContactIcon;
      case "carrier":
        return CarrierGreenIcon;
      default:
        return OpportunityIcon;
    }
  };

  return (
    <div className="navbar-search" style={{ position: "relative" }}>
      <Autocomplete
        value={value}
        inputValue={inputValue}
        options={searchItems}
        getOptionLabel={(option) => option.name}
        filterOptions={(x) => x} 
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={handleSelect}
        PopperComponent={CustomPopper}
        popupIcon={null}
        noOptionsText={
          <div
            style={{
              color: "#9CA3AF",
              backgroundColor: "#252a2d",
              padding: "10px 16px",
              fontSize: "14px",
            }}
          >
            No records found
          </div>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "0px 8px",
                height: "33px",
                fontSize: "14px",
                backgroundColor: "#3b4043",
                borderRadius: "13px",
                color: "#CFCFD8",
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
                "& .MuiAutocomplete-endAdornment": {
                  display: "none",
                },
              },
              "& input": {
                padding: "0",
                height: "33px",
                color: "#CFCFD8",
                "&::placeholder": {
                  color: "#6B7280",
                  opacity: 1,
                },
                "&::-webkit-calendar-picker-indicator": { display: "none" },
              },
              "& .MuiInputBase-input": {
                height: "33px",
                lineHeight: "33px",
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              backgroundColor: props["ariaSelected"] ? "#2D3339" : "#252a2d",
              color: "#CFCFD8",
              display: "flex",
              alignItems: "center",
              padding: "10px 16px",
              cursor: "pointer",
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              margin: 0,
              "&[ariaSelected='true']": {
                backgroundColor: "#f2f0ef",
              },
            }}
            className="navbar-search-items"
            key={option.name + option.type}
          >
            <img
              src={getIcon(option.type)}
              alt=""
              width="16"
              style={{ marginRight: "10px" }}
            />
            <div>
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                {option.name}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#6B7280",
                  textTransform: "capitalize",
                }}
              >
                {option.type === "opportunity" ? "Deal" : option.type}
              </div>
            </div>
          </li>
        )}
        sx={{
          width: "250px",
          "& .MuiAutocomplete-paper": {
            backgroundColor: "#252a2d",
            borderRadius: "4px",
            marginTop: "4px",
            padding: 0,
            overflow: "hidden",
            boxShadow: "none",
            border: "none",
          },
          "& .MuiAutocomplete-noOptions": {
            padding: "0 !important",
          },
          "& .MuiPopper-root": {
            backgroundColor: "#252a2d !important",
            boxShadow: "none !important",
            border: "none !important",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#252a2d !important",
          },
          "& .MuiAutocomplete-listbox": {
            padding: 0,
            margin: 0,
            backgroundColor: "#252a2d",
            "& ::-webkit-scrollbar": {
              width: "6px",
            },
            "& ::-webkit-scrollbar-track": {
              backgroundColor: "#252a2d",
            },
            "& ::-webkit-scrollbar-thumb": {
              backgroundColor: "#4B5563",
              borderRadius: "3px",
            },
          },
          "& .MuiAutocomplete-option": {
            minHeight: "unset",
            padding: 0,
            "&:hover": {
              backgroundColor: "#f2f0ef !important",
            },
            "&[ariaSelected='true']": {
              backgroundColor: "#f2f0ef",
            },
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  totalTabs: state.tabsState.tabs.length,
});

const mapDispatchToProps = (dispatch) => ({
  setTab: (newTabData) => dispatch(setTab(newTabData)),
  setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarSearch);

import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { CurrencyList } from "../../../../constants/currencylist";
import { nFormatter } from "../../../../utils/Helper/reusableFunctions";
import ReactTooltip from "react-tooltip";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../../../redux";
class LineChartDashboard extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      xAxis: {
        categories: ["A", "B", "C"],
      },
      yAxis: {
        title: {
          text:
            this.props.yAxis === "total_revenue"
              ? "Total Revenue"
              : this.props.yAxis === "total_shipments"
              ? "Total Shipments"
              : `Number of ${this.props.type}`,
        },
      },
      credits: {
        enabled: false,
      },
      series: [{ data: [1, 2, 3] }],
      title: null,
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: this.setHoverData,
            },
          },
        },
      },
    },
    hoverData: null,
    downloadLoader: false,
  };

  componentDidMount() {
    const yAxis = this.props.yAxis;
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                const formattedCurrency = nFormatter(this.y, 1);
                return yAxis === "total_revenue"
                  ? currencySymbol.symbol +
                      formattedCurrency.toLocaleString("en-US")
                  : this.y;
              },
            },
          },
        },
        series: [
          {
            name: this.props.type,
            data:
              this.props.yAxis === "total_revenue"
                ? this.props.data?.map((d) => d.revenue)
                : this.props.yAxis === "no_of_carrier"
                ? this.props.data?.map((d) => d.no_of_carrier)
                : this.props.data?.map((d) => d.count),
          },
        ],
        yAxis: {
          title: {
            text:
              this.props.yAxis === "total_revenue"
                ? "Total Revenue"
                : this.props.yAxis === "total_shipments"
                ? "Total Shipments"
                : `Number of ${this.props.type}`,
          },
        },
        xAxis: {
          categories: this.props.data?.map((d) => {
            let name = d.source;
            name =
              this.props.segmentBy === "source"
                ? d.source
                : this.props.segmentBy === "account_owner"
                ? d.account_owner
                : this.props.segmentBy === "account_name"
                ? d.name
                : this.props.segmentBy === "last_shipment_date"
                ? d.last_shipment_date
                : this.props.segmentBy === "contact_owner"
                ? d.contact_owner
                : this.props.segmentBy === "carrier_owner"
                ? d.carrier_owner
                : this.props.segmentBy === "opportunity_owner"
                ? d.name
                : this.props.segmentBy === "created_date"
                ? d.created_date
                : this.props.segmentBy === "close_date"
                ? d.close_date
                : this.props.segmentBy === "won_date"
                ? d.close_date
                : this.props.segmentBy === "stage"
                ? d.name
                : this.props.segmentBy === "duration"
                ? d.name
                : this.props.segmentBy === "type"
                ? d.type
                : this.props.segmentBy === "activity_owner"
                ? d.activity_owner
                : this.props.segmentBy === "completion_date"
                ? d.completion_date
                : this.props.segmentBy === "result"
                ? d.result
                : this.props.segmentBy === "status"
                ? d.status
                : d.cf_value;
            return name === null
              ? Number.isInteger(parseInt(this.props.segmentBy))
                ? `No value`
                : `No ${this.props.segmentBy}`
              : name;
          }),
        },
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.chart.setSize(this.props.reportWidth);
    // this.chart.reflow();
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: [
            {
              name: this.props.type,
              data:
                this.props.yAxis === "total_revenue"
                  ? this.props.data?.map((d) => d.revenue)
                  : this.props.yAxis === "no_of_carrier"
                  ? this.props.data?.map((d) => d.no_of_carrier)
                  : this.props.data?.map((d) => d.count),
            },
          ],
          yAxis: {
            title: {
              text:
                this.props.yAxis === "total_revenue"
                  ? "Total Revenue"
                  : this.props.yAxis === "total_shipments"
                  ? "Total Shipments"
                  : `Number of ${this.props.type}`,
            },
          },
          xAxis: {
            categories: this.props.data?.map((d) => {
              let name = d.source;
              name =
                this.props.segmentBy === "source"
                  ? d.source
                  : this.props.segmentBy === "account_owner"
                  ? d.account_owner
                  : this.props.segmentBy === "account_name"
                  ? d.name
                  : this.props.segmentBy === "last_shipment_date"
                  ? d.last_shipment_date
                  : this.props.segmentBy === "contact_owner"
                  ? d.contact_owner
                  : this.props.segmentBy === "carrier_owner"
                  ? d.carrier_owner
                  : this.props.segmentBy === "opportunity_owner"
                  ? d.opportunity_owner
                  : this.props.segmentBy === "created_date"
                  ? d.created_date
                  : this.props.segmentBy === "close_date"
                  ? d.close_date
                  : this.props.segmentBy === "won_date"
                  ? d.close_date
                  : this.props.segmentBy === "stage"
                  ? d.name
                  : this.props.segmentBy === "duration"
                  ? d.name
                  : this.props.segmentBy === "type"
                  ? d.type
                  : this.props.segmentBy === "activity_owner"
                  ? d.activity_owner
                  : this.props.segmentBy === "completion_date"
                  ? d.completion_date
                  : this.props.segmentBy === "result"
                  ? d.result
                  : this.props.segmentBy === "status"
                  ? d.status
                  : d.cf_value;
              return name === null
                ? Number.isInteger(parseInt(this.props.segmentBy))
                  ? `No value`
                  : `No ${this.props.segmentBy}`
                : name;
            }),
          },
        },
      });
    }
  }

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };
  setChart(chart) {
    this.chart = chart;
  }

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        <ReactTooltip id="reportNameOfLineComponent" />
        <h3
          className="funnel-heading-sub"
          style={{
            marginBottom: "0",
            textDecoration: "underline",
            zIndex: "1",
            cursor: "pointer",
          }}
          onClick={this.props.chartRedirectToReport}
          data-tip={this.props.reportName}
          data-for="reportNameOfLineComponent"
        >
          {this.props.reportName.length > 30
            ? this.props.reportName.substring(0, 29) + ".."
            : this.props.reportName}
        </h3>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          callback={(chart) => this.setChart(chart)}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(LineChartDashboard);

export const YOUTUBE_VIDEOS = [
  {
    group: "Getting Started",
    videos: [
      {
        title: "Import Spreadsheet Data",
        src: "https://www.youtube.com/embed/dUY5d0OzQSw",
        id: 1,
      },
      {
        title: "Set up Apollo.io Integration for Shipper Leads",
        src: "https://www.youtube.com/embed/8HnXmR2OJX0",
        id: 2,
      },
      {
        title: "ZoomInfo Salesdash CRM Chrome Extension Setup",
        src: "https://www.youtube.com/embed/OfVE9KQ4VS8",
        id: 3,
      },
      {
        title: "Organizing your record pages",
        src: "https://www.youtube.com/embed/TqpVKoH2Aak",
        id: 4,
      },
      {
        title: "Add Team Members & Set Permissions",
        src: "https://www.youtube.com/embed/2e74lul4oJM?si=P9ebre2rZ40JSRye",
        id: 5,
      },
      {
        title: "Customize add new record forms",
        src: "https://www.youtube.com/embed/2om_vJmF_AM?si=0dT9q7_WrPoIik2o",
        id: 6,
      },
      {
        title: "Adding custom fields and groups",
        src: "https://www.youtube.com/embed/WKerWCgCzZA",
        id: 7,
      },
      {
        title: "Account and Contact Statuses for Lead Nurturing",
        src: "https://www.youtube.com/embed/R66Ba4XEVUc",
        id: 8,
      },
    ],
  },
  {
    group: "Sales Outreach & Process",
    videos: [
      {
        title: "Managing Follow-up Tasks",
        src: "https://www.youtube.com/embed/RvHBq10goXE",
        id: 9,
      },
      {
        title: "Assign a task to a team member",
        src: "https://www.youtube.com/embed/GfISLjwsH78",
        id: 10,
      },
      {
        title: "Setup Ring Central Auto-call Recording Links",
        src: "https://www.youtube.com/embed/bBO5qi9LrK8",
        id: 11,
      },
    ],
  },
  {
    group: "Email Setup & Integration",
    videos: [
      {
        title: "Google and Outlook Integration for Email and Calendar",
        src: "https://www.youtube.com/embed/2ooFcimLShY",
        id: 12,
      },
      {
        title: "Create your email signature",
        src: "https://www.youtube.com/embed/NsVSIplRP8k",
        id: 13,
      },
      {
        title: "Build Email Templates",
        src: "https://www.youtube.com/embed/I6XTAL1LOsc",
        id: 14,
      },
      {
        title: "Targeted, Bulk Emailing",
        src: "https://www.youtube.com/embed/FES-FTj8Uaw?si=w-6wMRxTBUIBd28K",
        id: 15,
      },
    ],
  },
  {
    group: "Reporting & Dashboards",
    videos: [
      {
        title: "Building Reports for Accounts",
        src: "https://www.youtube.com/embed/ETxDnAT4Wrg",
        id: 16,
      },
      {
        title: "Building Reports for Activities",
        src: "https://www.youtube.com/embed/bwiida8vHLA",
        id: 17,
      },
      {
        title: "Creating Custom Dashboards",
        src: "https://www.youtube.com/embed/UroDJltAeNk?si=49ycY-5iyxqw9zYa",
        id: 18,
      },
    ],
  },
  {
    group: "Support",
    videos: [
      {
        title: "Bulk Deleting Records",
        src: "https://www.youtube.com/embed/zHAOZAm2doE?si=CBfgfat1-CfKa40Q",
        id: 19,
      },
      {
        title: "What to do when your emails are not delivering",
        src: "https://www.youtube.com/embed/sBk0X8gfifg?si=b9nrVOyENJQ_8KmA",
        id: 20,
      },
      {
        title: "Manually delete an Account/Contact",
        src: "https://www.youtube.com/embed/7RRmdWGeFuY?si=b0PXGr_dwHBuCOYB",
        id: 21,
      },
    ],
  },
];

import React, { Component } from "react";
import "../../sass/Reports.scss";
import "../../sass/DropdownHeader.scss";
import axios from "axios";
import moment from "moment";
import ReportIcon from "../../images/ReportsLogo.svg";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import ColumnReportIcon from "../../images/ReportsColumnIcon.svg";
import ExportButtonIcon from "../../images/ExportButtonIcon.svg";
import ScoreCardIcon from "../../images/scorecard.png";
import { Col, Row, setConfiguration } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ExitReportModal from "./ExitReportModal";
import DeleteReportModal from "./DeleteReportModal";
import JoditEditor from "jodit-react";
import "jodit";
import ActivitiesLine from "../Analytics/Pages/Activities/ActivitiesLine";
import ActivitiesColumn from "../Analytics/Pages/Activities/ActivitiesColumn";
import ActivitiesPie from "../Analytics/Pages/Activities/ActivitiesPie";
import ActivitiesScoreCard from "../Analytics/Pages/Activities/ActivitiesScoreCard";
import ReportExitDropdown from "./ReportExitDropdown";
import { usStatesList } from "../../constants/usStatesList";
import { countriesList } from "../../constants/countriesList";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../../redux";
import InputField from "../Reusables/InputField/InputField";
import { SlidersHorizontal } from "lucide-react";
import { Plus } from "lucide-react";

setConfiguration({ gutterWidth: "0" });

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  readonly: true,
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Type your message...",
  zIndex: 2147483640,
};
class ReportsActivityTable extends Component {
  state = {
    isInitializing: true,
    filter: "",
    operator: "",
    filter_rules: [{ id: uuidv4(), attribute: "", operator: "", value: null }],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    isEditing: false,
    reportName: this.props.reportName,
    reportDescription: "",
    errorMessage: false,
    sortKey: null,
    displayExitMenu: false,
    sortDirection: false,
    exportLoader: false,
    isLoading: false,
    columnsArray: [
      { name: "Owner", key: "activity_owner", selected: true },
      { name: "Account", key: "account_name", selected: true },
      { name: "Carrier", key: "carrier_name", selected: true },
      { name: "Contact", key: "contact_name", selected: true },
      { name: "Deal", key: "opportunity_name", selected: true },
      { name: "Type", key: "category_id", selected: true },
      { name: "Result", key: "result_id", selected: true },
      { name: "Description", key: "description", selected: true },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
      },
      { name: "Task Name", key: "activity_name", selected: false },
      { name: "Account Status", key: "account_status", selected: false },
      { name: "Deal Stage", key: "opportunity_stage", selected: false },
      { name: "Account City", key: "account_city", selected: false },
      { name: "Account State", key: "account_state", selected: false },
      { name: "Account Zip", key: "account_zip", selected: false },
      { name: "Account Country", key: "account_country", selected: false },
      { name: "Deal City", key: "opportunity_city", selected: false },
      { name: "Deal State", key: "opportunity_state", selected: false },
      { name: "Deal Zip", key: "opportunity_zip", selected: false },
      { name: "Deal Country", key: "opportunity_country", selected: false },
    ],
    results: [],
    categories: [],
    columnsData: [],
    showDeleteModal: false,
    showExitModal: false,
    activePage: 1,
    total: 0,
    isApplying: false,
    access: "everyone",
    analyticsMode: "table",
  };
  wrapperRef = React.createRef();
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  accessChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ access: "everyone" });
    } else {
      this.setState({ access: "only_me" });
    }
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };
  showExitDropdownMenu = (e) => {
    e.preventDefault();
    this.setState({ displayExitMenu: true }, () => {
      document.addEventListener("click", this.hideExitDropdownMenu);
    });
  };
  hideExitDropdownMenu = () => {
    this.setState({ displayExitMenu: false }, () => {
      document.removeEventListener("click", this.hideExitDropdownMenu);
    });
  };
  handleDeleteOpenModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  handleDeleteCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleExitOpenModal = () => {
    this.setState({
      showExitModal: true,
    });
  };
  handleExitCloseModal = () => {
    this.setState({
      showExitModal: false,
    });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    axios({
      method: "GET",
      url: `/activities/results`,
    })
      .then((res) => this.setState({ results: res.data.results }))
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `/tasks/categories`,
    })
      .then((res) => {
        this.setState({ categories: res.data.task_categories });
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `/reports/${this.props.reportId}`,
    }).then((res) =>
      this.setState(
        {
          reportsData: res.data.report,
          reportType: res.data.report.type,
          reportName: res.data.report.name,
          access: res.data.report.access,
          reportDescription: res.data.report.description,
          filter_rules: res.data.report.filters?.map((rule) => {
            const { operator, value } = rule;
            if (operator === "is_in" || operator === "is_not_in") {
              if (rule.attribute === "result") {
                rule.value = value.map((val) => ({
                  value: val,
                  label: this.state.results.find((stage) => stage.id === val)
                    ?.name,
                }));
              } else {
                rule.value = value.map((val) => ({ value: val, label: val }));
              }
            }
            return rule;
          }),
          columnsData: res.data.report.columns,
          columnsArray: [
            {
              name: "Owner",
              key: "activity_owner",
              selected: res.data.report.columns.includes("activity_owner")
                ? true
                : false,
            },
            {
              name: "Account",
              key: "account_name",
              selected: res.data.report.columns.includes("account_name")
                ? true
                : false,
            },
            {
              name: "Carrier",
              key: "carrier_name",
              selected: res.data.report.columns.includes("carrier_name")
                ? true
                : false,
            },
            {
              name: "Contact",
              key: "contact_name",
              selected: res.data.report.columns.includes("contact_name")
                ? true
                : false,
            },
            {
              name: "Deal",
              key: "opportunity_name",
              selected: res.data.report.columns.includes("opportunity_name")
                ? true
                : false,
            },
            {
              name: "Type",
              key: "category_id",
              selected: res.data.report.columns.includes("category_id")
                ? true
                : false,
            },
            {
              name: "Result",
              key: "result_id",
              selected: res.data.report.columns.includes("result_id")
                ? true
                : false,
            },
            {
              name: "Description",
              key: "description",
              selected: res.data.report.columns.includes("description")
                ? true
                : false,
            },
            {
              name: "Last Activity Date",
              key: "last_activity_date",
              selected: res.data.report.columns.includes("last_activity_date")
                ? true
                : false,
            },
            {
              name: "Task Name",
              key: "activity_name",
              selected: res.data.report.columns.includes("activity_name")
                ? true
                : false,
            },
            {
              name: "Account Status",
              key: "account_status",
              selected: res.data.report.columns.includes("account_status")
                ? true
                : false,
            },
            {
              name: "Deal Stage",
              key: "opportunity_stage",
              selected: res.data.report.columns.includes("opportunity_stage")
                ? true
                : false,
            },
            {
              name: "Account City",
              key: "account_city",
              selected: res.data.report.columns.includes("account_city")
                ? true
                : false,
            },
            {
              name: "Account State",
              key: "account_state",
              selected: res.data.report.columns.includes("account_state")
                ? true
                : false,
            },
            {
              name: "Account Zip",
              key: "account_zip",
              selected: res.data.report.columns.includes("account_zip")
                ? true
                : false,
            },
            {
              name: "Account Country",
              key: "account_country",
              selected: res.data.report.columns.includes("account_country")
                ? true
                : false,
            },
            {
              name: "Deal City",
              key: "opportunity_city",
              selected: res.data.report.columns.includes("opportunity_city")
                ? true
                : false,
            },
            {
              name: "Deal State",
              key: "opportunity_state",
              selected: res.data.report.columns.includes("opportunity_state")
                ? true
                : false,
            },
            {
              name: "Deal Zip",
              key: "opportunity_zip",
              selected: res.data.report.columns.includes("opportunity_zip")
                ? true
                : false,
            },
            {
              name: "Deal Country",
              key: "opportunity_country",
              selected: res.data.report.columns.includes("opportunity_country")
                ? true
                : false,
            },
          ],
        },
        () => this.getReportInitializer(res.data.report.order_by),
        () => this.filterRulesIsIn()
      )
    );
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  downloadReport = () => {
    this.setState({ exportLoader: true });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/export`,
      data: {
        report_id: this.props.reportId,
      },
    })
      .then((data) => {
        this.setState({ exportLoader: false });
        let a = document.createElement("a");
        a.href = data.data.file_url;
        a.click();
      })
      .catch((err) => this.setState({ exportLoader: false }));
  };
  getReportInitializer = (orderby, page = 1) => {
    const orderBy = orderby?.split(",");
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({
      sortKey: orderby === null ? null : orderBy[0],
      sortDirection:
        orderby === null ? null : orderBy[1] === "desc" ? true : false,
    });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/reports?_limit=30&_page=${page}`,
      data: {
        filter_rules: newValue,
        sort_key: orderby === null ? null : orderBy[0],
        sort_dir: orderby === null ? null : orderBy[1],
      },
    })
      .then((res) => {
        this.setState({
          reportsTable: res.data.activities?.results,
          total: res.data.total,
          isInitializing: false,
        });
      })
      .catch((err) => this.setState({ isInitializing: false }));
  };
  filterRulesIsIn = () => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules?.map((rule) => {
        const { operator, value } = rule;
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      }),
    }));
  };
  getReport = (page = 1) => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      return rule;
    });
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/activities/reports?_limit=30&_page=${page}`,
        data: {
          filter_rules: newValue,
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.activities.results,
            total: res.data.total,
            isApplying: false,
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  isInChangeHandler = (index, selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected;
        }
        return rule;
      }),
    }));
  };
  changeInfoHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  deleteReport = (id, e) => {
    e.preventDefault();
    axios({
      method: "DELETE",
      url: `/reports/${id}`,
    }).then((res) => this.props.deleteTab(this.props.deleteTabIndex));
  };
  changeHandler = (index, e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "this_year" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
          if (rule.operator === "custom_period") {
            rule.value = [];
          }
        }
        return rule;
      }),
    }));
  };
  valueChangeHandler = (index, valueIndex, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }
          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
    }));
  };
  addFilter = () => {
    this.setState((prevState) => ({
      filter_rules: [
        ...prevState.filter_rules,
        { id: uuidv4(), attribute: "", operator: "", value: [] },
      ],
    }));
  };
  deleteFilter = (index) => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.filter((rule, i) => i !== index),
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isLoading: true });
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val?.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    let order_by =
      this.state.sortKey === null
        ? null
        : `${this.state.sortKey},${sortDirection}`;
    if (checkError === true) {
      axios({
        method: "PUT",
        url: `/reports/${this.props.reportId}`,
        data: {
          name: this.state.reportName,
          type: this.state.reportType,
          description: this.state.reportDescription,
          access: this.state.access,
          filters: newValue,
          columns: this.state.columnsArray
            .filter((columns) => columns.selected === true)
            .map((columns) => (columns.custom ? columns.id : columns.key)),
          order_by,
        },
      })
        .then((res) => {
          this.getReport();
          this.setState({ isLoading: false, isEditing: false });
          NotificationManager.success("Report edited successfully.");
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          NotificationManager.error("Error editing report.");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(
      "reports save props",
      this.props,
      "reports save state",
      this.state
    );
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const { columnsArray } = this.state;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        minWidth: "200px",
        outline: "#C0EAD8",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        marginRight: "12px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };
    return (
      <div
        className="reports"
        style={
          this.state.isEditing === true ? { padding: "0px", margin: "0px" } : {}
        }
      >
        <ReactTooltip />
        {this.state.isEditing === true ? (
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="reports-inputFields">
              <InputField
                id="reportName"
                label="Report Name"
                size="small"
                type="text"
                name="reportName"
                placeholder="Enter Report Name"
                className="reports-inputFields"
                value={this.state.reportName}
                onChange={this.changeInfoHandler}
                disabled={false}
              />
              <div style={{ marginTop: "10px" }}>
                <InputField
                  id="reportDescription"
                  label="Report Description"
                  size="small"
                  type="text"
                  name="reportDescription"
                  placeholder="Enter Report Description"
                  className="reports-inputFields"
                  value={this.state.reportDescription}
                  onChange={this.changeInfoHandler}
                  disabled={false}
                  multiline={true}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "only_me"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                        }
                  }
                >
                  <i
                    className="fa fa-lock"
                    aria-hidden="true"
                    style={{ transform: "translateY(3px)" }}
                  ></i>{" "}
                  &nbsp;
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    value={this.state.access}
                    id="checkboxView"
                    checked={this.state.access === "everyone"}
                    onChange={this.accessChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "everyone"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                  }
                >
                  {" "}
                  &nbsp;<i className="fa fa-users" aria-hidden="true"></i>
                </span>
              </div>
              <button
                className="button-md"
                type="button"
                onClick={this.submitHandler}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Saving ..." : "Save Report"}
              </button>
              <div>
                {this.state.isEditing === true && (
                  <ReportExitDropdown
                    handleDeleteOpenModal={this.handleDeleteOpenModal}
                    handleExitOpenModal={this.handleExitOpenModal}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ borderBottom: "1px solid #E3E3E3", marginBottom: "10px" }}
          >
            <div
              className="customerInfo-icon-report"
              style={{ transform: "translateY(-16px)" }}
            >
              <img
                src={ReportIcon}
                alt=""
                width="17px"
                style={{ transform: "translateY(9px)" }}
              />
            </div>
            <div className="customerInfo-info">
              <h2 className="c-type">Report</h2>
              <h1 className="c-name">
                {this.state.reportName}{" "}
                <span style={{ color: "#4A9876", marginLeft: "15px" }}>
                  {this.state.total} records
                </span>
              </h1>
            </div>
            {this.state.isEditing === false && (
              <div
                style={{
                  marginTop: "2vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="button"
                  className="button-md-black-outline reports-edit-button"
                  onClick={() => this.setState({ isEditing: true })}
                >
                  Edit Report
                </button>
              </div>
            )}
          </div>
        )}
        <div>
          {this.props.authUser.permissions.includes("crm:exporter") &&
            this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.downloadReport}
                className="button-md"
                disabled={this.state.exportLoader}
              >
                <img src={ExportButtonIcon} alt="" />
                &nbsp;&nbsp; {this.state.exportLoader ? "Exporting" : "Export"}
              </button>
            )}
        </div>
        <DeleteReportModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleDeleteOpenModal}
          handleCloseModal={this.handleDeleteCloseModal}
          deleteReport={this.deleteReport}
          reportId={this.props.reportId}
        />
        <ExitReportModal
          showOpportunityModal={this.state.showExitModal}
          setShowOpportunityModal={this.handleExitOpenModal}
          handleCloseModal={this.handleExitCloseModal}
        />
        {this.state.isEditing === true && (
          <form>
            <div className="reports-filter">
              <div>
                <h4 style={{ marginTop: "0px" }}>Filters</h4>
              </div>
              {this.state.filter_rules.map((rule, index) => (
                <div style={{ display: "flex" }} key={rule.id}>
                  <div>
                    <label>Filter</label>
                    <select
                      name="attribute"
                      value={rule.attribute}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="owner">Owner</option>
                      <option value="category">Type</option>
                      <option value="result">Result</option>
                      <option value="created_date">Created Date</option>
                      <option value="last_activity_date">
                        Last Activity Date
                      </option>
                      <option value="last_edit_date">Last Edit Date</option>
                      <option value="activity_name">Task Name</option>
                      <option value="description">Description</option>
                      <option value="date">Date</option>
                      <option value="account_name">Account</option>
                      <option value="carrier_name">Carrier</option>
                      <option value="contact_name">Contact</option>
                      <option value="opportunity_name">Deal</option>
                      <option value="account_status">Account Status</option>
                      <option value="opportunity_stage">Deal Stage</option>
                      <option value="account_city">Account City</option>
                      <option value="account_state">
                        Account State/Province
                      </option>
                      <option value="account_zip">Account Zip</option>
                      <option value="account_country">Account Country</option>
                      <option value="opportunity_city">Deal City</option>
                      <option value="opportunity_state">
                        Deal State/Province
                      </option>
                      <option value="opportunity_zip">Deal Zip</option>
                      <option value="opportunity_country">Deal Country</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.attribute === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Filters is required field
                        </div>
                      )}
                  </div>
                  {rule.attribute === "last_edit_date" ||
                  rule.attribute === "created_date" ||
                  rule.attribute === "last_activity_date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "result" ||
                    rule.attribute === "category" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "owner" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="only_me">My Records</option>
                        <option value="my_team">My Team's Records</option>
                        <option value="everyone">Everyone’s Records</option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  )}
                  {rule.operator === "custom_period" ? (
                    <div style={{ display: "flex" }}>
                      <div>
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 0, e)}
                          value={rule.value[0]}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 1, e)}
                          value={rule.value[1]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label>&nbsp;</label>
                      {rule.operator === "is_empty" ||
                      rule.operator === "is_not_empty" ||
                      rule.operator === "today" ||
                      rule.operator === "yesterday" ||
                      rule.operator === "this_week" ||
                      rule.operator === "last_7_days" ||
                      rule.operator === "last_30_days" ||
                      rule.operator === "this_month" ||
                      rule.operator === "this_quarter" ||
                      rule.operator === "next_quarter" ||
                      rule.operator === "last_quarter" ||
                      rule.operator === "only_me" ||
                      rule.operator === "my_team" ||
                      rule.operator === "everyone" ||
                      rule.operator === "this_year" ||
                      rule.operator === "last_year" ? (
                        <input
                          type="text"
                          name="value"
                          value={(rule.value = null)}
                          disabled={true}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.attribute === "last_edit_date" ||
                        rule.attribute === "created_date" ||
                        rule.attribute === "last_activity_date" ? (
                        <input
                          type="date"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.operator === "is_in" ||
                        rule.operator === "is_not_in" ? (
                        rule.attribute === "result" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.state?.results?.map((result) => {
                              const { id, name } = result;
                              return { value: id, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "category" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.state?.categories.map((category) => {
                              const { id, name } = category;
                              return { value: id, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "owner" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.props.allUsers?.map((user) => {
                              const { name } = user;
                              return { value: name, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "account_status" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.props.allAccountStatuses?.map(
                              (status) => {
                                return {
                                  label: status.name,
                                  value: status.name,
                                };
                              }
                            )}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "account_state" ||
                          rule.attribute === "opportunity_state" ? (
                          <CreatableSelect
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={usStatesList?.map((status) => {
                              return {
                                label: status,
                                value: status,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "account_country" ||
                          rule.attribute === "opportunity_country" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={countriesList?.map((status) => {
                              return {
                                label: status,
                                value: status,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : (
                          <CreatableSelect
                            isMulti
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={[]}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        )
                      ) : (
                        <input
                          type="text"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      )}
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => this.deleteFilter(index)}
                    style={{
                      background: "transparent",
                      border: "none",
                      borderRadius: "28px",
                      outline: "none",
                      cursor: "pointer",
                      transform: "translateY(12px)",
                    }}
                  >
                    <img src={DeleteIcon} alt="Delete" width="16px" />
                  </button>
                  {this.state.errorMessage === true &&
                    ((rule.operator === "is_in" &&
                      (rule.value === null ||
                        rule.value === "" ||
                        rule.value.length === 0)) ||
                      (rule.operator === "is_not_in" &&
                        (rule.value === null ||
                          rule.value === "" ||
                          rule.value.length === 0)) ||
                      (rule.operator === "contains" &&
                        (rule.value === null || rule.value === "")) ||
                      (rule.operator === "not_contains" &&
                        (rule.value === null || rule.value === "")) ||
                      (rule.operator === "greater_than" &&
                        (rule.value === null || rule.value === "")) ||
                      (rule.operator === "less_than" &&
                        (rule.value === null || rule.value === ""))) && (
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        Value cannot be empty
                      </div>
                    )}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  type="button"
                  onClick={this.addFilter}
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                >
                  Add Filter
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
                <button
                  type="button"
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                  onClick={() => this.getReport()}
                >
                  {this.state.isApplying ? "Applying ..." : "Apply Filter"}
                </button>
              </div>
            </div>
          </form>
        )}
        <div>
          <div style={{ display: "flex", marginBottom: "40px" }}>
            {this.state.isEditing === true &&
              this.state.analyticsMode === "table" && (
                <div
                  className="dropdownTask"
                  ref={this.wrapperRef}
                  style={{
                    background: "transparent",
                    width: "90px",
                    height: "39px",
                    margin: "20px",
                    marginBottom: "0",
                  }}
                >
                  <div
                    className="button"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      width: "90px",
                      right: "0",
                      top: "0",
                    }}
                    onClick={this.showDropdownMenu}
                  >
                    <div
                      style={{
                        color: "#212123",
                        borderRadius: "4px",
                        border: "1px solid #E7ECF2",
                        padding: "10px",
                        width: "90px",
                      }}
                      className="reports-column-button"
                    >
                      Columns &nbsp;
                      <SlidersHorizontal size={14} />
                    </div>
                  </div>

                  {this.state.displayMenu ? (
                    <ul
                      style={{
                        width: "260px",
                        background: "white",
                        paddingLeft: "15px",
                        height: "350px",
                        overflowY: "auto",
                        left: "0px",
                        top: "52px",
                      }}
                    >
                      <h4
                        style={{
                          color: "#586874",
                          fontSize: "14px",
                          marginLeft: "0px",
                          textAlign: "center",
                          marginBottom: "10px",
                          borderBottom: "1px solid #586874",
                          paddingBottom: "10px",
                          width: "220px",
                        }}
                      >
                        Displayed Columns
                      </h4>
                      {columnsArray.map((column, index) => {
                        let { name, selected } = column;
                        return (
                          <li style={{ width: "200px", fontSize: "12px" }}>
                            <input
                              type="checkbox"
                              checked={selected}
                              id={column.key + index}
                              onChange={() =>
                                this.setState((prevState) => ({
                                  columnsArray: prevState.columnsArray.map(
                                    (clm) => {
                                      if (clm.key === column.key) {
                                        clm.selected = !clm.selected;
                                      }
                                      return clm;
                                    }
                                  ),
                                }))
                              }
                              style={{
                                height: "15px",
                                display: "inline-block",
                                width: "auto",
                                marginRight: "5px",
                              }}
                            />
                            <label
                              htmlFor={column.key + index}
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                              }}
                            >
                              {name}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              )}
          </div>
        </div>
        <div
          className="reports-tab-container"
          style={this.state.isEditing === true ? { marginLeft: "20px" } : {}}
        >
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "table",
              })
            }
            data-tip="Tabular view"
          >
            <i className="fa fa-table" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "column",
              })
            }
            data-tip="Column chart"
          >
            <i className="fa fa-bar-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "line",
              })
            }
            data-tip="Line chart"
          >
            <i className="fa fa-line-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "bar",
              })
            }
            data-tip="Bar chart"
          >
            <i
              className="fa fa-bar-chart-o"
              aria-hidden="true"
              style={{ transform: "rotate(90deg)" }}
            ></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "pie",
              })
            }
            data-tip="Pie chart"
          >
            <i className="fa fa-pie-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "scorecard",
              })
            }
            data-tip="Scorecard"
          >
            <img
              src={ScoreCardIcon}
              alt="ScoreCard"
              width="20px"
              height="15px"
            />
          </button>
        </div>
        {this.state.analyticsMode === "table" && (
          <div style={{ width: "93vw", height: "auto", overflowX: "auto" }}>
            {this.state.isInitializing === true ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : this.state.isInitializing === false &&
              this.state.reportsTable?.length === 0 ? (
              <div
                style={{
                  margin: "30px auto",
                  marginLeft: "35vw",
                  fontWeight: "500",
                }}
              >
                No records found.
              </div>
            ) : this.state.isEditing === true ? (
              <table
                className="accountList__table"
                style={{
                  width: "98vw",
                  height: "auto",
                  overflowX: "auto",
                  marginLeft: "20px",
                  marginTop: "0",
                }}
              >
                <thead>
                  {columnsArray.map(
                    (column) =>
                      column.selected === true && (
                        <th key={column.key}>
                          <Row>
                            <Col lg={9} xl={9}>
                              {column.name}
                            </Col>
                            <Col lg={3} xl={3}>
                              <button
                                type="button"
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  outline: "none",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      sortKey: column.key,
                                      sortDirection: !this.state.sortDirection,
                                    },
                                    this.getReport
                                  )
                                }
                              >
                                <i className="fa fa-long-arrow-up"></i>
                                <i className="fa fa-long-arrow-down"></i>
                              </button>
                            </Col>
                          </Row>
                        </th>
                      )
                  )}
                </thead>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const {
                      id,
                      activity_owner,
                      category_name,
                      result,
                      activity_name,
                      description,
                      account_name,
                      carrier_name,
                      carrier_id,
                      account_id,
                      contact_name,
                      contact_id,
                      opportunity_name,
                      opportunity_id,
                      account_status,
                      opportunity_stage,
                      account_state,
                      account_zip,
                      account_country,
                      opportunity_city,
                      opportunity_state,
                      opportunity_zip,
                      opportunity_country,
                      last_activity_date,
                    } = report;
                    return (
                      <tr key={id}>
                        {columnsArray[0].selected === true && (
                          <td>{activity_owner}</td>
                        )}
                        {columnsArray[1].selected === true && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "account",
                                    id: account_id,
                                    name: account_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "account",
                                    id: account_id,
                                    name: account_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {account_name}
                            </span>
                          </td>
                        )}
                        {columnsArray[2].selected === true && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "carrier",
                                    id: carrier_id,
                                    name: carrier_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "carrier",
                                    id: carrier_id,
                                    name: carrier_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {carrier_name}
                            </span>
                          </td>
                        )}
                        {columnsArray[3].selected === true && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "contact",
                                    id: contact_id,
                                    name: contact_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "contact",
                                    id: contact_id,
                                    name: contact_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {contact_name}
                            </span>
                          </td>
                        )}
                        {columnsArray[4].selected === true && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "opportunity",
                                    id: opportunity_id,
                                    name: opportunity_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "opportunity",
                                    id: opportunity_id,
                                    name: opportunity_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {opportunity_name}
                            </span>
                          </td>
                        )}
                        {columnsArray[5].selected === true && (
                          <td>{category_name}</td>
                        )}
                        {columnsArray[6].selected === true && <td>{result}</td>}
                        {columnsArray[7].selected === true && (
                          <td>
                            {description && (
                              <JoditEditor
                                value={
                                  description?.length > 140
                                    ? description?.substring(0, 140)
                                    : description
                                }
                                config={config}
                              />
                            )}
                          </td>
                        )}
                        {columnsArray[8].selected === true && (
                          <td>
                            {(last_activity_date &&
                              moment(last_activity_date).format(dateFormat)) ||
                              null}
                          </td>
                        )}
                        {columnsArray[9].selected === true && (
                          <td>{activity_name}</td>
                        )}
                        {columnsArray[10].selected === true && (
                          <td>{account_status}</td>
                        )}
                        {columnsArray[11].selected === true && (
                          <td>{opportunity_stage}</td>
                        )}
                        {columnsArray[12].selected === true && (
                          <td>{report.account_city}</td>
                        )}
                        {columnsArray[13].selected === true && (
                          <td>{account_state}</td>
                        )}
                        {columnsArray[14].selected === true && (
                          <td>{account_zip || null}</td>
                        )}
                        {columnsArray[15].selected === true && (
                          <td>{account_country}</td>
                        )}
                        {columnsArray[16].selected === true && (
                          <td>{opportunity_city}</td>
                        )}
                        {columnsArray[17].selected === true && (
                          <td>{opportunity_state}</td>
                        )}
                        {columnsArray[18].selected === true && (
                          <td>{opportunity_zip}</td>
                        )}
                        {columnsArray[19].selected === true && (
                          <td>{opportunity_country}</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.isApplying ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : (
              <table className="accountList__table">
                <thead>
                  {columnsArray.map(
                    (column) =>
                      this.state?.columnsData.includes(column.key) && (
                        <th key={column.key}>
                          <Row>
                            <Col lg={9} xl={9}>
                              {column.name}
                            </Col>
                            <Col lg={3} xl={3}>
                              <button
                                type="button"
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  outline: "none",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      sortKey: column.key,
                                      sortDirection: !this.state.sortDirection,
                                    },
                                    this.getReport
                                  )
                                }
                              >
                                <i className="fa fa-long-arrow-up"></i>
                                <i className="fa fa-long-arrow-down"></i>
                              </button>
                            </Col>
                          </Row>
                        </th>
                      )
                  )}
                </thead>
                <tbody>
                  {this.state?.reportsTable?.map((report) => {
                    const {
                      id,
                      activity_owner,
                      category_name,
                      result,
                      activity_name,
                      description,
                      account_name,
                      account_id,
                      carrier_name,
                      carrier_id,
                      contact_name,
                      contact_id,
                      opportunity_name,
                      opportunity_id,
                      account_status,
                      opportunity_stage,
                      account_state,
                      account_zip,
                      account_country,
                      opportunity_city,
                      opportunity_state,
                      opportunity_zip,
                      opportunity_country,
                      last_activity_date,
                    } = report;
                    return (
                      <tr key={id}>
                        {this.state?.columnsData.includes("activity_owner") && (
                          <td>{activity_owner}</td>
                        )}
                        {this.state?.columnsData.includes("account_name") && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "account",
                                    id: account_id,
                                    name: account_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "account",
                                    id: account_id,
                                    name: account_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {account_name}
                            </span>
                          </td>
                        )}
                        {this.state?.columnsData.includes("carrier_name") && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "carrier",
                                    id: carrier_id,
                                    name: carrier_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "carrier",
                                    id: carrier_id,
                                    name: carrier_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {carrier_name}
                            </span>
                          </td>
                        )}
                        {this.state?.columnsData.includes("contact_name") && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "contact",
                                    id: contact_id,
                                    name: contact_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "contact",
                                    id: contact_id,
                                    name: contact_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {contact_name}
                            </span>
                          </td>
                        )}
                        {this.state?.columnsData.includes(
                          "opportunity_name"
                        ) && (
                          <td>
                            <span
                              onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                  this.props.setTab({
                                    type: "opportunity",
                                    id: opportunity_id,
                                    name: opportunity_name,
                                    blank: true,
                                  });
                                } else {
                                  this.props.setActiveTabIndex(
                                    this.props.totalTabs + 1
                                  );
                                  this.props.setTab({
                                    type: "opportunity",
                                    id: opportunity_id,
                                    name: opportunity_name,
                                  });
                                  this.props.history.push("/active-tabs");
                                }
                              }}
                              style={{ cursor: "pointer", color: "#4A9876" }}
                            >
                              {opportunity_name}
                            </span>
                          </td>
                        )}
                        {this.state?.columnsData.includes("category_id") && (
                          <td>{category_name}</td>
                        )}
                        {this.state?.columnsData.includes("result_id") && (
                          <td>{result}</td>
                        )}
                        {this.state?.columnsData.includes("description") && (
                          <td>
                            {description && (
                              <JoditEditor
                                value={
                                  description?.length > 140
                                    ? description?.substring(0, 140)
                                    : description
                                }
                                config={config}
                              />
                            )}
                          </td>
                        )}
                        {this.state?.columnsData.includes(
                          "last_activity_date"
                        ) && (
                          <td>
                            {(last_activity_date &&
                              moment(last_activity_date).format(dateFormat)) ||
                              null}
                          </td>
                        )}
                        {this.state?.columnsData.includes("activity_name") && (
                          <td>{activity_name}</td>
                        )}
                        {this.state?.columnsData.includes("account_status") && (
                          <td>{account_status}</td>
                        )}
                        {this.state?.columnsData.includes(
                          "opportunity_stage"
                        ) && <td>{opportunity_stage}</td>}
                        {this.state?.columnsData.includes("account_city") && (
                          <td>{report.account_city}</td>
                        )}
                        {this.state?.columnsData.includes("account_state") && (
                          <td>{account_state}</td>
                        )}
                        {this.state?.columnsData.includes("account_zip") && (
                          <td>{account_zip || null}</td>
                        )}
                        {this.state?.columnsData.includes(
                          "account_country"
                        ) && <td>{account_country}</td>}
                        {this.state?.columnsData.includes(
                          "opportunity_city"
                        ) && <td>{opportunity_city}</td>}
                        {this.state?.columnsData.includes(
                          "opportunity_state"
                        ) && <td>{opportunity_state}</td>}
                        {this.state?.columnsData.includes(
                          "opportunity_zip"
                        ) && <td>{opportunity_zip}</td>}
                        {this.state?.columnsData.includes(
                          "opportunity_country"
                        ) && <td>{opportunity_country}</td>}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
        {this.state.analyticsMode === "table" && (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={30}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              this.setState({ activePage: page });
              this.getReport(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        )}
        {this.state.analyticsMode === "pie" && (
          <ActivitiesPie
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "line" && (
          <ActivitiesLine
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "column" && (
          <ActivitiesColumn
            filter_rules={this.state.filter_rules}
            chartType="column"
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "bar" && (
          <ActivitiesColumn
            filter_rules={this.state.filter_rules}
            chartType="bar"
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "scorecard" && (
          <ActivitiesScoreCard
            filter_rules={this.state.filter_rules}
            fromReport={true}
          />
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allUsers: state.allUsers.users,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ReportsActivityTable);

import React from "react";

const AccountIcon = ({ fill = "#4A9876" }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 0C11.6297 0 11.9435 0.282154 11.9932 0.648229L12 0.75V3H17.25C17.6642 3 18 3.33579 18 3.75V19.25C18 19.6642 17.6642 20 17.25 20H14.25C13.8358 20 13.5 19.6642 13.5 19.25V16.5H11.5V19.25C11.5 19.6642 11.1642 20 10.75 20H7.75C7.33579 20 7 19.6642 7 19.25V3.75C7 3.33579 7.33579 3 7.75 3H10.5V1.5H1.5V19.25C1.5 19.6642 1.16421 20 0.75 20C0.370304 20 0.0565091 19.7178 0.00684667 19.3518L0 19.25V0.75C0 0.370304 0.282154 0.0565091 0.648229 0.00684667L0.75 0H11.25ZM16.5 4.5H8.5V18.5H10V15.75C10 15.3358 10.3358 15 10.75 15H14.25C14.6642 15 15 15.3358 15 15.75V18.5H16.5V4.5ZM5 15V17H3V15H5ZM12 12V14H10V12H12ZM15 12V14H13V12H15ZM5 12V14H3V12H5ZM12 9V11H10V9H12ZM15 9V11H13V9H15ZM5 9V11H3V9H5ZM5 6V8H3V6H5ZM12 6V8H10V6H12ZM15 6V8H13V6H15ZM5 3V5H3V3H5Z"
        fill={fill}
      />
    </svg>
  );
};

export default AccountIcon;

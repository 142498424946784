import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import NotificationManager from "react-notifications/lib/NotificationManager";

class FollowupTask extends Component {
  state = {
    category_id: 1,
    isLoading: false,
    date: "",
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  dateChangeHandler = (period) => {
    const today = new Date();
    let date = "";
    switch (period) {
      case "1d":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1
        );
        break;
      case "2d":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 2
        );
        break;
      case "3d":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 3
        );
        break;
      case "4d":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 4
        );
        break;
      case "5d":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 5
        );
        break;
      case "1w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 7
        );
        break;
      case "2w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 14
        );
        break;
      case "4w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 28
        );
        break;
      case "6w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 42
        );
        break;
      case "8w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 56
        );
        break;
      case "12w":
        date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 84
        );
        break;
      default:
        break;
    }
    this.setState({ date }, () => this.submitHandler());
  };
  submitHandler = () => {
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/tasks/create`,
      data: {
        name:
          this.state.category_id == 1
            ? "Follow-up Call"
            : this.state.category_id == 2
            ? "Follow-up Email"
            : "Follow-up Text",
        date: this.state.date && moment(this.state.date).format("YYYY-MM-DD"),
        ...(this.props.accountId !== "" &&
          this.props.accountId !== null &&
          this.props.accountId !== undefined && {
            account_id: this.props.accountId,
          }),
        ...(this.props.carrierId !== "" &&
          this.props.carrierId !== null &&
          this.props.carrierId !== undefined && {
            carrier_id: this.props.carrierId,
          }),
        ...(this.props.contactId !== "" &&
          this.props.contactId !== null &&
          this.props.contactId !== undefined && {
            contact_id: this.props.contactId,
          }),
        ...(this.props.opportunityId !== "" &&
          this.props.opportunityId !== null &&
          this.props.opportunityId !== undefined && {
            opportunity_id: this.props.opportunityId,
          }),
        category_id: this.state.category_id,
        start_time: null,
        end_time: null,
        assigned_to: this.props.existingUser,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        NotificationManager.success("Follow-up Task Created successfully.");
        this.props.getTasks();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error creating follow-up task.");
      });
  };
  render() {
    return this.state.isLoading ? (
      <div className="FollowupTask">
        <h2 className="FollowupTask-heading">Creating follow-up task ...</h2>
      </div>
    ) : (
      <div className="FollowupTask">
        <h2 className="FollowupTask-heading">Set a quick follow-up task.</h2>
        <select
          name="category_id"
          value={this.state.category_id}
          onChange={this.changeHandler}
          className="FollowupTask-select"
        >
          <option value={1}>Call</option>
          <option value={2}>Email</option>
          <option value={3}>Text</option>
        </select>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("1d")}
        >
          1d
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("2d")}
        >
          2d
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("3d")}
        >
          3d
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("4d")}
        >
          4d
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("5d")}
        >
          5d
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("1w")}
        >
          1w
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("2w")}
        >
          2w
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("4w")}
        >
          4w
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("6w")}
        >
          6w
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("8w")}
        >
          8w
        </button>
        <button
          type="button"
          className="FollowupTask-button"
          onClick={() => this.dateChangeHandler("12w")}
        >
          12w
        </button>
      </div>
    );
  }
}

export default FollowupTask;

import React, { useState, useCallback, useEffect, useRef } from "react";
import CarrierIntro from "./CarrierIntro";
import ActivityLog from "../ActivityLog";
import "../../sass/CustomerProfile.scss";
import Confetti from "react-confetti";
import { NotificationManager } from "react-notifications";

import axios from "axios";
import AllSequenceModal from "../Sequencing/AllSequenceModal";
import SaveEmailModal from "../SendEmail/SaveEmailModal";
import SendSequenceModal from "../Sequencing/SendSequenceModal";
import EditSequenceModal from "../Sequencing/EditSequenceModal";
import BillingCreditModal from "../Reports/BillingCreditModal";
import BillingRedirectModal from "../Reports/BillingRedirectModal";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
import PersonalizedVarsModal from "../SendEmail/PersonalizedVarsModal";
import CarrierActivityForm from "./CarrierActivityForm";
import AddLanePanel from "../Lanes/AddLanePanel";
import EditLanePanel from "../Lanes/EditLanePanel";
import NextRecordNavigation from "../NextRecordNavigation";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet, setTab, setActiveTabIndex } from "../../redux";

const CarrierProfile = ({
  carrierId,
  customerName,
  tabIndex,
  deleteTab,
  authUser,
  activities,
  showTaskArrow,
  tasks,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
  taskIndex,
}) => {
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showAllSequenceModal, setShowAllSequenceModal] = useState(false);
  const [showSaveEmailModal, setShowSaveEmailModal] = useState(false);
  const [showSendSequenceModal, setShowSendSequenceModal] = useState(false);
  const [showEditSequenceModal, setShowEditSequenceModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPersonalizedVarsModal, setShowPersonalizedVarsModal] =
    useState(false);

  const [logs, setLogs] = useState([]);
  const [totalActivities, setTotalActivities] = useState(0);
  const [pinnedActivity, setPinnedActivity] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [carrierName, setCarrierName] = useState("");
  const [carrierMobile, setCarrierMobile] = useState("");
  const [restart, setRestart] = useState(false);
  const [addLane, setAddLane] = useState(false);
  const [laneMode, setLaneMode] = useState("create");
  const [laneData, setLaneData] = useState({});
  const [carrierEmail, setCarrierEmail] = useState(null);
  const [carrierData, setCarrierData] = useState({});
  const [carrierCustomFields, setCarrierCustomFields] = useState({});
  const [contactEmailsArray, setContactEmailsArray] = useState([]);
  const [carrierLanes, setCarrierLanes] = useState([]);
  const [totalLanes, setTotalLanes] = useState(0);
  const [integration, setIntegration] = useState(null);
  const [signatureId, setSignatureId] = useState(null);
  const [signatureVerified, setSignatureVerified] = useState(false);
  const [emailCredits, setEmailCredits] = useState(0);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [personalizedVariablesArray, setPersonalizedVariablesArray] = useState(
    []
  );
  const [recorderLoading, setRecorderLoading] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [opportunities, setOpportunities] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const carrierMobileRef = useRef(carrierMobile);
  const setCarrierMobileRef = (data) => {
    carrierMobileRef.current = data;
    setCarrierMobile(data);
  };

  const emailDecision = () => {
    if (signatureId === null) {
      setShowPostMarkModal(true);
    } else if (!signatureVerified) {
      setShowVerificationModal(true);
    } else if (emailCredits === null || 10 > emailCredits) {
      setShowCreditsModal(true);
    } else {
      setShowAllSequenceModal(true);
    }
  };
  const getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        setEmailSignature(res.data.email_signature);
      }
    });
  };
  const recordChangeHandler = (e) => {
    setCarrierEmail(e.target.value);
  };
  const fetchAllOpportunitiesCarriers = () => {
    axios({
      method: "GET",
      url: `/carriers/${carrierId}/opportunities?_limit=100`,
    }).then((res) => {
      const data = res.data.opportunities;
      setOpportunities(data);
    });
  };
  const fetchAllContactsCarriers = (page = 1) => {
    axios({
      method: "GET",
      url: `/carriers/${carrierId}/contacts?_limit=50&_page=${page}`,
    }).then((res) => {
      const data = res.data.contacts;
      setContacts(data);
      setTotalContacts(res.data.total);
    });
  };
  const recordSubmitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      carrierEmail !== null &&
      carrierEmail !== "" &&
      !emailValidator.test(carrierEmail)
    ) {
      setRecorderLoading(false);
      setEmailValidationError(true);
      return false;
    }
    setRecorderLoading(true);
    let customField = carrierCustomFields;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    axios({
      method: "PUT",
      url: `/carriers/${carrierId}`,
      data: {
        name: carrierData.name,
        mc_number: carrierData.mc_number,
        status_id: carrierData.status_id,
        owner_ids: carrierData.owners?.map((id) => id.owner_id),
        email: carrierEmail,
        phone_ext: carrierData.phone_ext,
        formatted_phone: carrierData.formatted_phone,
        equipment_type_ids: carrierData.equipment_types?.map((type) => type.id),
        dot_number: carrierData.dot_number,
        fleet_size: carrierData.fleet_size,
        description: carrierData.description,
        city: carrierData.homeBase.city,
        state: carrierData.homeBase.state,
        zip: carrierData.homeBase.zip,
        country: carrierData.homeBase.country,
        address: carrierData.homeBase.address,
        lat: carrierData.homeBase.lat,
        long: carrierData.homeBase.long,
        custom_fields: customField,
      },
    })
      .then((response) => {
        NotificationManager.success("Carrier edited successfully.");
        setRecorderLoading(false);
        setEmailValidationError(false);
        setShowSaveEmailModal(false);
        setShowAllSequenceModal(true);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing carrier.");
        }
      });
  };

  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
          setShowCreditsModal(true);
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?carrier_id=${carrierId}`,
    }).then((res) => {
      setSelectedSequence(res.data.sequence);
      setShowSendSequenceModal(true);
      setShowAllSequenceModal(false);
    });
  };
  const readTemplateVarsValues = (tempId, personalizedVariables) => {
    let url = `/emails/templates/${tempId}?carrier_id=${carrierId}`;
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setShowPersonalizedVarsModal(false);
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      for (let i = 0; i < personalizedVariables.length; i++) {
        finalContent = finalContent.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalSubject;
      }
    });
  };
  const carrierInfo = () => {
    axios({
      method: "GET",
      url: `/carriers/${carrierId}`,
    }).then((res) => {
      setCarrierMobileRef(res.data.carrier.formatted_phone);
      setCarrierName(res.data.carrier.name);
    });
  };
  const getCarrierLanes = () => {
    axios({
      method: "GET",
      url: `/lanes?carrier_id=${carrierId}&_limit=100&_page=1`,
    }).then((res) => {
      setCarrierLanes(res.data.lanes);
      setTotalLanes(res.data.total);
    });
  };
  useEffect(() => {
    checkUserProfile();
    getSignature();
    carrierInfo();
    getCarrierLanes();
    fetchAllOpportunitiesCarriers();
    fetchAllContactsCarriers();
  }, []);

  const loadActivities = useCallback(
    (page, fetchType) => {
      if (activityLoader) return;
      setActivityLoader(true);
      let url = `/activities/timeline-activities?carrier_id=${carrierId}&_limit=30&_page=${page}`;
      axios({
        method: "GET",
        url,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      })
        .then((res) => {
          setActivityLoader(false);
          if (fetchType === "refetch") {
            setLogs(res.data.activities);
          } else {
            setLogs((oldLogs) => [...oldLogs, ...res.data.activities]);
          }
          setPinnedActivity(res.data.pinned_activity);
          setTotalActivities(res.data.total);
          const totalLoaded = page * 30; // 30 is the limit per page
          setHasMoreItems(totalLoaded < res.data.total);
        })
        .catch((err) => {
          console.error("Error loading activities:", err);
          setActivityLoader(false);
          setHasMoreItems(false);
        });
    },
    [carrierId, activityLoader]
  );
  useEffect(() => {
    if (activities.fetchActivity === true) {
      loadActivities(1, "refetch");
    }
  }, [activities.fetchActivity]);
  const getConfetti = () => {
    setShowConfetti(true);
  };
  return (
    <div>
      <div className="customerProfile" id={`carrierProfile${carrierId}`}>
        {showConfetti && (
          <Confetti
            width={window.innerWidth / 1.1}
            height={window.innerHeight}
            numberOfPieces={400}
            recycle={false}
          />
        )}
        <div className="row">
          {showTaskArrow && (
            <NextRecordNavigation
              tasks={tasks}
              setTab={setTab}
              setActiveTabIndex={setActiveTabIndex}
              history={history}
              totalTabs={totalTabs}
              recordType="carrier"
              recordId={carrierId}
              taskIndex={taskIndex}
            />
          )}
          <div className="col-1-of-3">
            <CarrierIntro
              customerId={carrierId}
              showOpportunityModal={showOpportunityModal}
              setShowOpportunityModal={setShowOpportunityModal}
              customerName={carrierName}
              tabIndex={tabIndex}
              deleteTab={deleteTab}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              restart={restart}
              setRestart={setRestart}
              loadActivities={loadActivities}
              setCarrierEmail={setCarrierEmail}
              setContactEmailsArray={setContactEmailsArray}
              setIntegration={setIntegration}
              getConfetti={getConfetti}
              setCarrierData={setCarrierData}
              setCarrierCustomFields={setCarrierCustomFields}
              setAddLane={setAddLane}
              setLaneData={setLaneData}
              setLaneMode={setLaneMode}
              addLane={addLane}
              carrierLanes={carrierLanes}
              totalLanes={totalLanes}
              fetchAllOpportunitiesCarriers={fetchAllOpportunitiesCarriers}
              fetchAllContactsCarriers={fetchAllContactsCarriers}
              opportunities={opportunities}
              contacts={contacts}
              totalContacts={totalContacts}
            />
          </div>
          {showAllSequenceModal && (
            <AllSequenceModal
              showOpportunityModal={showAllSequenceModal}
              setShowOpportunityModal={() => setShowAllSequenceModal(true)}
              handleCloseModal={() => setShowAllSequenceModal(false)}
              setSequenceValue={setSequenceValue}
              handleOpenEditSequenceModal={() => setShowEditSequenceModal(true)}
            />
          )}
          {showSaveEmailModal && (
            <SaveEmailModal
              showModal={showSaveEmailModal}
              handleCloseModal={() => setShowSaveEmailModal(false)}
              email={carrierEmail}
              changeHandler={recordChangeHandler}
              modalHeight={45}
              modalTop={15}
              loading={recorderLoading}
              submitHandler={recordSubmitHandler}
              error={emailValidationError}
            />
          )}
          {showSendSequenceModal && (
            <SendSequenceModal
              showOpportunityModal={showSendSequenceModal}
              setShowOpportunityModal={() => setShowSendSequenceModal(true)}
              handleCloseModal={() => setShowSendSequenceModal(false)}
              selectedSequence={selectedSequence}
              toEmail={carrierEmail ? [carrierEmail] : []}
              carrierId={carrierId}
              fromEmail={authUser.email}
              handleCloseEmailModal={() => setShowSendSequenceModal(false)}
              emailType={"carrier_id"}
              loadActivities={loadActivities}
              emailSignature={emailSignature}
              metadata={{
                type: "carrier",
                type_id: carrierId,
                type_name: carrierName,
              }}
            />
          )}
          {showEditSequenceModal && (
            <EditSequenceModal
              showOpportunityModal={showEditSequenceModal}
              setShowOpportunityModal={() => setShowEditSequenceModal(true)}
              handleCloseModal={() => setShowEditSequenceModal(false)}
              selectedSequence={selectedSequence}
            />
          )}
          {showCreditsModal && (
            <BillingCreditModal
              showOpportunityModal={showCreditsModal}
              setShowOpportunityModal={() => setShowCreditsModal(true)}
              handleCloseModal={() => setShowCreditsModal(false)}
              handleEmailOpenModal={() => setShowAllSequenceModal(true)}
              checkUserProfile={checkUserProfile}
            />
          )}
          {showRedirectModal && (
            <BillingRedirectModal
              showOpportunityModal={showRedirectModal}
              setShowOpportunityModal={() => setShowRedirectModal(true)}
              handleCloseModal={() => setShowRedirectModal(false)}
            />
          )}
          {showPostMarkModal && (
            <PostMarkAppModal
              showOpportunityModal={showPostMarkModal}
              setShowOpportunityModal={() => setShowPostMarkModal(true)}
              handleCloseModal={() => setShowPostMarkModal(false)}
              handleVerificationOpenModal={() => setShowVerificationModal(true)}
              checkUserProfile={checkUserProfile}
            />
          )}
          {showVerificationModal && (
            <PostMarkAppVerificationModal
              showOpportunityModal={showVerificationModal}
              setShowOpportunityModal={() => setShowVerificationModal(true)}
              handleCloseModal={() => setShowVerificationModal(false)}
              checkUserProfile={checkUserProfile}
              signatureVerified={signatureVerified}
              handleEmailOpenModal={() => setShowAllSequenceModal(true)}
              checkVerification={checkVerification}
            />
          )}
          {showPersonalizedVarsModal && (
            <PersonalizedVarsModal
              showOpportunityModal={showPersonalizedVarsModal}
              setShowOpportunityModal={() => setShowPersonalizedVarsModal(true)}
              handleCloseModal={() => setShowPersonalizedVarsModal(false)}
              readTemplateVarsValues={readTemplateVarsValues}
              templateId={templateId}
              personalizedVariablesArray={personalizedVariablesArray}
            />
          )}
          {addLane && laneMode === "edit" ? (
            <div className="col-2-of-3" style={{ marginRight: "0" }}>
              <EditLanePanel
                carrierId={carrierId}
                setAddLane={setAddLane}
                getCarrierLanes={getCarrierLanes}
                setLaneData={setLaneData}
                laneData={laneData}
              />
            </div>
          ) : addLane ? (
            <div className="col-2-of-3" style={{ marginRight: "0" }}>
              <AddLanePanel
                carrierId={carrierId}
                setAddLane={setAddLane}
                getCarrierLanes={getCarrierLanes}
              />
            </div>
          ) : null}
          <div
            className="col-1-of-3"
            style={addLane ? { display: "none" } : {}}
          >
            <ActivityLog
              entityId={carrierId}
              customerName={carrierName}
              entityType="carrier"
              // New props
              loadActivities={loadActivities}
              hasMoreItems={hasMoreItems}
              logs={logs}
              integration={integration}
              pinnedActivity={pinnedActivity}
              setHasMoreItems={setHasMoreItems}
              activityLoader={activityLoader}
              totalActivities={totalActivities}
            />
          </div>
          <div
            className="col-1-of-3"
            style={addLane ? { display: "none" } : {}}
          >
            <CarrierActivityForm
              customerId={carrierId}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              customerName={carrierName}
              // New props
              loadActivities={loadActivities}
              restart={restart}
              setRestart={setRestart}
              carrierEmail={carrierEmail}
              contactEmailsArray={contactEmailsArray}
              integration={integration}
              emailDecision={emailDecision}
              fetchAllOpportunitiesCarriers={fetchAllOpportunitiesCarriers}
              fetchAllContactsCarriers={fetchAllContactsCarriers}
              opportunities={opportunities}
              contacts={contacts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    activities: state.activities,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(React.memo(CarrierProfile));

import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";
import "jodit";
import EditSequenceModal from "./EditSequenceModal";
import EditTemplateModal from "../SendEmail/EditTemplateModal";
import EditIcon from "../../images/EditIconLight.svg";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  readonly: true,
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Type your message...",
  zIndex: 2147483640,
};

function SequenceDetailPage() {
  const [sequences, setSequences] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditTempModal, setShowEditTempModal] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const location = useLocation();
  const sequenceId = location.pathname.replace("/sequencing/", "");

  const getSequence = useCallback(() => {
    axios({
      method: "GET",
      url: `/sequences/${sequenceId}`,
    }).then((res) => setSequences(res.data.sequence));
  }, [sequenceId]);
  useEffect(() => {
    getSequence();
  }, [getSequence]);
  const getSingleTemplateData = (
    id,
    label,
    subject,
    content,
    personalizeVars,
    isPrivate = true
  ) => {
    setTemplateData({
      id,
      label,
      subject,
      content,
      personalizeVars,
      isPrivate,
    });
    setShowEditTempModal(true);
  };
  return (
    <div className="reports">
      <EditSequenceModal
        showOpportunityModal={showEditModal}
        setShowOpportunityModal={() => setShowEditModal(true)}
        handleCloseModal={() => setShowEditModal(false)}
        selectedSequence={sequences}
        getSequence={getSequence}
        fromDetailPage={true}
      />
      <EditTemplateModal
        showOpportunityModal={showEditTempModal}
        setShowOpportunityModal={() => setShowEditTempModal(true)}
        handleCloseModal={() => setShowEditTempModal(false)}
        getAllTemplates={getSequence}
        templateData={templateData}
      />
      <Link to="/sequencing" style={{ color: "#4A9876" }}>
        Go Back to Sequence
      </Link>
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px", display: "inline-block" }}>
          {sequences && sequences.name}
        </h2>
        <button
          onClick={() => setShowEditModal(true)}
          className="button-md"
          style={{ float: "right", marginRight: "20px" }}
        >
          Edit Sequence
        </button>
      </div>
      <h3>Steps</h3>
      {sequences &&
        sequences?.steps
          ?.sort((a, b) => a.order - b.order)
          .map((step) => {
            let finalContent = step.body;
            let finalSubject = step.subject;
            for (let i = 0; i < step.personalize_vars.length; i++) {
              finalContent = finalContent.includes(
                `{{ ${step.personalize_vars[i].key} }}`
              )
                ? finalContent.replaceAll(
                    `{{ ${step.personalize_vars[i].key} }}`,
                    `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                  )
                : finalContent;
              finalSubject = finalSubject.includes(
                `{{ ${step.personalize_vars[i].key} }}`
              )
                ? finalSubject.replaceAll(
                    `{{ ${step.personalize_vars[i].key} }}`,
                    `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                  )
                : finalSubject;
            }
            return (
              <div
                className="sequence-steps"
                style={{ textAlign: "left", paddingLeft: "6%" }}
              >
                <button
                  type="button"
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "2%",
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    getSingleTemplateData(
                      step.template_id,
                      step.name,
                      step.subject,
                      step.body,
                      step.personalize_vars,
                      step.is_private
                    )
                  }
                >
                  <img src={EditIcon} alt="Edit" width="16px" height="16px" />
                </button>
                <div>
                  <span style={{ fontWeight: "500" }}>Template Name: </span>
                  {step.name}{" "}
                  <div>
                    <span style={{ fontWeight: "500" }}>Send in</span>{" "}
                    {parseInt(step.delay) / 86400} Day(s)
                  </div>
                  <span style={{ fontWeight: "500" }}>Subject: </span>
                  <JoditEditor
                    value={finalSubject === false ? step.subject : finalSubject}
                    config={config}
                  />
                  <div>
                    <span style={{ fontWeight: "500" }}>Body: </span>
                    <JoditEditor
                      value={finalContent === false ? step.body : finalContent}
                      config={config}
                    />
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default SequenceDetailPage;

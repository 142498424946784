import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Logo from "../images/logoNew.png";
import { Row, Col, setConfiguration, Visible } from "react-grid-system";
import "../sass/Header.scss";
import "../sass/Burger.scss";
import { slide as Menu } from "react-burger-menu";
import DashboardLogo from "../images/DashboardNavIconGreen.svg";
import CalendarLogo from "../images/CalendarNavIconGreen.svg";
import ContactsNavIcon from "../images/ContactsNavIconGreen.svg";
import LaneNavIcon from "../images/LaneNavIcon.svg";
import ContactsSettingsIcon from "../images/ContactsSettingsIcon.svg";
import DealsSettingsIcon from "../images/DealsSettingsIcon.svg";
import ReportsLogo from "../images/ReportsNavIconGreen.svg";
import BulkEmailNavIcon from "../images/BulkEmailNavIcon.svg";
import SequenceNavIcon from "../images/SequenceNavIcon.svg";
import TemplateNavIcon from "../images/TemplateNavIcon.svg";
import AccountsLogo from "../images/AccountsSettingsIcon.svg";
import UnassignedLeadIcon from "../images/UnassignedLeadIcon.svg";
import ApolloTabLogo from "../images/ApolloTabLogo.svg";
import CarrierGreenNavIcon from "../images/CarrierGreenNavIcon.svg";
import SendEmailIcon from "../images/AccountEmailIconNav.svg";
import DropdownHeader from "./Dropdown/DropdownHeader";
import NavbarSearch from "./NavbarSearch";
import { compose } from "recompose";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  authTokenSet,
  fetchAllDashboards,
  fetchAllAllFormAccountFields,
  fetchAllAllFormContactFields,
  fetchAllAllFormDealFields,
  fetchAllAllFormCarrierFields,
  companyOnboardingSet,
} from "../redux";
import CreateContact from "./Contacts/CreateContact";
import OppModal from "./OppModal";
import CustomerModal from "./CustomerModal";
import CreateAccountModal from "./CreateModal/CreateAccountModal";
import DropdownAdd from "./Dropdown/DropdownAdd";
import DropdownNotification from "./Dropdown/DropdownNotification";
import CreateContactModal from "./CreateModal/CreateContactModal";
import CreateDealModal from "./CreateModal/CreateDealModal";
import CreateCarrierModal from "./Carrier/CreateCarrierModal";
import CreateCarrierCustomModal from "./Carrier/CreateCarrierCustomModal";

setConfiguration({
  containerWidths: [540, 740, 1070, 1400, 1800],
  breakpoints: [576, 768, 1150, 1200, 1600],
});

const Header2 = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showOppModal, setShowOppModal] = useState(false);
  const [showCarrierModal, setShowCarrierModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showNewOppModal, setShowNewOppModal] = useState(false);
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const [showNewCarrierModal, setShowNewCarrierModal] = useState(false);
  const [userData, setUserData] = useState({});
  const logoutUser = () => {
    localStorage.clear();
    props.authUserSet(null);
    props.authTokenSet(null);

    window.location.href = "/";
  };
  const fetchMailProvider = () => {
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      setUserData(res.data.user);
    });
  };
  useEffect(() => {
    setWindowWidth(windowWidth);
    return () => {
      setWindowWidth(windowWidth);
    };
  }, [windowWidth]);
  useEffect(() => {
    fetchMailProvider();
  }, []);
  return (
    <header
      className="header"
      style={
        (props.status === "inactive" || props.status === "past_due") &&
        props.isSampleData
          ? { top: "40px" }
          : props.status === "inactive" ||
            props.status === "past_due" ||
            props.isSampleData
          ? { top: "20px" }
          : {}
      }
    >
      <div
        style={windowWidth < 768 ? { marginLeft: "2%" } : {}}
        className="header-x-0"
      >
        <Visible lg xl xxl>
          <CreateContact
            showOpportunityModal={showContactModal}
            setShowOpportunityModal={() => setShowContactModal(true)}
            handleCloseModal={() => setShowContactModal(false)}
            fromNav={true}
          />
          <CreateContactModal
            showOpportunityModal={showNewContactModal}
            setShowOpportunityModal={() => setShowNewContactModal(true)}
            handleCloseModal={() => setShowNewContactModal(false)}
            fromNav={true}
          />
          <OppModal
            showOpportunityModal={showOppModal}
            setShowOpportunityModal={() => setShowOppModal(true)}
            handleCloseModal={() => setShowOppModal(false)}
            fromNav={true}
          />
          <CreateDealModal
            showOpportunityModal={showNewOppModal}
            setShowOpportunityModal={() => setShowNewOppModal(true)}
            handleCloseModal={() => setShowNewOppModal(false)}
            fromNav={true}
          />
          <CustomerModal
            showOpportunityModal={showAccountModal}
            setShowOpportunityModal={() => setShowAccountModal(true)}
            handleCloseModal={() => setShowAccountModal(false)}
          />
          <CreateCarrierModal
            showOpportunityModal={showCarrierModal}
            setShowOpportunityModal={() => setShowCarrierModal(true)}
            handleCloseModal={() => setShowCarrierModal(false)}
          />
          <CreateCarrierCustomModal
            showOpportunityModal={showNewCarrierModal}
            setShowOpportunityModal={() => setShowNewCarrierModal(true)}
            handleCloseModal={() => setShowNewCarrierModal(false)}
          />
          <CreateAccountModal
            showOpportunityModal={showNewAccountModal}
            setShowOpportunityModal={() => setShowNewAccountModal(true)}
            handleCloseModal={() => setShowNewAccountModal(false)}
          />
          <div className="header-row">
            <div className="header-img" style={{ display: "flex" }}>
              <Link
                to="/active-tabs"
                onClick={() => {
                  props.setActiveTabIndex(0);
                }}
                style={{ marginRight: "25px" }}
              >
                <img
                  src={Logo}
                  // src={props.companyOnboarding?.logo ? props.companyOnboarding?.logo : Logo}
                  alt="logo"
                  height="30px"
                  className="header-img"
                />
              </Link>
              <NavbarSearch />
            </div>
            <div>
              <ul className="header-items ">
                <li className="has-dropdown">
                  <Link
                    to="/active-tabs"
                    className="menu nav-button"
                    onClick={() => {
                      props.setActiveTabIndex(0);
                    }}
                  >
                    <img
                      src={DashboardLogo}
                      alt="Dashboard"
                      width="18px"
                      style={{ transform: "translateY(-2px)" }}
                    />
                    &nbsp;&nbsp;
                    <li style={{ transform: "translateY(-6px)" }}>
                      Dashboard{" "}
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                        style={{ fontSize: "8px" }}
                      ></i>
                    </li>
                  </Link>
                  <ul class="nav-dropdown">
                    {props.dashboardsState.dashboards
                      ?.slice(0, 3)
                      ?.map((dash) => {
                        const { id, name, settings, type, team_id } = dash;
                        let newTabData = {
                          type: "dashboard",
                          id,
                          name,
                          settings,
                          dashboardType: type,
                          teamId: team_id,
                        };
                        return (
                          <li
                            onClick={(e) => {
                              if (e.metaKey || e.ctrlKey) {
                                props.setTab({ ...newTabData, blank: true });
                              } else {
                                props.setActiveTabIndex(props.totalTabs + 1);
                                props.setTab(newTabData);
                                props.history.push("/active-tabs");
                              }
                            }}
                            key={dash.id}
                          >
                            <span>{dash.name}</span>
                          </li>
                        );
                      })}
                    <li>
                      <Link to="/dashboard-list">
                        <span>View All</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard-customization/create">
                        <span>Add new</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>&nbsp;&nbsp;</li>
                <li>
                  <Link to="/calendar" className="menu nav-button">
                    <img
                      src={CalendarLogo}
                      alt="Calendar"
                      width="18px"
                      style={{ transform: "translateY(-2px)" }}
                    />
                    &nbsp;&nbsp;
                    <li style={{ transform: "translateY(-6px)" }}>Calendar</li>
                  </Link>
                </li>
                <li>&nbsp;&nbsp;</li>
                <li className="has-dropdown">
                  <Link className="menu nav-button">
                    <img
                      src={ContactsNavIcon}
                      alt=""
                      width="20px"
                      style={{ transform: "translateY(-1px)" }}
                    />
                    &nbsp;&nbsp;
                    <li style={{ transform: "translateY(-5px)" }}>
                      Records{" "}
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                        style={{ fontSize: "8px" }}
                      ></i>
                    </li>
                  </Link>
                  <ul class="nav-dropdown">
                    <li
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          props.setTab({
                            type: "accountList",
                            id: `${props.authUser.id}AccountsList`,
                            name: "Accounts",
                            blank: true,
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                        } else {
                          props.setActiveTabIndex(props.totalTabs + 1);
                          props.setTab({
                            type: "accountList",
                            id: `${props.authUser.id}AccountsList`,
                            name: "Accounts",
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                          props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <span>
                        <img src={AccountsLogo} alt="" width="16px" />
                        &nbsp;&nbsp;Accounts
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          props.setTab({
                            type: "customerList",
                            id: `${props.authUser.id}CustomersList`,
                            name: "Customers",
                            blank: true,
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                        } else {
                          props.setActiveTabIndex(props.totalTabs + 1);
                          props.setTab({
                            type: "customerList",
                            id: `${props.authUser.id}CustomersList`,
                            name: "Customers",
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                          props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <span>
                        <img src={AccountsLogo} alt="" width="16px" />
                        &nbsp;&nbsp;Customers
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          props.setTab({
                            type: "contactList",
                            id: `${props.authUser.id}ContactsList`,
                            name: "Contacts",
                            blank: true,
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                        } else {
                          props.setActiveTabIndex(props.totalTabs + 1);
                          props.setTab({
                            type: "contactList",
                            id: `${props.authUser.id}ContactsList`,
                            name: "Contacts",
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                          props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <span>
                        <img src={ContactsSettingsIcon} alt="" width="16px" />
                        &nbsp;&nbsp;Contacts
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          props.setTab({
                            type: "dealStage",
                            id: `${props.authUser.id}DealsList`,
                            name: "Deals",
                            blank: true,
                            stageId: "",
                            userId: props.authUser.id,
                            userName: props.authUser.name,
                          });
                        } else {
                          props.setActiveTabIndex(props.totalTabs + 1);
                          props.setTab({
                            type: "dealStage",
                            id: `${props.authUser.id}DealsList`,
                            name: "Deals",
                            userId: props.authUser.id,
                            stageId: "",
                            userName: props.authUser.name,
                          });
                          props.history.push("/active-tabs");
                        }
                      }}
                    >
                      <span>
                        <img src={DealsSettingsIcon} alt="" width="16px" />
                        &nbsp;&nbsp;Deals
                      </span>
                    </li>
                    {props.companyOnboarding.industry ===
                      "Freight Brokerage" && (
                      <li
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({
                              type: "carrierList",
                              id: `${props.authUser.id}CarriersList`,
                              name: "Carriers",
                              blank: true,
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab({
                              type: "carrierList",
                              id: `${props.authUser.id}CarriersList`,
                              name: "Carriers",
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                            props.history.push("/active-tabs");
                          }
                        }}
                      >
                        <span>
                          <img src={CarrierGreenNavIcon} alt="" width="16px" />
                          &nbsp;&nbsp;Carriers
                        </span>
                      </li>
                    )}
                    {(props.authUser.permissions.includes(
                      "crm:unassigned:leads:viewer"
                    ) ||
                      props.authUser.role === "SUPERADMIN") && (
                      <li
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({
                              type: "unassignedList",
                              id: `${props.authUser.id}UnassignedList`,
                              name: "Unassigned Leads",
                              blank: true,
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab({
                              type: "unassignedList",
                              id: `${props.authUser.id}UnassignedList`,
                              name: "Unassigned Leads",
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                            props.history.push("/active-tabs");
                          }
                        }}
                      >
                        <span>
                          <img
                            src={UnassignedLeadIcon}
                            alt=""
                            width="16px"
                            style={{ transform: "translateY(2px)" }}
                          />
                          &nbsp;&nbsp;Unassigned Leads
                        </span>
                      </li>
                    )}
                    <li>
                      <Link
                        to={
                          userData?.is_apollo_connected
                            ? "/apollo-lead-gen"
                            : "integration"
                        }
                      >
                        <span>
                          <img src={ApolloTabLogo} alt="" width="16px" />
                          &nbsp;&nbsp;Apollo Lead Gen
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                {props.companyOnboarding.industry === "Freight Brokerage" && (
                  <li style={{ marginRight: "10px" }}>
                    <Link className="menu nav-button">
                      <img
                        src={LaneNavIcon}
                        alt=""
                        width="20px"
                        style={{ transform: "translateY(-1px)" }}
                      />
                      &nbsp;&nbsp;
                      <li
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({
                              type: "laneList",
                              id: `${props.authUser.id}LanesList`,
                              name: "Lanes",
                              blank: true,
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab({
                              type: "laneList",
                              id: `${props.authUser.id}LanesList`,
                              name: "Lanes",
                              userId: props.authUser.id,
                              userName: props.authUser.name,
                            });
                            props.history.push("/active-tabs");
                          }
                        }}
                        style={{ transform: "translateY(-5px)" }}
                      >
                        Lanes
                      </li>
                    </Link>
                  </li>
                )}
                <li className="has-dropdown">
                  <Link className="menu nav-button">
                    <img
                      src={SendEmailIcon}
                      alt=""
                      width="18px"
                      style={{
                        transform: "translateY(-2px)",
                      }}
                    />
                    &nbsp;&nbsp;
                    <li style={{ transform: "translateY(-5px)" }}>
                      Email{" "}
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                        style={{ fontSize: "8px" }}
                      ></i>
                    </li>
                  </Link>
                  <ul class="nav-dropdown">
                    <li>
                      <Link to="/templates">
                        <img
                          src={TemplateNavIcon}
                          alt=""
                          width="17px"
                          style={{ transform: "translateY(3px)" }}
                        />
                        &nbsp;&nbsp; Templates
                      </Link>
                    </li>
                    <li>
                      <Link to="/sequencing">
                        <img
                          src={SequenceNavIcon}
                          alt=""
                          width="17px"
                          style={{ transform: "translateY(3px)" }}
                        />
                        &nbsp;&nbsp; Sequences
                      </Link>
                    </li>
                    <li>
                      <Link to="/campaign">
                        <img
                          src={BulkEmailNavIcon}
                          alt=""
                          width="17px"
                          style={{ transform: "translateY(3px)" }}
                        />
                        &nbsp;&nbsp; Bulk Email
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/reports" className="menu nav-button">
                    <img
                      src={ReportsLogo}
                      alt=""
                      width="18px"
                      style={{ transform: "translateY(-2px)" }}
                    />
                    &nbsp;&nbsp;
                    <li style={{ transform: "translateY(-6px)" }}>Reports</li>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="customer-1">
              <ul className="customer-1-ul">
                <li>
                  <DropdownAdd
                    setShowAccountModal={
                      props.isAccountFieldsModified
                        ? setShowNewAccountModal
                        : setShowAccountModal
                    }
                    setShowContactModal={
                      props.isContactFieldsModified
                        ? setShowNewContactModal
                        : setShowContactModal
                    }
                    setShowOppModal={
                      props.isDealFieldsModified
                        ? setShowNewOppModal
                        : setShowOppModal
                    }
                    setShowCarrierModal={
                      props.isCarrierFieldsModified
                        ? setShowNewCarrierModal
                        : setShowCarrierModal
                    }
                  />
                </li>
                <li>
                  <DropdownNotification userName={props.authUser?.name} />
                </li>
                <li>
                  <DropdownHeader userName={props.authUser?.name} />
                </li>
              </ul>
            </div>
          </div>
        </Visible>
        <Visible md sm xs>
          <CreateContact
            showOpportunityModal={showContactModal}
            setShowOpportunityModal={() => setShowContactModal(true)}
            handleCloseModal={() => setShowContactModal(false)}
            fromNav={true}
          />
          <CreateContactModal
            showOpportunityModal={showNewContactModal}
            setShowOpportunityModal={() => setShowNewContactModal(true)}
            handleCloseModal={() => setShowNewContactModal(false)}
            fromNav={true}
          />
          <OppModal
            showOpportunityModal={showOppModal}
            setShowOpportunityModal={() => setShowOppModal(true)}
            handleCloseModal={() => setShowOppModal(false)}
            fromNav={true}
          />
          <CreateDealModal
            showOpportunityModal={showNewOppModal}
            setShowOpportunityModal={() => setShowNewOppModal(true)}
            handleCloseModal={() => setShowNewOppModal(false)}
            fromNav={true}
          />
          <CustomerModal
            showOpportunityModal={showAccountModal}
            setShowOpportunityModal={() => setShowAccountModal(true)}
            handleCloseModal={() => setShowAccountModal(false)}
          />
          <CreateAccountModal
            showOpportunityModal={showNewAccountModal}
            setShowOpportunityModal={() => setShowNewAccountModal(true)}
            handleCloseModal={() => setShowNewAccountModal(false)}
          />
          <Row style={{ marginLeft: "0px" }}>
            <Link
              to="/active-tabs"
              onClick={() => {
                props.setActiveTabIndex(0);
              }}
            >
              <img src={Logo} alt="logo" height="30px" className="header-img" />
            </Link>
            &nbsp;&nbsp;&nbsp;
            <div style={{ marginTop: "12px" }}>
              <NavbarSearch />
            </div>
            <div style={{ marginLeft: "68px" }}>
              <DropdownNotification userName={props.authUser?.name} />
            </div>
            <Col>
              <Menu
                id={"sidebar"}
                className={"my-menu"}
                isOpen={menuOpen}
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
                right
                noOverlay
                disableAutoFocus
                onOpen={() => setMenuOpen(!menuOpen)}
                onClose={() => setMenuOpen(!menuOpen)}
              >
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                >
                  Add New {"  "}
                  <i className="fa fa-caret-down"></i>
                </Link>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                    paddingLeft: "15%",
                  }}
                  onClick={() =>
                    props.isAccountFieldsModified
                      ? setShowNewAccountModal(true)
                      : setShowAccountModal(true)
                  }
                >
                  Account
                </Link>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                    paddingLeft: "15%",
                  }}
                  onClick={() =>
                    props.isContactFieldsModified
                      ? setShowNewContactModal(true)
                      : setShowContactModal(true)
                  }
                >
                  Contact
                </Link>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                    borderBottom: "1px solid white",
                    paddingLeft: "15%",
                  }}
                  onClick={() =>
                    props.isDealFieldsModified
                      ? setShowNewOppModal(true)
                      : setShowOppModal(true)
                  }
                >
                  Deal
                </Link>
                <Link
                  to="/"
                  style={{
                    color: "#4A9876",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Dashboard
                </Link>
                <Link
                  to="/calendar"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Calendar
                </Link>
                <Link
                  to="/accounts"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Accounts
                </Link>
                <Link
                  to="/contacts"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Contacts
                </Link>
                <Link
                  to="/deals"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Deals
                </Link>
                <Link
                  to="/reports"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                  }}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Reports
                </Link>
                <div className="dropdownNav">
                  <button
                    className="dropbtnNav"
                    style={{ fontSize: "16px", marginTop: "3px" }}
                  >
                    Settings {"  "}
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown-contentNav">
                    <Link
                      to="/import"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Import Data
                    </Link>
                    <Link
                      to="/integration"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Integrations
                    </Link>
                    <Link
                      to="/dashboard-custom"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Dashboard Customization
                    </Link>
                    <Link
                      to="/account-custom"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Account Fields & Groups
                    </Link>
                    <Link
                      to="/opportunity-custom"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Deal Fields & Groups
                    </Link>
                    <Link
                      to="/contact-custom"
                      style={{ fontSize: "14px" }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      Contact Fields & Groups
                    </Link>
                  </div>
                </div>
                <Link
                  to="/"
                  onClick={() => logoutUser()}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "16px",
                    marginBottom: "3px",
                    padding: "6px 10px",
                    position: "absolute",
                    width: "65%",
                    bottom: "5%",
                    borderTop: "1px solid white",
                  }}
                >
                  Log out
                </Link>
              </Menu>
              <div id="outer-container" style={{ height: "100%" }}>
                {/* {getMenu()} */}
                <main id="page-wrap"></main>
              </div>
            </Col>
          </Row>
        </Visible>
      </div>
    </header>
  );
};

const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    totalTabs: state.tabsState.tabs.length,
    dashboardsState: state.dashboardsState,
    isAccountFieldsModified: state.allAccountFields.isModified,
    isCarrierFieldsModified: state.allCarrierFields.isModified,
    isContactFieldsModified: state.allContactFields.isModified,
    isDealFieldsModified: state.allDealFields.isModified,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    fetchAllDashboards: (dashboardData) =>
      dispatch(fetchAllDashboards(dashboardData)),
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
    fetchAllAllFormCarrierFields: (allCarrierFields) =>
      dispatch(fetchAllAllFormCarrierFields(allCarrierFields)),
    fetchAllAllFormContactFields: (allContactFields) =>
      dispatch(fetchAllAllFormContactFields(allContactFields)),
    fetchAllAllFormDealFields: (allDealFields) =>
      dispatch(fetchAllAllFormDealFields(allDealFields)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(Header2);

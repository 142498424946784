import React, { Component } from "react";
import "../../sass/Reports.scss";
import "../../sass/DropdownHeader.scss";
import axios from "axios";
import moment from "moment";
import ReportIcon from "../../images/ReportsLogo.svg";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import ColumnReportIcon from "../../images/ReportsColumnIcon.svg";
import ScoreCardIcon from "../../images/scorecard.png";
import { Col, Row, setConfiguration } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import TransferOwnershipModal from "./TransferOwnershipModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DeleteReportModal from "./DeleteReportModal";
import ExitReportModal from "./ExitReportModal";
import ExportButtonIcon from "../../images/ExportButtonIcon.svg";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import PostMarkAppModal from "./PostMarkAppModal";
import PostMarkAppVerificationModal from "./PostMarkAppVerificationModal";
import SendEmailReportModal from "../SendEmail/SendEmailReportModal";
import BillingRedirectModal from "./BillingRedirectModal";
import BillingCreditModal from "./BillingCreditModal";
import DealsColumn from "../Analytics/Pages/Deals/DealsColumn";
import DealsPie from "../Analytics/Pages/Deals/DealsPie";
import DealsLine from "../Analytics/Pages/Deals/DealsLine";
import DealsScoreCard from "../Analytics/Pages/Deals/DealsScoreCard";
import ReportExitDropdown from "./ReportExitDropdown";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usStatesList } from "../../constants/usStatesList";
import { countriesList } from "../../constants/countriesList";
import StageReasonModal from "../Deals/StageReasonModal";
import ReusableModal from "../Reusables/ReusableModal";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
} from "../../redux";
import InputField from "../Reusables/InputField/InputField";
import { SlidersHorizontal } from "lucide-react";
import { Plus } from "lucide-react";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

setConfiguration({ gutterWidth: "0" });

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(({ column, sortButtonClickHandler }) => (
  <th>
    <Row>
      <Col lg={9} xl={9}>
        <DragHandle />
        {column.name}
      </Col>
      <Col lg={3} xl={3}>
        <button
          type="button"
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => sortButtonClickHandler(column)}
        >
          <i className="fa fa-long-arrow-up"></i>
          <i className="fa fa-long-arrow-down"></i>
        </button>
      </Col>
    </Row>
  </th>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <thead>{children}</thead>;
});

class ReportsDealTable extends Component {
  state = {
    isInitializing: true,
    filter: "",
    operator: "",
    filter_rules: [
      { id: uuidv4(), attribute: "", operator: "", value: null, type: null },
    ],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    isEditing: false,
    reportName: this.props.reportName,
    reportDescription: "",
    errorMessage: false,
    sortKey: null,
    displayExitMenu: false,
    sortDirection: false,
    isLoading: false,
    exportLoader: false,
    isApplying: false,
    transferAccountEmail: [],
    excludeArray: [],
    excludeEmailArray: [],
    showStageModal: false,
    currentStageId: "",
    currentDealData: {},
    currentStageType: "",
    customFieldRevenueTypeCheck: [],
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    revenueFieldsSum: {},
    revenueSum: "0",
    columnsArray: [
      {
        name: "Owner",
        key: "opportunity_owner",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Deal Name",
        key: "name",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Stage",
        key: "stage_name",
        selected: true,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Revenue",
        key: "revenue",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Next Step",
        key: "next_step",
        selected: true,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Created Date",
        key: "created_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Close Date",
        key: "close_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: true,
      },
      {
        name: "Email",
        key: "email",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Phone",
        key: "phone",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "City",
        key: "city",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "State",
        key: "state",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Zip",
        key: "zip",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Reason",
        key: "close_reason",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Account Name",
        key: "account_name",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Description",
        key: "description",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: true,
      },
      {
        name: "Age",
        key: "age",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Stage Age",
        key: "stage_age",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Prev. Stage",
        key: "prev_stage",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Stage Change Date",
        key: "stage_changed_at",
        selected: false,
        custom: false,
        type: "text",
        choices: [],
        editable: false,
      },
      {
        name: "Country",
        key: "country",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Source",
        key: "source",
        selected: false,
        custom: false,
        type: "dropdown",
        choices: [],
        editable: true,
      },
      {
        name: "Last Edit Date",
        key: "last_edit_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
      {
        name: "Last Activity Date",
        key: "last_activity_date",
        selected: false,
        custom: false,
        type: "date",
        choices: [],
        editable: false,
      },
    ],
    stages: [],
    columnsData: [],
    showDeleteModal: false,
    showExitModal: false,
    showEmailModal: false,
    showPostMarkModal: false,
    showVerificationModal: false,
    signatureVerified: false,
    signatureId: null,
    activePage: 1,
    total: 0,
    transferAccount: [],
    allReportTransfer: false,
    showOwnerModal: false,
    ownerErrorMessage: false,
    customFields: [],
    access: "everyone",
    subscriptionStatus: null,
    showRedirectModal: false,
    emailCredits: 0,
    showCreditsModal: false,
    analyticsMode: "table",
    showBulkDeleteModal: false,
    bulkDeleteLoader: false,
    anchorEl: null,
    inputSelectedReports: 0,
    currentPage: 1,
    isSelectedReportLoading: false,
    selectAllRecords: false,
    selectPageRecords: false,
    currentPageReportsTransfer: false,
  };
  wrapperRef = React.createRef();
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  accessChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ access: "everyone" });
    } else {
      this.setState({ access: "only_me" });
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };
  showExitDropdownMenu = (e) => {
    e.preventDefault();
    this.setState({ displayExitMenu: true }, () => {
      document.addEventListener("click", this.hideExitDropdownMenu);
    });
  };
  hideExitDropdownMenu = () => {
    this.setState({ displayExitMenu: false }, () => {
      document.removeEventListener("click", this.hideExitDropdownMenu);
    });
  };
  handleOpenModal = (propertyValue, dealData, type) => {
    this.setState(
      {
        showStageModal: true,
      },
      () => this.openStageModal(propertyValue, dealData, type)
    );
  };
  handleCloseModal = () => {
    this.setState({
      showStageModal: false,
    });
  };
  handleOwnerOpenModal = () => {
    this.setState({
      showOwnerModal: true,
    });
  };
  handleOwnerCloseModal = () => {
    this.setState({
      showOwnerModal: false,
    });
  };
  handleBulkDeleteOpenModal = () => {
    this.setState({
      showBulkDeleteModal: true,
    });
  };
  handleBulkDeleteCloseModal = () => {
    this.setState({
      showBulkDeleteModal: false,
    });
  };
  handleRedirectOpenModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleRedirectCloseModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleCreditsOpenModal = () => {
    this.setState({
      showCreditsModal: true,
    });
  };
  handleCreditsCloseModal = () => {
    this.setState({
      showCreditsModal: false,
    });
  };
  handleDeleteOpenModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  handleDeleteCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleEmailOpenModal = () => {
    this.setState({
      showEmailModal: true,
    });
  };
  handleEmailCloseModal = () => {
    this.setState({
      showEmailModal: false,
    });
  };
  handlePostMarkOpenModal = () => {
    this.setState({
      showPostMarkModal: true,
    });
  };
  handlePostMarkCloseModal = () => {
    this.setState({
      showPostMarkModal: false,
    });
  };
  handleVerificationOpenModal = () => {
    this.setState({
      showVerificationModal: true,
    });
  };
  handleVerificationCloseModal = () => {
    this.setState({
      showVerificationModal: false,
    });
  };
  checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.verifySignature(res.data.postmark_signature_verified);
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          this.handleVerificationCloseModal();
          this.checkUserProfile();
          this.handleCreditsOpenModal();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  emailDecision = () => {
    if (this.state.subscriptionStatus !== "active") {
      this.handleRedirectOpenModal();
    } else if (this.state.signatureId === null) {
      this.handlePostMarkOpenModal();
    } else if (!this.state.signatureVerified) {
      this.handleVerificationOpenModal();
    } else if (
      this.state.emailCredits === null ||
      this.state.transferAccountEmail.length > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else if (
      (this.state.emailCredits === null ||
        this.state.transferAccountEmail.length === 0) &&
      this.state.total > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else {
      this.handleEmailOpenModal();
    }
  };
  handleExitOpenModal = () => {
    this.setState({
      showExitModal: true,
    });
  };
  handleExitCloseModal = () => {
    this.setState({
      showExitModal: false,
    });
  };
  filterRulesIsIn = () => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules?.map((rule) => {
        const { operator, value } = rule;
        if (operator === "is_in" || operator === "is_not_in") {
          rule.value = value.map((val) => ({ value: val, label: val }));
        }
        return rule;
      }),
    }));
  };
  getReportById = () => {
    axios({
      method: "GET",
      url: `/reports/${this.props.reportId}`,
    }).then((res) => {
      const columns = res.data.report.columns.replace("mobile", "phone");
      const columnsPositionArray = columns.split(",");
      this.setState(
        {
          reportsData: res.data.report,
          columnsData: columns,
          reportName: res.data.report.name,
          access: res.data.report.access,
          reportDescription: res.data.report.description,
          reportType: res.data.report.type,
          filter_rules: res.data.report.filters?.map((rule) => {
            const { operator, value } = rule;
            if (operator === "is_in" || operator === "is_not_in") {
              if (rule.attribute === "stage") {
                rule.value = value.map((val) => ({
                  value: val,
                  label: this.state.stages.find((stage) => stage.id === val)
                    ?.name,
                }));
              } else {
                rule.value = value.map((val) => ({
                  value: val,
                  label: val,
                }));
              }
            }
            return rule;
          }),
          columnsArray: [
            {
              name: "Owner",
              key: "opportunity_owner",
              selected: columns.includes("opportunity_owner") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("opportunity_owner")
                ? columnsPositionArray.indexOf("opportunity_owner")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Deal Name",
              key: "name",
              selected: columns.includes("name") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("name")
                ? columnsPositionArray.indexOf("name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  1,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Stage",
              key: "stage_name",
              selected: columns.includes("stage_name") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("stage_name")
                ? columnsPositionArray.indexOf("stage_name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  2,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Revenue",
              key: "revenue",
              selected: columns.includes("revenue") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("revenue")
                ? columnsPositionArray.indexOf("revenue")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  3,
              type: "decimal",
              choices: [],
              editable: true,
            },
            {
              name: "Next Step",
              key: "next_step",
              selected: columns.includes("next_step") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("next_step")
                ? columnsPositionArray.indexOf("next_step")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  4,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Created Date",
              key: "created_date",
              selected: columns.includes("created_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("created_date")
                ? columnsPositionArray.indexOf("created_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  5,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Close Date",
              key: "close_date",
              selected: columns.includes("close_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("close_date")
                ? columnsPositionArray.indexOf("close_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  6,
              type: "date",
              choices: [],
              editable: true,
            },
            {
              name: "Email",
              key: "email",
              selected: columns.includes("email") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("email")
                ? columnsPositionArray.indexOf("email")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  7,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Phone",
              key: "phone",
              selected: columns.includes("phone") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("phone")
                ? columnsPositionArray.indexOf("phone")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  8,
              type: "decimal",
              choices: [],
              editable: true,
            },
            {
              name: "City",
              key: "city",
              selected: columns.includes("city") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("city")
                ? columnsPositionArray.indexOf("city")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  9,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "State",
              key: "state",
              selected: columns.includes("state") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("state")
                ? columnsPositionArray.indexOf("state")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  10,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Zip",
              key: "zip",
              selected: columns.includes("zip") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("zip")
                ? columnsPositionArray.indexOf("zip")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  11,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Reason",
              key: "close_reason",
              selected: columns.includes("close_reason") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("close_reason")
                ? columnsPositionArray.indexOf("close_reason")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  12,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Account Name",
              key: "account_name",
              selected: columns.includes("account_name") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("account_name")
                ? columnsPositionArray.indexOf("account_name")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  13,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Description",
              key: "description",
              selected: columns.includes("description") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("description")
                ? columnsPositionArray.indexOf("description")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  14,
              type: "text",
              choices: [],
              editable: true,
            },
            {
              name: "Age",
              key: "age",
              selected: columns.includes("age") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("age")
                ? columnsPositionArray.indexOf("age")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  15,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Stage Age",
              key: "stage_age",
              selected: columns.includes("stage_age") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("stage_age")
                ? columnsPositionArray.indexOf("stage_age")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  16,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Prev. Stage",
              key: "prev_stage",
              selected: columns.includes("prev_stage") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("prev_stage")
                ? columnsPositionArray.indexOf("prev_stage")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  17,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Stage Change Date",
              key: "stage_changed_at",
              selected: columns.includes("stage_changed_at") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("stage_changed_at")
                ? columnsPositionArray.indexOf("stage_changed_at")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  18,
              type: "text",
              choices: [],
              editable: false,
            },
            {
              name: "Country",
              key: "country",
              selected: columns.includes("country") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("country")
                ? columnsPositionArray.indexOf("country")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  19,
              type: "dropdown",
              choices: [],
              editable: true,
            },
            {
              name: "Source",
              key: "source",
              selected: columns.includes("source") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("source")
                ? columnsPositionArray.indexOf("source")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  20,
              type: "dropdown",
              choices: this.props.allAccountSources,
              editable: true,
            },
            {
              name: "Last Edit Date",
              key: "last_edit_date",
              selected: columns.includes("last_edit_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("last_edit_date")
                ? columnsPositionArray.indexOf("last_edit_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  21,
              type: "date",
              choices: [],
              editable: false,
            },
            {
              name: "Last Activity Date",
              key: "last_activity_date",
              selected: columns.includes("last_activity_date") ? true : false,
              custom: false,
              position: columnsPositionArray.includes("last_activity_date")
                ? columnsPositionArray.indexOf("last_activity_date")
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  22,
              type: "date",
              choices: [],
              editable: false,
            },
          ],
        },
        () => this.getReportInitializer(res.data.report.order_by),
        () => this.filterRulesIsIn()
      );
      this.getCustomFields(columns, columnsPositionArray);
    });
  };
  logCall = (description, selectedDeal) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #4A9876;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        opportunity_id: selectedDeal.id,
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedDeal = this.state.reportsTable?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedDeal) {
        this.logCall(data.call, selectedDeal);
      }
    }
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("message", this.handleClickToDial);
    this.checkUserProfile();
    this.checkSubscription();
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => funnel.is_default === true && funnel)
        .filter((funnel) => funnel !== false);
      this.setState(
        {
          allFunnels: res.data.funnels,
          selectedFunnel: selectedFunnel[0],
          stages: selectedFunnel[0].stages.map((stage) => {
            stage.destroy = false;
            return stage;
          }),
          funnelId: selectedFunnel[0].id,
        },
        () => {
          this.getReportById();
        }
      );
    });
  }
  getReportInitializer = (orderby, page = 1) => {
    const orderBy = orderby?.split(",");
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    this.setState({
      sortKey: orderby === null ? null : orderBy[0],
      sortDirection:
        orderby === null ? null : orderBy[1] === "desc" ? true : false,
    });
    axios({
      method: "POST",
      url: `/opportunities/reports?_limit=30&_page=${page}`,
      data: {
        filter_rules: newValue,
        sort_key: orderby === null ? null : orderBy[0],
        sort_dir: orderby === null ? null : orderBy[1],
      },
    })
      .then((res) => {
        this.setState({
          reportsTable: res.data.opportunities,
          revenueFieldsSum: res.data.revenue_fields,
          revenueSum: res.data.total_revenue,
          total: res.data.total,
          isInitializing: false,
        });
      })
      .catch((err) => this.setState({ isInitializing: false }));
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  renderTableData = (
    dealData,
    tableData,
    name,
    value,
    dateFormat,
    newTabData,
    currencySymbol
  ) => {
    let renderItem = <td>{value}</td>;
    if (!tableData.editable) {
      if (tableData.type === "date") {
        renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
      } else {
        renderItem = <td>{value}</td>;
      }
    } else if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: dealData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === dealData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  dealData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "website" || tableData.type === "hyperlink") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
            >
              <a
                href={
                  value
                    ? value.startsWith("https://") ||
                      value.startsWith("http://")
                      ? value
                      : "https://" + value
                    : null
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: "pointer",
                  color: "#4A9876",
                }}
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: dealData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === dealData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  dealData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "phone" || name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { color: "#4A9876" }
              }
            >
              <a
                target="_blank"
                style={{ color: "#4A9876", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === dealData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: dealData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === dealData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(
                  dealData,
                  name,
                  e.target.value,
                  tableData.custom
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "revenue" || name === "revenue") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value && currencySymbol}
            {value && getCurrencyValue(value)?.toLocaleString("en-US")}
          </span>
          <input
            type="text"
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldRevenueChangeHandler(
                dealData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (
      tableData.type === "text" ||
      tableData.type === "paragraph" ||
      tableData.type === "decimal"
    ) {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            type={tableData.type === "decimal" ? "number" : "text"}
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(
                dealData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      const newDate = value ? new Date(value) : new Date();
      const offsetDateCalc =
        newDate === null || newDate === ""
          ? new Date()
          : newDate.getTimezoneOffset() < 0
          ? new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
          : new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
      const offsetDate = offsetDateCalc;
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value && moment(value).format(dateFormat)}
          </span>
          <DatePicker
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
            selected={offsetDate}
            autoComplete="off"
            todayButton="Today"
            utcOffset={0}
            value={offsetDate}
            onChange={(date) => {
              this.fieldChangeHandler(dealData, name, date, tableData.custom);
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              });
            }}
            placeholderText="Date"
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            className="inlineReportEdit-input"
          />
        </td>
      );
    } else if (tableData.type === "dropdown" || tableData.type === "radio") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {name === "stage_id" ? dealData.stage_name : value}
          </span>
          {name === "state" ? (
            dealData.country === "United States" ? (
              <select
                className="inlineReportEdit-input"
                value={value}
                onChange={(e) =>
                  this.fieldChangeHandler(
                    dealData,
                    name,
                    e.target.value,
                    tableData.custom
                  )
                }
                onFocus={() =>
                  this.setState({
                    inputFocused: {
                      id: dealData.id,
                      isTrue: true,
                      name: name,
                    },
                  })
                }
                onBlur={() =>
                  this.setState({
                    inputFocused: {
                      id: "",
                      isTrue: false,
                      name: "",
                    },
                  })
                }
              >
                <option value="" selected disabled>
                  -Select-
                </option>
                {usStatesList.map((list) => (
                  <option key={list} value={list}>
                    {list}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="inlineReportEdit-input"
                value={value}
                onChange={(e) =>
                  this.fieldChangeHandler(
                    dealData,
                    name,
                    e.target.value,
                    tableData.custom
                  )
                }
                onFocus={() =>
                  this.setState({
                    inputFocused: {
                      id: dealData.id,
                      isTrue: true,
                      name: name,
                    },
                  })
                }
                onBlur={() =>
                  this.setState({
                    inputFocused: {
                      id: "",
                      isTrue: false,
                      name: "",
                    },
                  })
                }
              />
            )
          ) : (
            <select
              className="inlineReportEdit-input"
              value={value}
              onChange={(e) =>
                this.fieldChangeHandler(
                  dealData,
                  name,
                  e.target.value,
                  tableData.custom,
                  dealData.funnel_id
                )
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: dealData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                })
              }
            >
              <option value="" selected disabled>
                -Select-
              </option>
              {name === "source" || name === "status"
                ? tableData.choices?.map((choice) => (
                    <option key={choice.id} value={choice.name}>
                      {choice.name}
                    </option>
                  ))
                : name === "stage_id"
                ? this.state.allFunnels
                    ?.find((funnel) => funnel.id === dealData.funnel_id)
                    .stages?.map((stage) => (
                      <option value={stage.id}>{stage.name}</option>
                    ))
                : name === "country"
                ? countriesList?.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))
                : tableData.choices?.map((choice) => (
                    <option key={choice.id} value={choice.value}>
                      {choice.value}
                    </option>
                  ))}
            </select>
          )}
        </td>
      );
    } else if (tableData.type === "checkbox") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.toString()}
          </span>
          <input
            type="checkbox"
            className="inlineReportEdit-input"
            value={value}
            checked={value}
            style={{ width: "15px" }}
            onChange={() =>
              this.fieldChangeHandler(
                dealData,
                name,
                (!value).toString(),
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "multi_select_dropdown") {
      const valueArray = value
        ?.split(";")
        ?.filter((val) => val !== "")
        ?.map((val) => ({ label: val, value: val }));
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value?.toString().startsWith(";")
              ? value.replace(/;/g, ", ").substr(1).slice(0, -2)
              : value}
          </span>
          <Select
            autoFocus={
              this.state.inputFocused.id === dealData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
            }
            className="inlineReportEdit-input"
            closeMenuOnSelect={false}
            value={valueArray}
            isMulti
            name={name}
            // styles={colourStyles}
            options={tableData.choices?.map((choice) => {
              const { value } = choice;
              return { value: value, label: value };
            })}
            menuPlacement="auto"
            classNamePrefix="select"
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                dealData,
                name,
                selectedOption
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: dealData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (deal, name, value, isCustom, funnelId) => {
    let dealData = deal;
    let stage_name = "";
    if (funnelId && name === "stage_id") {
      const selectedStage = this.state.allFunnels
        ?.find((funnel) => funnel.id === funnelId)
        .stages?.find((stage) => stage.id.toString() === value.toString());
      stage_name = selectedStage.name;
    }
    let formattedValue = value;
    if (name === "phone" || name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    dealData = isCustom
      ? {
          ...deal,
          custom_fields: { ...deal.custom_fields, [name]: formattedValue },
        }
      : name === "stage_id"
      ? {
          ...deal,
          [name]: formattedValue === "" ? null : formattedValue,
          stage_name,
        }
      : { ...deal, [name]: formattedValue === "" ? null : formattedValue };
    this.setState({
      reportsTable: this.state.reportsTable?.map((acc) => {
        if (acc.id === deal.id) {
          if (isCustom) {
            acc = {
              ...deal,
              custom_fields: {
                ...deal.custom_fields,
                [name]: formattedValue === "" ? null : formattedValue,
              },
            };
          } else if (name === "stage_id") {
            acc = {
              ...deal,
              [name]: formattedValue === "" ? null : formattedValue,
              stage_name,
            };
          } else {
            acc = {
              ...deal,
              [name]: formattedValue === "" ? null : formattedValue,
            };
          }
        }
        return acc;
      }),
    });
    this.submitdealDataHandler(dealData, name, formattedValue);
  };
  fieldRevenueChangeHandler = (deal, name, value, isCustom) => {
    let dealData = deal;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      dealData = isCustom
        ? {
            ...deal,
            custom_fields: { ...deal.custom_fields, [name]: value },
          }
        : { ...deal, [name]: value === "" ? null : value };
      this.setState({
        reportsTable: this.state.reportsTable?.map((acc) => {
          if (acc.id === deal.id) {
            if (isCustom) {
              acc = {
                ...deal,
                custom_fields: {
                  ...deal.custom_fields,
                  [name]: value === "" ? null : value,
                },
              };
            } else {
              acc = { ...deal, [name]: value === "" ? null : value };
            }
          }
          return acc;
        }),
      });
      this.submitdealDataHandler(dealData, name, value);
    }
  };
  customFieldMultiDropdownChangeHandler = (
    deal,
    fieldName,
    selectedOptions
  ) => {
    let dealData = deal;
    this.setState((prevState) => ({
      reportsTable: this.state.reportsTable?.map((acc) => {
        if (acc.id === deal.id) {
          acc = {
            ...deal,
            custom_fields: {
              ...deal.custom_fields,
              [fieldName]:
                ";" +
                selectedOptions?.map((option) => option.value)?.join(";") +
                ";",
            },
          };
        }
        return acc;
      }),
    }));
    this.submitdealDataHandler(dealData);
  };
  submitdealDataHandler = debounce((dealData, propertyName, propertyValue) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (propertyName === "stage_id") {
      const newStage = this.state.allFunnels
        ?.find((funnel) => funnel.id === dealData.funnel_id)
        .stages.find((stage) => stage.id === parseInt(propertyValue));
      if (newStage.type === "won" || newStage.type === "lost") {
        return this.setState(
          {
            currentStageId: propertyValue,
            currentDealData: dealData,
            currentStageType: newStage.type,
          },
          () => this.handleOpenModal(propertyValue, dealData, newStage.type)
        );
      }
    }
    if (
      dealData.phone !== null &&
      dealData.phone !== "" &&
      !phoneValidator.test(dealData.phone)
    ) {
      return false;
    }
    if (
      dealData.email !== null &&
      dealData.email !== "" &&
      !emailValidator.test(dealData.email)
    ) {
      return false;
    }
    if (!dealData.name) {
      return false;
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      dealData.custom_fields[i] = getCurrencyValue(dealData.custom_fields[i]);
    }
    const calculatedRevenue = getCurrencyValue(dealData.revenue);
    axios({
      method: "PUT",
      url: `/opportunities/${dealData.id}`,
      data: {
        ...(dealData.accountId && { account_id: dealData.accountId }),
        name: dealData?.name,
        revenue: calculatedRevenue,
        stage_id: dealData?.stage_id,
        funnel_id: dealData?.funnel_id,
        close_date: dealData.close_date,
        description: dealData.description,
        opportunity_owner_id: dealData.opportunity_owner_id,
        close_reason: dealData?.close_reason_id,
        custom_fields: dealData.custom_fields,
        source: dealData.source,
        email: dealData.email,
        next_step: dealData.next_step,
        formatted_phone:
          dealData.formatted_phone === undefined ||
          dealData.formatted_phone === "undefined"
            ? null
            : dealData.formatted_phone !== null
            ? dealData.formatted_phone.toString()
            : dealData.formatted_phone,
        website:
          dealData.website === null
            ? null
            : dealData.website?.startsWith("https://") ||
              dealData.website?.startsWith("http://")
            ? dealData.website
            : "https://" + dealData.website,
      },
    })
      .then((response) => {
        // NotificationManager.success("Deal edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing deal.");
        }
      });
  }, 500);
  openStageModal = (stage_id, dealData, currentStageType) => {
    return (
      <StageReasonModal
        showOpportunityModal={this.state.showStageModal}
        setShowOpportunityModal={this.handleOpenModal}
        handleCloseModal={this.handleCloseModal}
        stage_id={stage_id}
        opportunityId={dealData?.id}
        customerId={dealData?.account_id}
        contactId={dealData?.contact_id}
        state={{
          ...dealData,
          customFields: dealData.custom_fields,
        }}
        currentStageType={currentStageType}
        fetchOpportunitiesData={this.getReport}
        getConfetti={() => console.log("hi")}
        funnels={this.state.allFunnels}
        funnelId={dealData.funnel_id}
        users={this.props.allUsers}
        ownerId={dealData?.opportunity_owner_id}
      />
    );
  };
  getCustomFields = (selectedArray, columnsPositionArray) => {
    this.setState({
      customFields: this.props.allDealCustomFields,
    });
    const customField = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field, index) => {
            const { label, name, id, type, choices } = field;
            return {
              name: label,
              key: name,
              selected: selectedArray.includes(id) ? true : false,
              custom: true,
              id,
              position: columnsPositionArray.includes(id.toString())
                ? columnsPositionArray.indexOf(id.toString())
                : this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  this.state.columnsArray.length +
                  index,
              type,
              choices,
              editable: true,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    const customFieldTypeCheck = this.props.allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState({
      columnsArray: columnsArray.concat(customFieldArray),
      customFieldRevenueTypeCheck: objTypeCheck,
    });
  };
  getReport = (page = 1) => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/opportunities/reports?_limit=30&_page=${page}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
        data: {
          filter_rules: newValue,
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.opportunities,
            revenueFieldsSum: res.data.revenue_fields,
            revenueSum: res.data.total_revenue,
            total: res.data.total,
            isApplying: false,
            selectPageRecords: false, // To Reset Select this Page Filter On New Page
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  changeInfoHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  deleteReport = (id, e) => {
    e.preventDefault();
    axios({
      method: "DELETE",
      url: `/reports/${id}`,
    }).then((res) => this.props.deleteTab(this.props.deleteTabIndex));
  };
  changeType = (index, e) => {
    const { value } = e.target;
    const fieldType = this.state.customFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.find((field) => field.id === parseInt(value))
        );
      })
      .filter((data) => data !== undefined && data !== false);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
    }));
  };
  changeHandler = (index, e) => {
    const { name, value } = e.target;
    name === "attribute" && this.changeType(index, e);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_14_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
          if (rule.operator === "custom_period") {
            rule.value = [];
          }
        }
        return rule;
      }),
    }));
  };
  valueChangeHandler = (index, valueIndex, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }
          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
    }));
  };
  isInChangeHandler = (index, selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected;
        }
        return rule;
      }),
    }));
  };
  addFilter = () => {
    this.setState((prevState) => ({
      filter_rules: [
        ...prevState.filter_rules,
        { id: uuidv4(), attribute: "", operator: "", value: [] },
      ],
    }));
  };
  funnelChangeHandler = (e) => {
    const { value } = e.target;
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      const selectedFunnel = res.data.funnels
        ?.map((funnel) => {
          return funnel.id === parseInt(value) && funnel;
        })
        .filter((funnel) => funnel !== false);
      this.setState({
        allFunnels: res.data.funnels,
        selectedFunnel: selectedFunnel[0],
        stages: selectedFunnel[0].stages?.map((stage) => {
          stage.destroy = false;
          return stage;
        }),
        funnelId: value,
      });
    });
  };
  deleteFilter = (index) => {
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.filter((rule, i) => i !== index),
    }));
  };
  checkSubscription = () => {
    axios({
      method: "GET",
      url: `/company/${this.props.authUser.company_id}/subscription-status`,
    }).then((res) => {
      this.setState({
        subscriptionStatus: res.data.subscription.status,
      });
    });
  };
  transferOwnership = (new_owner_id) => {
    if (new_owner_id === null) {
      this.setState({ ownerErrorMessage: true });
      return false;
    }
    if (this.state.allReportTransfer === true) {
      axios({
        method: "PUT",
        url: `/admin/change-owner`,
        data: {
          report_id: this.props.reportId,
          excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
          new_owner_id,
        },
      })
        .then((res) => {
          this.getReport();
          this.handleOwnerCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Ownership transferred successfully.");
        })
        .catch((err) =>
          NotificationManager.error("Error transferring ownership")
        );
    } else {
      axios({
        method: "PUT",
        url: `/admin/change-owner`,
        data: {
          opportunity_ids: this.state.transferAccount,
          new_owner_id,
        },
      })
        .then((res) => {
          this.getReport();
          this.handleOwnerCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Ownership transferred successfully.");
        })
        .catch((err) =>
          NotificationManager.error("Error transferring ownership")
        );
    }
  };
  bulkDelete = () => {
    if (this.state.allReportTransfer === true) {
      this.setState({
        bulkDeleteLoader: true,
      });
      axios({
        method: "PUT",
        url: `/admin/bulk-delete`,
        data: {
          report_id: this.props.reportId,
          excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
        },
      })
        .then((res) => {
          this.getReport();
          this.handleBulkDeleteCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            bulkDeleteLoader: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Records deleted successfully.");
        })
        .catch((err) => {
          NotificationManager.error("Error deleting records");
          this.setState({
            bulkDeleteLoader: false,
          });
        });
    } else {
      this.setState({
        bulkDeleteLoader: true,
      });
      axios({
        method: "PUT",
        url: `/admin/bulk-delete`,
        data: {
          opportunity_ids: this.state.transferAccount,
        },
      })
        .then((res) => {
          this.getReport();
          this.handleBulkDeleteCloseModal();
          this.setState({
            transferAccount: [],
            transferAccountEmail: [],
            allReportTransfer: false,
            bulkDeleteLoader: false,
            excludeArray: [],
            excludeEmailArray: [],
          });
          NotificationManager.success("Records deleted successfully.");
        })
        .catch((err) => {
          NotificationManager.error("Error deleting records");
          this.setState({
            bulkDeleteLoader: false,
          });
        });
    }
  };
  allReportTransfer = () => {
    this.setState({
      allReportTransfer: !this.state.allReportTransfer,
      transferAccount: [],
      transferAccountEmail: [],
      excludeArray: [],
      excludeEmailArray: [],
    });
  };

  applySelectedReports = () => {
    if (this.state.selectAllRecords && !this.state.allReportTransfer) {
      this.allReportTransfer();
    } else if (this.state.allReportTransfer && !this.state.selectAllRecords) {
      this.allReportTransfer();
    }
    if (this.state.selectPageRecords) {
      this.currentPageReportTransfer();
    } else if (
      !this.state.selectPageRecords &&
      !this.state.selectAllRecords &&
      !this.state.inputSelectedReports
    ) {
      this.currentPageReportTransfer();
    }
    if (this.state.inputSelectedReports > 0) {
      if (this.state.inputSelectedReports <= 30) {
        this.setState({
          transferAccount: this.state?.reportsTable
            .slice(0, this.state?.inputSelectedReports)
            ?.map((item) => item.id.toString()),
          transferAccountEmail: this.state.reportsTable
            ?.slice(0, this.state.inputSelectedReports)
            ?.map((item) => ({
              email: item.email,
            })),
        });
      } else {
        this.getReportByInput();
      }
    }
    this.handleClose();
  };

  getReportByInput = () => {
    this.setState({ isSelectedReportLoading: true });

    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });

    axios({
      method: "POST",
      url: `/opportunities/reports?_limit=${this.state.inputSelectedReports}&_page=1`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
      data: {
        filter_rules: newValue,
        sort_key: this.state.sortKey,
        sort_dir: this.state.sortDirection === true ? "desc" : "asc",
      },
    })
      .then((res) => {
        this.setState({
          transferAccount: res.data.opportunities.map((item) =>
            item.id.toString()
          ),
          transferAccountEmail: res.data.opportunities.map((item) => ({
            email: item.email,
          })),
          isSelectedReportLoading: false,
        });
      })
      .catch((err) => this.setState({ isApplying: false }));
  };

  currentPageReportTransfer = () => {
    if (this.state.selectPageRecords) {
      this.setState({
        currentPageReportsTransfer: !this.state.currentPageReportsTransfer,
        transferAccount: this.state.reportsTable.map((item) =>
          item.id.toString()
        ),
        transferAccountEmail: this.state.reportsTable.map((item) => ({
          email: item.email,
        })),
      });
    } else {
      this.setState({
        currentPageReportsTransfer: !this.state.currentPageReportsTransfer,
        transferAccount: [],
        transferAccountEmail: [],
      });
    }
  };
  transferAccountChangeHandler = (report, e) => {
    const { value } = e.target;
    if (e.target.name === "transferAccount") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          transferAccount: [
            ...this.state.transferAccount.filter((com) => com !== ""),
            value,
          ],
          transferAccountEmail: [
            ...this.state.transferAccountEmail.filter((com) => com !== ""),
            { email: report.email, opportunity_id: report.id },
          ],
        }));
      } else {
        this.setState({
          transferAccount: this.state.transferAccount.filter(
            (pc) => pc !== value
          ),
          transferAccountEmail: this.state.transferAccountEmail.filter(
            (pc) => pc.email !== report.email
          ),
        });
      }
    }
  };
  excludeAccountChangeHandler = (email, e) => {
    const { value } = e.target;
    if (e.target.checked === false) {
      this.setState((prevState) => ({
        excludeArray: [
          ...this.state.excludeArray.filter((com) => com !== ""),
          value,
        ],
        excludeEmailArray: [
          ...this.state.excludeEmailArray.filter((com) => com !== ""),
          email,
        ],
      }));
    } else {
      this.setState({
        excludeArray: this.state.excludeArray.filter((pc) => pc !== value),
        excludeEmailArray: this.state.excludeEmailArray.filter(
          (pc) => pc !== email
        ),
      });
    }
  };
  storeSorting = () => {
    this.getReport();
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    axios({
      method: "PATCH",
      url: `/reports/${this.props.reportId}`,
      data: {
        order_by: `${this.state.sortKey},${sortDirection}`,
      },
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isLoading: true });
    let newValue = [];
    newValue = this.state.filter_rules.map((rule) => {
      let { attribute, operator, value } = rule;
      if (
        Array.isArray(value) &&
        (operator === "is_in" || operator === "is_not_in")
      )
        value = value.map((val) => val.value);
      return {
        attribute,
        operator,
        value,
      };
    });
    let sortDirection = "asc";
    this.state.sortDirection === true
      ? (sortDirection = "desc")
      : (sortDirection = "asc");
    let order_by =
      this.state.sortKey === null
        ? null
        : `${this.state.sortKey},${sortDirection}`;
    if (checkError === true) {
      axios({
        method: "PUT",
        url: `/reports/${this.props.reportId}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
        data: {
          name: this.state.reportName,
          type: this.state.reportType,
          description: this.state.reportDescription,
          access: this.state.access,
          filters: newValue,
          columns: this.state.columnsArray
            .filter((columns) => columns.selected === true)
            .map((columns) => (columns.custom ? columns.id : columns.key)),
          order_by,
        },
      })
        .then((res) => {
          this.getReportById();
          this.setState({ isLoading: false, isEditing: false });
          NotificationManager.success("Report edited successfully.");
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          NotificationManager.error("Error editing report.");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };
  intialFilters = () => {
    this.setState({
      filter_rules: this.state?.reportsData.filters,
    });
  };
  sortButtonClickHandler = (column) => {
    this.setState(
      {
        sortKey: column.custom ? column.id : column.key,
        sortDirection: !this.state.sortDirection,
      },
      this.getReport
    );
  };
  downloadReport = () => {
    this.setState({ exportLoader: true });
    axios({
      method: "POST",
      url: `/${this.state.reportType}/export`,
      data: {
        report_id: this.props.reportId,
      },
    })
      .then((data) => {
        console.log("KKOO", data);
        let a = document.createElement("a");
        a.href = data.data.file_url;
        // a.download = "employees.json";
        a.click();
        this.setState({ exportLoader: false });
      })
      .catch((err) => this.setState({ exportLoader: false }));
  };

  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const { columnsArray } = this.state;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        minWidth: "200px",
        outline: "#C0EAD8",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        marginRight: "12px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div
        className="reports"
        style={
          this.state.isEditing === true ? { padding: "0px", margin: "0px" } : {}
        }
      >
        <ReactTooltip />
        {this.state.isEditing === true ? (
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="reports-inputFields">
              <InputField
                id="reportName"
                label="Report Name"
                size="small"
                type="text"
                name="reportName"
                placeholder="Enter Report Name"
                className="reports-inputFields"
                value={this.state.reportName}
                onChange={this.changeInfoHandler}
                disabled={false}
              />
              <div style={{ marginTop: "10px" }}>
                <InputField
                  id="reportDescription"
                  label="Report Description"
                  size="small"
                  type="text"
                  name="reportDescription"
                  placeholder="Enter Report Description"
                  className="reports-inputFields"
                  value={this.state.reportDescription}
                  onChange={this.changeInfoHandler}
                  disabled={false}
                  multiline={true}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "only_me"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                        }
                  }
                >
                  <i
                    className="fa fa-lock"
                    aria-hidden="true"
                    style={{ transform: "translateY(3px)" }}
                  ></i>{" "}
                  &nbsp;
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    value={this.state.access}
                    id="checkboxView"
                    checked={this.state.access === "everyone"}
                    onChange={this.accessChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span
                  className="general-heading"
                  style={
                    this.state.access === "everyone"
                      ? {
                          color: "#3AAB7B",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                      : {
                          color: "#A7ABAA",
                          fontSize: "20px",
                          transform: "translateY(2px)",
                        }
                  }
                >
                  {" "}
                  &nbsp;<i className="fa fa-users" aria-hidden="true"></i>
                </span>
              </div>
              <button
                className="button-md"
                type="button"
                onClick={this.submitHandler}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Saving ..." : "Save Report"}
              </button>
              <div>
                {this.state.isEditing === true && (
                  <ReportExitDropdown
                    handleDeleteOpenModal={this.handleDeleteOpenModal}
                    handleExitOpenModal={this.handleExitOpenModal}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ borderBottom: "1px solid #E3E3E3", marginBottom: "10px" }}
          >
            <div
              className="customerInfo-icon-report"
              style={{ transform: "translateY(-16px)" }}
            >
              <img
                src={ReportIcon}
                alt=""
                width="17px"
                style={{ transform: "translateY(9px)" }}
              />
            </div>
            <div className="customerInfo-info">
              <h2 className="c-type">Report</h2>
              <h1 className="c-name">
                {this.state.reportName}{" "}
                <span style={{ color: "#4A9876", marginLeft: "15px" }}>
                  {this.state.total} records
                </span>
              </h1>
            </div>
            {this.state.isEditing === false && (
              <div
                style={{
                  marginTop: "2vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="button"
                  className="button-md-black-outline reports-edit-button"
                  onClick={() => this.setState({ isEditing: true })}
                >
                  Edit Report
                </button>
              </div>
            )}
          </div>
        )}
        <DeleteReportModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleDeleteOpenModal}
          handleCloseModal={this.handleDeleteCloseModal}
          deleteReport={this.deleteReport}
          reportId={this.props.reportId}
        />
        <ExitReportModal
          showOpportunityModal={this.state.showExitModal}
          setShowOpportunityModal={this.handleExitOpenModal}
          handleCloseModal={this.handleExitCloseModal}
        />
        {this.openStageModal(
          this.state.currentStageId,
          this.state.currentDealData,
          this.state.currentStageType
        )}
        {this.state.isEditing === true && (
          <form>
            <div className="reports-filter">
              <div>
                <h4 style={{ marginTop: "0px" }}>Filters</h4>
              </div>
              {this.state.filter_rules.map((rule, index) => (
                <div style={{ display: "flex" }} key={rule.id}>
                  <div>
                    <label>Filter</label>
                    <select
                      name="attribute"
                      value={rule.attribute}
                      onChange={(e) => this.changeHandler(index, e)}
                      disabled={rule.attribute === "batch"}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <optgroup label="Default Fields">
                        <option value="owner">Owner</option>
                        <option value="opportunity_name">Deal Name</option>
                        <option value="account_name">Account Name</option>
                        <option value="created_date">Created Date</option>
                        <option value="email">Email</option>
                        <option value="phone">Phone</option>
                        <option value="city">City</option>
                        <option value="state">State</option>
                        <option value="zip">Zip</option>
                        <option value="funnel">Funnel</option>
                        <option value="stage">Stage</option>
                        <option value="last_edit_date">Last Edit Date</option>
                        <option value="age">Age</option>
                        <option value="stage_age">Stage Age</option>
                        <option value="country">Country</option>
                        <option value="close_reason">Close Reason</option>
                        <option value="close_date">Close Date</option>
                        <option value="source">Source</option>
                        <option value="batch" disabled>
                          Batch
                        </option>
                        <option value="description">Description</option>
                        <option value="revenue">Revenue</option>
                        <option value="last_activity_date">
                          Last Activity Date
                        </option>
                      </optgroup>
                      {this.state.customFields.map((group) => {
                        const { label, fields, meta } = group;
                        return (
                          (meta === null || meta.visible_in_form === true) && (
                            <optgroup label={label}>
                              {fields?.map((field) => {
                                const { id, label } = field;
                                return (
                                  <option key={id} value={id}>
                                    {label}
                                  </option>
                                );
                              })}
                            </optgroup>
                          )
                        );
                      })}
                    </select>
                    {this.state.errorMessage === true &&
                      rule.attribute === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Filters is required field
                        </div>
                      )}
                  </div>
                  {rule.attribute === "stage" && (
                    <div>
                      <label>Select Funnel</label>
                      <select
                        // value={this.state.funnelId}
                        onChange={this.funnelChangeHandler}
                      >
                        {this.state.allFunnels?.map((funnel) => (
                          <option key={funnel.id} value={funnel.id}>
                            {funnel.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {rule.attribute === "last_edit_date" ||
                  rule.attribute === "close_date" ||
                  rule.type === "date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_14_days">Last 14 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "batch" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option value="is" disabled>
                          is
                        </option>
                      </select>
                    </div>
                  ) : rule.attribute === "last_activity_date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_14_days">Last 14 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="greater_than_14_days">
                          Greater than last 14 days
                        </option>
                        <option value="greater_than_30_days">
                          Greater than last 30 days
                        </option>
                        <option value="greater_than_60_days">
                          Greater than last 60 days
                        </option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.type === "checkbox" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is_not_empty">is true</option>
                        <option value="is_empty">is false</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.type === "decimal" || rule.type === "number" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="greater_than_equal">
                          equal or greater than
                        </option>
                        <option value="less_than_equal">
                          equal or less than
                        </option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "created_date" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="last_14_days">Last 14 days</option>
                        <option value="last_30_days">Last 30 days</option>
                        <option value="this_month">This Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="next_quarter">Next Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom_period">Custom Period</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "stage" ||
                    rule.attribute === "funnel" ||
                    rule.attribute === "source" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "revenue" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "owner" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="only_me">My Records</option>
                        <option value="my_team">My Team's Records</option>
                        <option value="everyone">Everyone’s Records</option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : rule.attribute === "age" ||
                    rule.attribute === "stage_age" ? (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        onChange={(e) => this.changeHandler(index, e)}
                        value={rule.operator}
                      >
                        <option hidden>-Select-</option>
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option value="is">is</option>
                        <option value="is_not">is not</option>
                        <option value="greater_than">greater than</option>
                        <option value="less_than">less than</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  ) : (
                    <div>
                      <label>Operator</label>
                      <select
                        name="operator"
                        value={rule.operator}
                        onChange={(e) => this.changeHandler(index, e)}
                      >
                        <option hidden>-Select-</option>
                        <option selected disabled>
                          -Select-
                        </option>
                        <option value="is_in">is</option>
                        <option value="is_not_in">is not</option>
                        <option value="contains">contains</option>
                        <option value="not_contains">does not contain</option>
                        <option value="is_empty">is empty</option>
                        <option value="is_not_empty">is not empty</option>
                      </select>
                      {this.state.errorMessage === true &&
                        rule.operator === "" && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Operator is required field
                          </div>
                        )}
                    </div>
                  )}
                  {rule.operator === "custom_period" ? (
                    <div style={{ display: "flex" }}>
                      <div>
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 0, e)}
                          value={rule?.value === null ? "" : rule?.value?.[0]}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <input
                          type="date"
                          name="value"
                          onChange={(e) => this.valueChangeHandler(index, 1, e)}
                          value={rule?.value === null ? "" : rule?.value?.[1]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label>&nbsp;</label>
                      {rule.operator === "is_empty" ||
                      rule.operator === "is_not_empty" ||
                      rule.operator === "today" ||
                      rule.operator === "yesterday" ||
                      rule.operator === "this_week" ||
                      rule.operator === "last_7_days" ||
                      rule.operator === "last_14_days" ||
                      rule.operator === "last_30_days" ||
                      rule.operator === "greater_than_14_days" ||
                      rule.operator === "greater_than_30_days" ||
                      rule.operator === "greater_than_60_days" ||
                      rule.operator === "this_month" ||
                      rule.operator === "this_quarter" ||
                      rule.operator === "next_quarter" ||
                      rule.operator === "last_quarter" ||
                      rule.operator === "this_year" ||
                      rule.operator === "only_me" ||
                      rule.operator === "my_team" ||
                      rule.operator === "everyone" ||
                      rule.operator === "last_year" ? (
                        <input
                          type="text"
                          name="value"
                          value={(rule.value = null)}
                          disabled={true}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.attribute === "age" ||
                        rule.attribute === "stage_age" ? (
                        <input
                          type="number"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.attribute === "created_date" ||
                        rule.attribute === "last_edit_date" ||
                        rule.attribute === "last_activity_date" ||
                        rule.attribute === "close_date" ||
                        rule.type === "date" ? (
                        <input
                          type="date"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                        />
                      ) : rule.operator === "is_in" ||
                        rule.operator === "is_not_in" ? (
                        rule.type === "dropdown" ||
                        rule.type === "multi_select_dropdown" ||
                        rule.type === "radio" ? (
                          <Select
                            isMulti
                            value={rule.value}
                            menuPlacement="auto"
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={rule?.choices?.map((choice) => {
                              const { value } = choice;
                              return { value, label: value };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "stage" ? (
                          <Select
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.state?.stages.map((stage) => {
                              const { id, name } = stage;
                              return { value: id, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "funnel" ? (
                          <Select
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.state.allFunnels?.map((funnel) => {
                              const { id, name } = funnel;
                              return { value: id, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "source" ? (
                          <Select
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.props.allAccountSources?.map(
                              (status) => {
                                return {
                                  label: status.name,
                                  value: status.name,
                                };
                              }
                            )}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "state" ? (
                          <CreatableSelect
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={usStatesList?.map((status) => {
                              return {
                                label: status,
                                value: status,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : rule.attribute === "owner" ? (
                          <Select
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={this.props.allUsers?.map((user) => {
                              const { name } = user;
                              return { value: name, label: name };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        ) : (
                          <CreatableSelect
                            isMulti
                            menuPlacement="auto"
                            value={rule.value}
                            onChange={(selected) =>
                              this.isInChangeHandler(index, selected)
                            }
                            options={rule.choices?.map((choice) => {
                              return {
                                label: choice.value,
                                value: choice.value,
                              };
                            })}
                            styles={colourStyles}
                            placeholder="Add Value"
                          />
                        )
                      ) : (
                        <input
                          type="text"
                          name="value"
                          value={rule.value}
                          onChange={(e) => this.changeHandler(index, e)}
                          disabled={rule.attribute === "batch"}
                        />
                      )}
                      {this.state.errorMessage === true &&
                        ((rule.operator === "is_in" &&
                          (rule.value === null ||
                            rule.value === "" ||
                            rule.value.length === 0)) ||
                          (rule.operator === "is_not_in" &&
                            (rule.value === null ||
                              rule.value === "" ||
                              rule.value.length === 0)) ||
                          (rule.operator === "contains" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "not_contains" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "greater_than" &&
                            (rule.value === null || rule.value === "")) ||
                          (rule.operator === "less_than" &&
                            (rule.value === null || rule.value === ""))) && (
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            Value cannot be empty
                          </div>
                        )}
                    </div>
                  )}
                  {rule.attribute !== "batch" && (
                    <button
                      type="button"
                      onClick={() => this.deleteFilter(index)}
                      style={{
                        background: "transparent",
                        border: "none",
                        borderRadius: "28px",
                        outline: "none",
                        cursor: "pointer",
                        transform: "translateY(12px)",
                      }}
                    >
                      <img src={DeleteIcon} alt="Delete" width="16px" />
                    </button>
                  )}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  type="button"
                  onClick={this.addFilter}
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                >
                  Add Filter
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
                <button
                  type="button"
                  className="button-md-secondary"
                  style={{
                    border: "1px solid #EAEEF3",
                    background: "white",
                  }}
                  onClick={() => this.getReport()}
                >
                  {this.state.isApplying ? "Applying ..." : "Apply Filter"}
                </button>
              </div>
            </div>
          </form>
        )}
        <div>
          {this.state.isEditing === true && (
            <div style={{ display: "flex", marginBottom: "40px" }}>
              {this.state.isEditing === true &&
                this.state.analyticsMode === "table" && (
                  <div
                    className="dropdownTask"
                    ref={this.wrapperRef}
                    style={{
                      background: "transparent",
                      width: "90px",
                      height: "39px",
                      margin: "20px",
                      marginBottom: "0",
                    }}
                  >
                    <div
                      className="button"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        width: "90px",
                        right: "0",
                        top: "0",
                      }}
                      onClick={this.showDropdownMenu}
                    >
                      <div
                        style={{
                          color: "#212123",
                          borderRadius: "4px",
                          border: "1px solid #E7ECF2",
                          padding: "10px",
                          width: "90px",
                        }}
                        className="reports-column-button"
                      >
                        Columns &nbsp;
                        <SlidersHorizontal size={14} />
                      </div>
                    </div>

                    {this.state.displayMenu ? (
                      <ul
                        style={{
                          width: "260px",
                          background: "white",
                          paddingLeft: "15px",
                          height: "350px",
                          overflowY: "auto",
                          left: "0px",
                          top: "52px",
                        }}
                      >
                        <h4
                          style={{
                            color: "#586874",
                            fontSize: "14px",
                            marginLeft: "0px",
                            textAlign: "center",
                            marginBottom: "10px",
                            borderBottom: "1px solid #586874",
                            paddingBottom: "10px",
                            width: "220px",
                          }}
                        >
                          Displayed Columns
                        </h4>
                        {columnsArray.map((column, index) => {
                          let { name, selected } = column;
                          return (
                            <li style={{ width: "200px", fontSize: "12px" }}>
                              <input
                                type="checkbox"
                                checked={selected}
                                id={column.key + index}
                                onChange={() =>
                                  this.setState((prevState) => ({
                                    columnsArray: prevState.columnsArray.map(
                                      (clm) => {
                                        if (clm.key === column.key) {
                                          clm.selected = !clm.selected;
                                        }
                                        return clm;
                                      }
                                    ),
                                  }))
                                }
                                style={{
                                  height: "15px",
                                  display: "inline-block",
                                  width: "auto",
                                  marginRight: "5px",
                                }}
                              />
                              <label
                                htmlFor={column.key + index}
                                style={{
                                  display: "inline-block",
                                  cursor: "pointer",
                                }}
                              >
                                {name}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="reports-buttons-container">
          {this.state.isEditing === false && (
            <button
              type="button"
              onClick={this.handleOwnerOpenModal}
              disabled={
                this.state.total === 0 ||
                (this.state.allReportTransfer === false &&
                  this.state.transferAccount.length === 0)
              }
              className="button-md-black-outline"
              style={{
                marginRight: "10px",
              }}
            >
              Transfer Deals
            </button>
          )}
          {this.props.authUser.permissions.includes(
            "crm:bulk:records:delete"
          ) &&
            this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.handleBulkDeleteOpenModal}
                disabled={
                  this.state.total === 0 ||
                  (this.state.allReportTransfer === false &&
                    this.state.transferAccount.length === 0)
                }
                className="button-md-black-outline"
                style={{
                  marginRight: "10px",
                }}
              >
                Bulk Delete
              </button>
            )}
          {this.state.isEditing === false && (
            <button
              type="button"
              onClick={this.emailDecision}
              className="button-md-black-outline"
              disabled={this.state.total === 0}
            >
              <img src={SendEmailIcon} alt="" style={{ width: "16px" }} />
              &nbsp;&nbsp; Bulk Email
            </button>
          )}
          {this.props.authUser.permissions.includes("crm:exporter") &&
            this.state.isEditing === false && (
              <button
                type="button"
                onClick={this.downloadReport}
                className="button-md"
                disabled={this.state.exportLoader}
                style={{ marginLeft: "10px" }}
              >
                <img src={ExportButtonIcon} alt="" />
                &nbsp;&nbsp; {this.state.exportLoader ? "Exporting" : "Export"}
              </button>
            )}
        </div>
        <TransferOwnershipModal
          showOpportunityModal={this.state.showOwnerModal}
          setShowOpportunityModal={this.handleOwnerOpenModal}
          handleCloseModal={this.handleOwnerCloseModal}
          transferOwnership={this.transferOwnership}
          ownerErrorMessage={this.state.ownerErrorMessage}
        />
        <ReusableModal
          showModal={this.state.showBulkDeleteModal}
          handleCloseModal={this.handleBulkDeleteCloseModal}
          submitHandler={this.bulkDelete}
          modalHeading={"Bulk Delete"}
          modalPara={`Are you sure you want to delete these ${
            this.state.allReportTransfer === true
              ? this.state.total
              : this.state.transferAccount.length
          } selected records?`}
          modalHeight={`30`}
          modalTop={`25`}
          loading={this.state.bulkDeleteLoader}
        />
        <SendEmailReportModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleEmailOpenModal}
          handleCloseModal={this.handleEmailCloseModal}
          reportName={this.state.reportName}
          totalRecords={this.state.total}
          reportId={this.props.reportId}
          reportType="opportunities"
          senderEmail={this.state.transferAccountEmail}
          source="opportunity"
          excludeEmail={this.state.excludeEmailArray}
          fromEmail={this.props.authUser.email}
        />
        <BillingCreditModal
          showOpportunityModal={this.state.showCreditsModal}
          setShowOpportunityModal={this.handleCreditsOpenModal}
          handleCloseModal={this.handleCreditsCloseModal}
          handleEmailOpenModal={this.handleEmailOpenModal}
          checkUserProfile={this.checkUserProfile}
        />
        <BillingRedirectModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleRedirectOpenModal}
          handleCloseModal={this.handleRedirectCloseModal}
        />
        <PostMarkAppModal
          showOpportunityModal={this.state.showPostMarkModal}
          setShowOpportunityModal={this.handlePostMarkOpenModal}
          handleCloseModal={this.handlePostMarkCloseModal}
          handleVerificationOpenModal={this.handleVerificationOpenModal}
          checkUserProfile={this.checkUserProfile}
        />
        <PostMarkAppVerificationModal
          showOpportunityModal={this.state.showVerificationModal}
          setShowOpportunityModal={this.handleVerificationOpenModal}
          handleCloseModal={this.handleVerificationCloseModal}
          checkUserProfile={this.checkUserProfile}
          signatureVerified={this.state.signatureVerified}
          handleEmailOpenModal={this.handleEmailOpenModal}
          checkVerification={this.checkVerification}
        />
        <div
          className="reports-tab-container"
          style={this.state.isEditing === true ? { marginLeft: "20px" } : {}}
        >
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "table",
              })
            }
            data-tip="Tabular view"
          >
            <i className="fa fa-table" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "column",
              })
            }
            data-tip="Column chart"
          >
            <i className="fa fa-bar-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "line",
              })
            }
            data-tip="Line chart"
          >
            <i className="fa fa-line-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "bar",
              })
            }
            data-tip="Bar chart"
          >
            <i
              className="fa fa-bar-chart-o"
              aria-hidden="true"
              style={{ transform: "rotate(90deg)" }}
            ></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "pie",
              })
            }
            data-tip="Pie chart"
          >
            <i className="fa fa-pie-chart" aria-hidden="true"></i>
          </button>
          <button
            className="reports-tab"
            type="button"
            onClick={() =>
              this.setState({
                analyticsMode: "scorecard",
              })
            }
            data-tip="Scorecard"
          >
            <img
              src={ScoreCardIcon}
              alt="ScoreCard"
              width="20px"
              height="15px"
            />
          </button>
        </div>
        {this.state.analyticsMode === "table" && (
          <div
            style={{
              width: this.state.isEditing === true ? "98vw" : "93vw",
              overflowX: "auto",
              height: "auto",
              minHeight: "55vh",
            }}
          >
            {this.state.isInitializing === true ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : this.state.isInitializing === false &&
              this.state.reportsTable?.length === 0 ? (
              <div
                style={{
                  margin: "30px auto",
                  marginLeft: "35vw",
                  fontWeight: "500",
                }}
              >
                No records found.
              </div>
            ) : this.state.isEditing === true ? (
              <table
                className="accountList__table"
                style={{
                  width: "98vw",
                  height: "auto",
                  overflowX: "auto",
                  marginLeft: "20px",
                  marginTop: "0",
                }}
              >
                <SortableContainer
                  useDragHandle
                  axis={"x"}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    this.setState(({ columnsArray }) => ({
                      columnsArray: arrayMove(columnsArray, oldIndex, newIndex),
                    }));
                  }}
                >
                  {columnsArray.map(
                    (column, index) =>
                      column.selected === true && (
                        <SortableItem
                          key={column.key}
                          column={column}
                          index={index}
                          sortButtonClickHandler={this.sortButtonClickHandler}
                        />
                      )
                  )}
                </SortableContainer>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const { id } = report;
                    return (
                      <tr key={id}>
                        {columnsArray.map(
                          (column) =>
                            column.selected === true &&
                            (column.key === "description" ? (
                              <td>
                                {report.description?.length > 140
                                  ? report.description?.substring(0, 140)
                                  : report.description}
                              </td>
                            ) : column.key === "revenue" ? (
                              <td>
                                {report.revenue && currencySymbol.symbol}
                                {report.revenue &&
                                  getCurrencyValue(
                                    report.revenue
                                  )?.toLocaleString("en-US")}
                              </td>
                            ) : moment(
                                report[column.key] ||
                                  report.custom_fields[column.key],
                                "YYYY-MM-DDTHH:mm:ss.SSSZ",
                                true
                              ).isValid() ? (
                              <td>
                                {(report[column.key] ||
                                  report.custom_fields[column.key]) &&
                                  moment(
                                    report[column.key] ||
                                      report.custom_fields[column.key]
                                  ).format(dateFormat)}
                              </td>
                            ) : (
                              <td>
                                {report.custom_fields[column.key] !==
                                  undefined &&
                                report.custom_fields[column.key] !== null &&
                                report.custom_fields[column.key]
                                  .toString()
                                  .startsWith(";")
                                  ? report.custom_fields[column.key]
                                      .replace(/;/g, ", ")
                                      .substr(1)
                                      .slice(0, -2)
                                  : report[column.key] ||
                                    report.custom_fields[column.key]}
                              </td>
                            ))
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.isApplying ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : (
              <table
                className="accountList__tableReport"
                style={{ marginTop: "0px" }}
              >
                <thead>
                  <th style={{ width: "8px" }}>
                    <div className="selectReports">
                      <input
                        type="checkbox"
                        style={{ width: "12px", height: "12px" }}
                        checked={this.state.allReportTransfer ? true : false}
                        onClick={this.handleClick}
                      />
                      <div style={{ position: "relative", left: "-10px" }}>
                        <ArrowDropDownIcon
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                          onClick={this.handleClick}
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ marginTop: "10px" }}
                        >
                          <div className="selectReports__popoverCustom">
                            <div
                              className="numRecInput"
                              style={{ display: "flex" }}
                            >
                              <InputField
                                type="number"
                                placeholder="Select number of records"
                                max={100}
                                value={this.state.inputSelectedReports || ""}
                                error={this.state.inputSelectedReports > 100}
                                helperText="Records limit is 100"
                                onChange={(page) =>
                                  this.setState({
                                    inputSelectedReports: Number(
                                      page.target.value
                                    ),
                                    selectAllRecords: false,
                                    selectPageRecords: false,
                                  })
                                }
                              />
                            </div>

                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <input
                                type="radio"
                                id="selectAllRadio"
                                style={{ width: "12px", height: "12px" }}
                                onChange={() =>
                                  this.setState({
                                    inputSelectedReports: "",
                                    selectPageRecords: false,
                                  })
                                }
                                checked={this.state.selectAllRecords}
                                onClick={() =>
                                  this.setState({
                                    selectAllRecords:
                                      !this.state.selectAllRecords,
                                  })
                                }
                              />
                              <label
                                htmlFor="selectAllRadio"
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <small>
                                  Select all ({this.state.total} records)
                                </small>
                              </label>
                            </div>

                            <div
                              style={{ display: "flex", marginBottom: "10px" }}
                            >
                              <input
                                type="radio"
                                id="selectCurrentRadio"
                                style={{ width: "12px", height: "12px" }}
                                checked={
                                  this.state.selectPageRecords &&
                                  this.state.currentPage ===
                                    this.state.activePage
                                }
                                onChange={() =>
                                  this.setState({
                                    inputSelectedReports: "",
                                    selectAllRecords: false,
                                    currentPage: this.state.activePage,
                                  })
                                }
                                onClick={() =>
                                  this.setState({
                                    selectPageRecords:
                                      !this.state.selectPageRecords,
                                  })
                                }
                              />
                              <label
                                htmlFor="selectCurrentRadio"
                                style={{ marginLeft: "5px" }}
                              >
                                <small>
                                  Select current page (
                                  {this.state?.reportsTable?.length || 0}{" "}
                                  records)
                                </small>
                              </label>
                            </div>

                            <div className="applyBtn">
                              <button
                                type="button"
                                className="button-sm-secondary"
                                onClick={this.handleClose}
                                style={{ marginRight: "5px", fontSize: "12px" }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="button-sm"
                                onClick={this.applySelectedReports}
                                style={{ marginLeft: "5px", fontSize: "12px" }}
                                disabled={this.state.inputSelectedReports > 100}
                              >
                                {this.state.isSelectedReportLoading
                                  ? "Applying..."
                                  : "Apply"}
                              </button>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </th>
                  {columnsArray
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map(
                      (column) =>
                        this.state?.columnsData
                          .split(",")
                          .includes(
                            column.id === undefined
                              ? column.key.toString()
                              : column.id.toString()
                          ) && (
                          <th
                            key={column.key}
                            style={
                              column.key === "revenue"
                                ? { background: "#DBF3E8" }
                                : {}
                            }
                          >
                            <Row>
                              <Col lg={9} xl={9}>
                                {column.name}
                              </Col>
                              <Col lg={3} xl={3}>
                                <button
                                  type="button"
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    outline: "none",
                                  }}
                                  onClick={() =>
                                    this.setState(
                                      {
                                        sortKey: column.custom
                                          ? column.id
                                          : column.key,
                                        sortDirection:
                                          !this.state.sortDirection,
                                      },
                                      this.storeSorting
                                    )
                                  }
                                >
                                  <i className="fa fa-long-arrow-up"></i>
                                  <i className="fa fa-long-arrow-down"></i>
                                </button>
                              </Col>
                            </Row>
                          </th>
                        )
                    )}
                </thead>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const { id, name } = report;
                    let newTabData = {
                      type: "opportunity",
                      id: id,
                      name: name,
                    };
                    return (
                      <tr key={id}>
                        <td style={{ width: "8px" }}>
                          {this.state.allReportTransfer ? (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              value={id}
                              checked={
                                !this.state.excludeArray.includes(id.toString())
                              }
                              onClick={(e) =>
                                this.excludeAccountChangeHandler(
                                  report.email,
                                  e
                                )
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              checked={this.state.transferAccount.includes(
                                id.toString()
                              )}
                              value={id}
                              onClick={(e) =>
                                this.transferAccountChangeHandler(report, e)
                              }
                            />
                          )}
                        </td>
                        {columnsArray.map(
                          (column) =>
                            this.state.columnsData
                              .split(",")
                              .includes(
                                column.id === undefined
                                  ? column.key.toString()
                                  : column.id.toString()
                              ) &&
                            this.renderTableData(
                              report,
                              column,
                              column.key === "stage_name"
                                ? "stage_id"
                                : column.key === "phone"
                                ? "formatted_phone"
                                : column.key,
                              report[
                                column.key === "stage_name"
                                  ? "stage_id"
                                  : column.key === "phone"
                                  ? "formatted_phone"
                                  : column.key
                              ] || report.custom_fields[column.key],
                              dateFormat,
                              newTabData,
                              currencySymbol.symbol
                            )
                        )}
                      </tr>
                    );
                  })}
                  <tr style={{ background: "#dbf3e8" }}>
                    <td style={{ width: "8px", padding: "5px 10px" }}></td>
                    {columnsArray.map(
                      (column) =>
                        this.state.columnsData
                          .split(",")
                          .includes(
                            column.id === undefined
                              ? column.key.toString()
                              : column.id.toString()
                          ) && (
                          <td style={{ padding: "5px 10px" }}>
                            {column.key === "revenue" ? (
                              <>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    display: "block",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  Grand Total
                                </span>
                                <span>
                                  {currencySymbol.symbol}
                                  {this.state.revenueSum?.toLocaleString(
                                    "en-US"
                                  )}
                                </span>
                              </>
                            ) : column.key in this.state.revenueFieldsSum ? (
                              <>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    display: "block",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  Grand Total
                                </span>
                                <span>
                                  {currencySymbol.symbol}
                                  {this.state.revenueFieldsSum[
                                    column.key
                                  ]?.toLocaleString("en-US")}
                                </span>
                              </>
                            ) : null}
                          </td>
                        )
                    )}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
        {this.state.analyticsMode === "table" && (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={30}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              this.setState({ activePage: page });
              this.getReport(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        )}
        {this.state.analyticsMode === "pie" && (
          <DealsPie
            filter_rules={this.state.filter_rules}
            currency={this.props.companyOnboarding?.currency}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "line" && (
          <DealsLine
            filter_rules={this.state.filter_rules}
            currency={this.props.companyOnboarding?.currency}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "column" && (
          <DealsColumn
            filter_rules={this.state.filter_rules}
            chartType="column"
            currency={this.props.companyOnboarding?.currency}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "bar" && (
          <DealsColumn
            filter_rules={this.state.filter_rules}
            chartType="bar"
            currency={this.props.companyOnboarding?.currency}
            fromReport={true}
          />
        )}
        {this.state.analyticsMode === "scorecard" && (
          <DealsScoreCard filter_rules={this.state.filter_rules} />
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ReportsDealTable);

import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLoader from "../../Loaders/DashboardLoader";
import ShipmentsByAccountChart from "./ShipmentsByAccountChart";

const ShipmentsByAccount = (props) => {
  const [filter, setFilter] = useState("last_30_days");
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState("");

  const allFilters = [
    { value: "all_time", label: "All time" },
    { value: "today", label: "Today" },
    { value: "this_week", label: "Current Week" },
    { value: "last_week", label: "Previous Week" },
    { value: "this_month", label: "Current Month" },
    { value: "last_month", label: "Previous Month" },
    { value: "this_quarter", label: "Current Quarter" },
    { value: "last_quarter", label: "Previous Quarter" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];

  const getMetrics = () => {
    setLoader(true);
    axios({
      method: "GET",
      url: `/charts/total-shipments?duration=${filter}&user_ids=${props.userId}`,
    })
      .then((res) => {
        setUsers(res.data.accounts);
        setLoader(false);
        setRefresh(filter);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getMetrics();
  }, [filter]);
  return (
    <div className="card-shipment-sales-rep">
      <div className="funnel-heading">
        <h4 className="funnel-heading-sub-1">Shipments by Account</h4>
        <h6 className="funnel-heading-sub-c">
          <select
            className="funnel-select"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {allFilters.map((fil) => (
              <option key={fil.value} value={fil.value}>
                {fil.label}
              </option>
            ))}
          </select>
        </h6>
      </div>
      {loader ? (
        <DashboardLoader />
      ) : (
        <ShipmentsByAccountChart
          data={users}
          reportWidth={props.reportWidth}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default ShipmentsByAccount;

import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartColors } from "../../../constants/chartColors";

class ShipmentsBySalesRepChart extends Component {
  state = {
    downloadLoader: false,
    chartOptions: {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: null,
      xAxis: {
        categories: ["Africa", "America", "Asia", "Europe", "Oceania"],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        labels: {
          overflow: "justify",
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [],
    },
    hoverData: null,
  };

  componentDidMount() {
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Shipments By Sales Rep",
            data: this.props.data?.map((d, index) => ({
              y: d.count,
              color: chartColors[index % chartColors.length],
            })),
          },
        ],
        yAxis: {
          min: 0,
          title: {
            text: `Total Shipments`,
          },
          labels: {
            overflow: "justify",
          },
        },
        xAxis: {
          categories: this.props.data?.map((d) => d.user_name),
        },
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.chart.setSize(this.props.reportWidth);
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: [
            {
              name: "Shipments By Sales Rep",
              data: this.props.data?.map((d, index) => ({
                y: d.count,
                color: chartColors[index % chartColors.length],
              })),
            },
          ],
          yAxis: {
            min: 0,
            title: {
              text: `Total Shipments`,
            },
            labels: {
              overflow: "justify",
            },
          },
          xAxis: {
            categories: this.props.data?.map((d) => d.user_name),
          },
        },
      });
    }
  }

  setChart(chart) {
    this.chart = chart;
  }

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          callback={(chart) => this.setChart(chart)}
        />
      </div>
    );
  }
}

export default ShipmentsBySalesRepChart;

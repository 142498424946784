import React, { Component } from "react";
import ReactModal from "react-modal";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import StatusTransferModal from "./StatusTransferModal";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import LockStages from "../../images/LockStages.svg";
// Redux stuff
import { connect } from "react-redux";
import {
  fetchAllAccountStatuses,
  fetchAllCarrierStatuses,
  fetchAllContactStatuses,
  fetchAllEquipmentTypes,
  fetchAllLaneTypes,
  fetchAllModes,
  fetchAllSpecialRequirements,
} from "../../redux";
import { Plus } from "lucide-react";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grab", padding: "13px 4px 0 4px" }}>
    <img src={DragHandlerIcon} alt="::" />
  </span>
));

const SortableItem = sortableElement(
  ({
    choice,
    editChoiceChangeHandler,
    sourceArray,
    deleteChoiceChangeHandler,
    handleOpenTransferModal,
    setSelectedStatus,
    errorMessage,
  }) => {
    return (
      <div>
        <div
          style={
            choice?.name?.toLowerCase() === "long-term"
              ? {
                  borderBottom: "1px solid #eee",
                  width: "100%",
                  margin: "5px 0",
                }
              : {}
          }
        ></div>
        <div style={{ display: "flex" }}>
          {choice?.name?.toLowerCase() === "disqualified" ||
          choice?.name?.toLowerCase() === "lead" ||
          choice?.name?.toLowerCase() === "long-term" ||
          choice?.name?.toLowerCase() === "customer" ? (
            <span style={{ cursor: "not-allowed", padding: "13px 4px 0 4px" }}>
              <img src={LockStages} alt="" />
            </span>
          ) : (
            <DragHandle />
          )}
          <input
            value={choice.name}
            onChange={(e) => editChoiceChangeHandler(choice.id, e)}
            disabled={
              choice.name?.toLowerCase() === "long-term" ||
              choice.name?.toLowerCase() === "disqualified" ||
              choice.name?.toLowerCase() === "lead" ||
              choice.name.toLowerCase() === "customer"
            }
          />
          {choice.name?.toLowerCase() !== "long-term" &&
            choice.name?.toLowerCase() !== "disqualified" &&
            choice.name?.toLowerCase() !== "lead" &&
            choice.name.toLowerCase() !== "customer" &&
            sourceArray?.filter(
              (choice) =>
                choice._destroy !== true &&
                choice.name?.toLowerCase() !== "long-term" &&
                choice.name?.toLowerCase() !== "disqualified"
            ).length > 3 && (
              <button
                type="button"
                onClick={(e) => {
                  if (choice._new) {
                    deleteChoiceChangeHandler(choice.id, "");
                  } else {
                    handleOpenTransferModal();
                    setSelectedStatus(choice);
                  }
                }}
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <img src={DeleteIcon} alt="Delete" width="16px" />
              </button>
            )}
          {errorMessage === true && choice.name === "" && (
            <div
              style={{
                display: "block",
                fontSize: "12px",
                color: "red",
              }}
            >
              Please enter status
            </div>
          )}
        </div>
        <div
          style={
            choice?.name?.toLowerCase() === "lead"
              ? {
                  borderBottom: "1px solid #eee",
                  width: "100%",
                  margin: "5px 0",
                }
              : {}
          }
        ></div>
      </div>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class StatusChangeModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    sourceArray: [],
    isLoading: false,
    errorMessage: false,
    showTransferModal: false,
    selectedStatus: {},
  };
  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleOpenTransferModal = () => {
    this.setState({
      showTransferModal: true,
    });
  };
  handleCloseTransferModal = () => {
    this.setState({
      showTransferModal: false,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getSources();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.getSources();
    }
  }
  getSources = () => {
    let sourceArray = this.props.allAccountStatuses;
    if (this.props.statusType === "contact") {
      sourceArray = this.props.allContactStatuses;
    } else if (this.props.statusType === "carrier") {
      sourceArray = this.props.allCarrierStatuses;
    } else if (this.props.statusType === "equipment") {
      sourceArray = this.props.allEquipmentTypes;
    } else if (this.props.statusType === "laneTypes") {
      sourceArray = this.props.allEquipmentTypes;
    } else if (this.props.statusType === "laneTypes") {
      sourceArray = this.props.allLaneTypes;
    } else if (this.props.statusType === "modes") {
      sourceArray = this.props.allModes;
    } else if (this.props.statusType === "requirement") {
      sourceArray = this.props.allSpecialRequirements;
    } else {
      sourceArray = this.props.allAccountStatuses;
    }
    this.setState({ sourceArray });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  editChoiceChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sourceArray: prevState.sourceArray.map((choice) => {
        if (choice.id === choiceId) {
          choice._rename = true;
          choice.name = value;
          choice._rename = true;
        }
        return choice;
      }),
    }));
  };
  deleteChoiceChangeHandler = (choiceId, newStatusId) => {
    const newStatus = this.state.sourceArray?.find(
      (status) => status.id.toString() === newStatusId.toString()
    );
    this.setState((prevState) => ({
      sourceArray: prevState.sourceArray
        .map((choice) => {
          if (choice.id === choiceId) {
            choice._destroy = true;
            choice.replace_with = choice._new ? "" : newStatus.name;
            choice.replace_with_id = choice._new ? "" : parseInt(newStatusId);
            if (choice.name === "") {
              choice.name = Math.random().toString();
            }
          }
          return choice;
        })
        .filter((choice) => !(choice._destroy && choice._new)),
    }));
  };
  addEditFieldChoice = () => {
    this.setState((prevState) => {
      const sourceArray = [
        // part of the array before the specified index
        ...prevState.sourceArray.slice(0, prevState.sourceArray.length - 3),
        // inserted item
        {
          name: "",
          id: uuidv4(),
          _destroy: false,
          _new: true,
        },
        // part of the array after the specified index
        ...prevState.sourceArray.slice(prevState.sourceArray.length - 3),
      ];
      return {
        sourceArray,
      };
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const sourceNameArray = this.state.sourceArray.map((source) => source.name);
    const checkError = this.state.sourceArray.every((source) => {
      let { name } = source;
      let resultToReturn = false;
      // call some function with callback function as argument
      resultToReturn = sourceNameArray.some((element, index) => {
        return sourceNameArray.indexOf(element) !== index;
      });
      if (name === "" || name === null) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (resultToReturn) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return source;
    });
    this.setState({ isLoading: true });
    let url = `/accounts/statuses`;
    let statusKey = "account_statuses";
    if (this.props.statusType === "contact") {
      url = `/contacts/statuses`;
      statusKey = "contact_statuses";
    } else if (this.props.statusType === "carrier") {
      url = `/carriers/statuses`;
      statusKey = "statuses";
    } else if (this.props.statusType === "equipment") {
      url = `/lanes/equipment-types`;
      statusKey = "equipment_types";
    } else if (this.props.statusType === "laneTypes") {
      url = `/lanes/types`;
      statusKey = "types";
    } else if (this.props.statusType === "modes") {
      url = `/lanes/modes`;
      statusKey = "modes";
    } else if (this.props.statusType === "requirement") {
      url = `/lanes/requirement-types`;
      statusKey = "requirement_types";
    } else {
      url = `/accounts/statuses`;
      statusKey = "account_statuses";
    }
    if (checkError === true) {
      axios({
        method: "PUT",
        url,
        data: {
          [statusKey]: this.state.sourceArray.map((source, index) => {
            const { name, _new, _destroy, id, _rename } = source;
            return {
              ...(_new !== true && { id }),
              name,
              _new: _new === undefined ? false : _new,
              // _rename: _rename === undefined ? false : _rename,
              _destroy: _destroy === undefined ? false : _destroy,
              ...(this.props.statusType !== "carrier" &&
                this.props.statusType !== "equipment" &&
                this.props.statusType !== "laneTypes" &&
                this.props.statusType !== "modes" &&
                this.props.statusType !== "requirement" && {
                  _rename: _rename === undefined ? false : _rename,
                }),
              ...(_destroy &&
                this.props.statusType !== "carrier" &&
                this.props.statusType !== "equipment" &&
                this.props.statusType !== "laneTypes" &&
                this.props.statusType !== "modes" &&
                this.props.statusType !== "requirement" && {
                  replace_with: source.replace_with,
                }),
              ...(_destroy &&
                (this.props.statusType === "carrier" ||
                  this.props.statusType === "equipment" ||
                  this.props.statusType === "laneTypes" ||
                  this.props.statusType === "modes" ||
                  this.props.statusType === "requirement") && {
                  replace_with_id: source.replace_with_id,
                }),
              position: index + 1,
            };
          }),
        },
      })
        .then((res) => {
          this.props.handleCloseModal();
          this.props.fetchAllAccountStatuses();
          this.props.fetchAllContactStatuses();
          this.props.fetchAllCarrierStatuses();
          this.props.fetchAllEquipmentTypes();
          this.props.fetchAllSpecialRequirements();
          this.props.fetchAllLaneTypes();
          this.props.fetchAllModes();
          NotificationManager.success("Status Updated.");
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          NotificationManager.error("Error updating status.");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };
  setSelectedStatus = (status) => {
    this.setState({
      selectedStatus: status,
    });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (
      this.state.sourceArray[newIndex]?.name?.toLowerCase() ===
        "disqualified" ||
      this.state.sourceArray[newIndex]?.name?.toLowerCase() === "lead" ||
      this.state.sourceArray[newIndex]?.name?.toLowerCase() === "long-term" ||
      this.state.sourceArray[newIndex]?.name?.toLowerCase() === "customer" ||
      this.state.sourceArray[oldIndex]?.name?.toLowerCase() ===
        "disqualified" ||
      this.state.sourceArray[oldIndex]?.name?.toLowerCase() === "lead" ||
      this.state.sourceArray[oldIndex]?.name?.toLowerCase() === "long-term" ||
      this.state.sourceArray[oldIndex]?.name?.toLowerCase() === "customer"
    ) {
      return false;
    }
    this.setState(({ sourceArray }) => ({
      sourceArray: arrayMove(sourceArray, oldIndex, newIndex),
    }));
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const sourceNameArray = this.state.sourceArray?.map(
      (source) => source.name
    );
    let duplicateArray = false;
    // call some function with callback function as argument
    duplicateArray = sourceNameArray?.some((element, index) => {
      return sourceNameArray.indexOf(element) !== index;
    });
    console.log("status dekho", this.state.sourceArray, this.props.statusType);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "80vh", top: "5%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">
                    Customize{" "}
                    {this.props.statusType === "equipment"
                      ? "Equipment Types"
                      : this.props.statusType === "modes"
                      ? "Modes"
                      : this.props.statusType === "laneTypes"
                      ? "Lane Types"
                      : this.props.statusType === "requirement"
                      ? "Special Requirements"
                      : "Status"}
                  </h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <button
                  type="button"
                  onClick={this.addEditFieldChoice}
                  className="button-sm"
                  style={
                    this.props.statusType !== "equipment" &&
                    this.props.statusType !== "laneTypes" &&
                    this.props.statusType !== "modes" &&
                    this.props.statusType !== "requirement" &&
                    this.state.sourceArray?.filter(
                      (choice) => choice._destroy !== true
                    ).length === 11
                      ? {
                          background: "#A6A6B0",
                          color: "white",
                          border: "1px solid #A6A6B0",
                          cursor: "not-allowed",
                          marginBottom: "5px",
                        }
                      : { marginBottom: "5px" }
                  }
                  disabled={
                    this.props.statusType !== "equipment" &&
                    this.props.statusType !== "laneTypes" &&
                    this.props.statusType !== "modes" &&
                    this.props.statusType !== "requirement" &&
                    this.state.sourceArray?.filter(
                      (choice) => choice._destroy !== true
                    ).length === 11
                  }
                >
                  Add{" "}
                  {this.props.statusType === "equipment"
                    ? "Equipment Types"
                    : this.props.statusType === "modes"
                    ? "Modes"
                    : this.props.statusType === "laneTypes"
                    ? "Lane Types"
                    : this.props.statusType === "requirement"
                    ? "Special Requirements"
                    : "Status"}
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
                <div style={{ height: "60vh", overflowY: "auto" }}>
                  <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {this.state.sourceArray?.map((choice, index) =>
                      choice._destroy === true ? null : (
                        <SortableItem
                          key={choice.id}
                          index={index}
                          choice={choice}
                          editChoiceChangeHandler={this.editChoiceChangeHandler}
                          sourceArray={this.state.sourceArray}
                          deleteChoiceChangeHandler={
                            this.deleteChoiceChangeHandler
                          }
                          handleOpenTransferModal={this.handleOpenTransferModal}
                          setSelectedStatus={this.setSelectedStatus}
                          errorMessage={this.state.errorMessage}
                        />
                      )
                    )}
                  </SortableContainer>
                </div>
                {this.state.errorMessage === true && duplicateArray && (
                  <div
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {this.props.statusType === "equipment"
                      ? "Equipment Types"
                      : this.props.statusType === "modes"
                      ? "Modes"
                      : this.props.statusType === "laneTypes"
                      ? "Lane Types"
                      : this.props.statusType === "requirement"
                      ? "Special Requirements"
                      : "Statuses"}{" "}
                    must be unique
                  </div>
                )}
                {this.props.statusType !== "equipment" &&
                  this.props.statusType !== "modes" &&
                  this.props.statusType !== "laneTypes" &&
                  this.props.statusType !== "requirement" &&
                  this.state.sourceArray?.filter(
                    (choice) => choice._destroy !== true
                  ).length === 11 && (
                    <div
                      style={{
                        display: "block",
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      Number of statuses cannot exceed above 11
                    </div>
                  )}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
        <StatusTransferModal
          showOpportunityModal={this.state.showTransferModal}
          handleCloseModal={this.handleCloseTransferModal}
          selectedStatus={this.state.selectedStatus}
          sourceArray={this.state.sourceArray}
          deleteChoiceChangeHandler={this.deleteChoiceChangeHandler}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allContactStatuses: state.allContactStatuses.contactStatuses,
    allLaneTypes: state.allLaneTypes.laneTypes,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllEquipmentTypes: (equipmentData) =>
      dispatch(fetchAllEquipmentTypes(equipmentData)),
    fetchAllSpecialRequirements: (specialRequirements) =>
      dispatch(fetchAllSpecialRequirements(specialRequirements)),
    fetchAllModes: (modes) => dispatch(fetchAllModes(modes)),
    fetchAllAccountStatuses: (data) => dispatch(fetchAllAccountStatuses(data)),
    fetchAllCarrierStatuses: (data) => dispatch(fetchAllCarrierStatuses(data)),
    fetchAllContactStatuses: (data) => dispatch(fetchAllContactStatuses(data)),
    fetchAllLaneTypes: (laneTypes) => dispatch(fetchAllLaneTypes(laneTypes)),
  };
};

export default connect(MSP, MDP)(StatusChangeModal);

import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../sass/Reports.scss";
import moment from "moment";
import Pagination from "react-js-pagination";

const CampaignPage = (props) => {
  const [campaign, setCampaign] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const getCampaign = (page = 1) => {
    axios({
      method: "GET",
      url: `/email-campaigns/${props.campaignId}?_limit=30&_page=${page}`,
    }).then((res) => {
      setCampaign(res.data.activities);
      setTotal(res.data.total);
    });
  };
  useEffect(() => getCampaign(), []);
  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>
            {props.campaignName}{" "}
            <span style={{ color: "#4A9876" }}>{total}</span>
          </h2>
          <p> </p>
        </div>
      </div>
      <div style={{ width: "96vw", height: "auto", overflowX: "auto" }}>
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              <th>Record</th>
              <th>Email</th>
              <th>Status</th>
              <th>Delivered At</th>
              <th>Opened</th>
              <th>First Opened At</th>
              <th>Clicked</th>
              <th>bounced</th>
              <th>unsubscribed</th>
              <th>replied</th>
            </tr>
          </thead>
          <tbody>
            {campaign?.map((cam) => {
              const {
                id,
                to_email,
                delivered_at,
                clicked,
                bounced,
                replied,
                account_name,
                contact_name,
                opportunity_name,
                status,
                opened,
                first_opened_at,
                unsubscribed,
              } = cam;
              return (
                <tr key={id}>
                  <td>{account_name || contact_name || opportunity_name}</td>
                  <td>{to_email}</td>
                  <td>{status}</td>
                  <td>{moment(delivered_at).format("lll")}</td>
                  <td>{opened ? "Yes" : "No"}</td>
                  <td>{moment(first_opened_at).format("lll")}</td>
                  <td>{clicked ? "Yes" : "No"}</td>
                  <td>{bounced ? "Yes" : "No"}</td>
                  <td>{replied ? "Yes" : "No"}</td>
                  <td>{unsubscribed ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          getCampaign(page);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

export default CampaignPage;

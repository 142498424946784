import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { NotificationManager } from "react-notifications";

class DisqualifyModal extends Component {
  state = {
    showModal: true,
    close_reason: [],
    close_reason_value: "",
    errorMessage: false,
    loading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  componentDidMount() {
    axios({
      method: "GET",
      url: `/close-reasons`,
    }).then((response) => {
      this.setState({
        close_reason: response.data.reasons,
      });
    });
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.close_reason_value) {
      this.setState({ errorMessage: true, loading: false });
      return false;
    }
    this.setState({
      loading: true,
    });
    axios({
      method: "PUT",
      url: `/accounts/${this.props.customerId}/status`,
      data: {
        status: "Disqualified",
        disqualify_reason: this.state.close_reason_value,
      },
    })
      .then((res) => {
        NotificationManager.success("Successfully changed status");
        this.setState({
          loading: false,
        });
        this.props.handleCloseModal();
        this.props.fetchAccountData();
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "38vh", top: "20%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Disqualify Account</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.props.addGroupHandler}>
                <label>Account Name</label>
                <input
                  name="label"
                  type="text"
                  value={this.props.customerName}
                  readOnly={true}
                />
                <label>Disqualify Reason</label>
                <select
                  name="close_reason_value"
                  value={this.state.close_reason_value}
                  onChange={this.changeHandler}
                  style={
                    this.state.errorMessage === true &&
                    (this.state.close_reason_value === null ||
                      this.state.close_reason_value === "")
                      ? {
                          border: "2px solid #F36363",
                          width: "100%",
                        }
                      : { width: "100%" }
                  }
                >
                  <option value="" selected disabled>
                    -Select-
                  </option>
                  {this.state?.close_reason?.map((reason) => {
                    const { name, id, type } = reason;
                    const option =
                      type === "lost" ? (
                        <option key={id} value={name}>
                          {name}
                        </option>
                      ) : null;
                    return option;
                  })}
                </select>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.close_reason_value === null ||
                    this.state.close_reason_value === "")
                    ? "Please select disqualify reason"
                    : null}
                </div>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    onClick={this.submitHandler}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default DisqualifyModal;

import React, { useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";

import Select from "react-select";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Modal, Box, MenuItem, Switch, FormControlLabel } from "@mui/material";
import { DateTime } from "luxon";

import { muiModalStyle } from "../../../constants/muiModalStyle";
import { customStylesErr } from "../../../constants/selectStyles";
import InputField from "../../../components/Reusables/InputField/InputField";
import MuiSelect from "../../../components/Reusables/Select/MuiSelect";
import MuiButton from "../../../components/Reusables/Button/MuiButton";
import { User, Users } from "lucide-react";
import { useCallback } from "react";
import moment from "moment";

const CreateScheduleReport = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  colourStyles,
  audience,
  reports,
  getAllScheduleReports,
  days,
  monthDays,
  setQuery,
  isReportsLoading,
}) => {
  // Validation Schema
  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters"),
    reports: Yup.string().required("Report is required").nullable(),
    audience: Yup.array().required("Please select user/team"),
    exportType: Yup.string().required("Export Type is required"),
    frequency: Yup.string().required("Frequency is required"),
    time: Yup.string().required("Time is required"),
    isWeekday: Yup.boolean(),
    weekday: Yup.string()
      .nullable()
      .when("frequency", {
        is: (frequency) => frequency && frequency === "weekly",
        then: (schema) => schema.required("Day of week is required"),
        otherwise: (schema) => schema.nullable(),
      }),
    monthDay: Yup.string()
      .nullable()
      .when("frequency", {
        is: (frequency) => frequency && frequency === "monthly",
        then: (schema) => schema.required("Day of month is required"),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  // Initial Form Values
  const initialValues = {
    name: "",
    reports: "",
    audience: [],
    exportType: "",
    frequency: "",
    weekday: null,
    monthDay: null,
    time: "",
    isWeekday: false,
  };

  const [isLoading, setIsLoading] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState("America/Chicago");

  const [time, setTime] = useState(null);
  const [utctime, setUtcTime] = useState(null);

  const dailyValues = "1,2,3,4,5,6,0";

  const timeZones = [
    { value: "America/Los_Angeles", label: "Pacific Time Zone (PT)" },
    { value: "America/Chicago", label: "Central Time Zone (CT)" },
    { value: "America/New_York", label: "Eastern Time Zone (ET)" },
    { value: "America/Denver", label: "Mountain Time Zone (MT)" },
  ];

  const timeChangeHandler = useCallback(
    (time, timeZone = selectedTimezone) => {
      const inputDate = new Date(time);
      const formattedDate =
        inputDate.toISOString().split("T")[0] +
        "T" +
        inputDate.toTimeString().split(" ")[0];

      setTime(time);
      setSelectedTimezone(timeZone);

      // Parse user-entered time with time zone
      const userDateTime = DateTime.fromISO(formattedDate, {
        zone: timeZone,
      });
      // Convert user-entered time to UTC
      const utcDateTime = userDateTime.toUTC();
      const utcTime = utcDateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
      setUtcTime(utcTime);
    },
    [selectedTimezone]
  );

  // Submit Handler
  const reportSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    const {
      name,
      audience,
      exportType,
      monthDay,
      reports,
      weekday,
      frequency,
      isWeekday,
    } = values;

    const audienceType = audience.map((item) => ({
      id: item.value,
      type: item.type,
    }));

    const notAllWeek = "1,2,3,4,5";

    const weeklyValues =
      frequency === "daily" && !isWeekday
        ? dailyValues
        : frequency === "weekly"
        ? weekday
        : frequency === "daily" && isWeekday
        ? notAllWeek
        : null;

    const isFrequencyCheck = frequency === "daily" || frequency === "weekly";

    axios({
      method: "POST",
      url: `/reports/schedule`,
      data: {
        name: name,
        report_id: reports.value,
        report_export_type: exportType,
        utc_time: utctime,
        days_of_week: isFrequencyCheck ? weeklyValues : null,
        day_of_month: isFrequencyCheck ? null : monthDay,
        schedule_audience: audienceType,
      },
    })
      .then((res) => {
        setIsLoading(false);
        resetForm();
        handleCloseModal();
        getAllScheduleReports();
      })
      .catch((err) => {
        setIsLoading(false);
        NotificationManager.error("Failed to create report");
      });
  };

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      style={muiModalStyle}
      aria-describedby="schedule-report-modal"
    >
      <div className="muiModal">
        <div
          className="modal__content"
          style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
        >
          <div className="modal__header">
            <div className="modal__head">
              <h2 className="modal__heading">Create Schedule Report</h2>
            </div>
            <button
              onClick={handleCloseModal}
              className="modal__close"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <ClearIcon fontSize="small" />
            </button>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={reportSubmit}
          >
            {({ errors, touched, setFieldValue, values, handleChange }) => (
              <Form
                className="modal__form"
                style={{
                  overflowY: "scroll",
                  height: "55vh",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {/* Name Input */}
                  <InputField
                    fullWidth
                    name="name"
                    label="Name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  {/* Reports Select */}
                  <Select
                    value={values.reports}
                    closeMenuOnSelect={true}
                    name="reports"
                    styles={
                      touched.reports && errors.reports
                        ? {
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 10,
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: "200px",
                            }),
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 10,
                            }),
                            ...customStylesErr,
                          }
                        : colourStyles
                    }
                    options={reports.map((report) => ({
                      value: report.id,
                      label: report.name,
                    }))}
                    placeholder="Select Reports"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedReport) =>
                      setFieldValue("reports", selectedReport)
                    }
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue) {
                        setQuery(inputValue);
                      }
                      return isReportsLoading
                        ? "Loading..."
                        : "No reports found";
                    }}
                  />
                  {touched.reports && errors.reports && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        marginTop: "-10px",
                        marginLeft: "14px",
                      }}
                    >
                      {errors.reports}
                    </span>
                  )}

                  {/* Audience Select */}

                  <Select
                    value={audience
                      .filter((user) =>
                        values.audience?.some(
                          (selected) => selected.value === user.id
                        )
                      )
                      .map((user) => ({
                        value: user.id,
                        type: user.type,
                        name: user.name,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                            title={user.type}
                          >
                            {user.name}
                          </div>
                        ),
                      }))}
                    closeMenuOnSelect={false}
                    isMulti
                    name="audience"
                    styles={
                      touched.audience && errors.audience
                        ? {
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 10,
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: "200px",
                            }),
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 10,
                            }),
                            ...customStylesErr,
                          }
                        : colourStyles
                    }
                    options={audience.map((user) => {
                      const { id, name, type } = user;
                      return {
                        value: id,
                        type: type,
                        name: name,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                            title={type}
                          >
                            {type === "team" ? (
                              <Users size={20} />
                            ) : (
                              <User size={20} />
                            )}
                            {name}
                          </div>
                        ),
                      };
                    })}
                    placeholder="Select Users/Teams"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedAudience) => {
                      if (!selectedAudience) {
                        setFieldValue("audience", []);
                        return;
                      }
                      const transformedAudience = selectedAudience.map(
                        (item) => ({
                          value: item.value,
                          type: item.type,
                          name: item.name,
                        })
                      );
                      setFieldValue("audience", transformedAudience);
                    }}
                  />
                  {touched.audience && errors.audience && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        marginTop: "-10px",
                        marginLeft: "14px",
                      }}
                    >
                      {errors.audience}
                    </span>
                  )}

                  {/* Export Type */}
                  <MuiSelect
                    label="Export Type"
                    sx={{ margin: 0 }}
                    error={touched.exportType && Boolean(errors.exportType)}
                    helperText={touched.exportType && errors.exportType}
                    value={values.exportType}
                    onChange={handleChange}
                    name="exportType"
                    menuItem={[
                      <MenuItem key="excel" value="excel">
                        Excel
                      </MenuItem>,
                      <MenuItem key="csv" value="csv">
                        Csv
                      </MenuItem>,
                    ]}
                  />

                  {/* Frequency Select */}
                  <MuiSelect
                    label="Frequency"
                    sx={{ margin: 0 }}
                    error={touched.frequency && Boolean(errors.frequency)}
                    helperText={touched.frequency && errors.frequency}
                    value={values.frequency}
                    onChange={(e) => {
                      handleChange(e);
                      if (
                        e.target.value === "weekly" ||
                        e.target.value === "daily"
                      ) {
                        setFieldValue("monthDay", null);
                      } else if (e.target.value === "monthly") {
                        setFieldValue("weekday", null);
                      }
                    }}
                    name="frequency"
                    menuItem={[
                      <MenuItem key="daily" value="daily">
                        Daily
                      </MenuItem>,
                      <MenuItem key="weekly" value="weekly">
                        Weekly
                      </MenuItem>,
                      <MenuItem key="monthly" value="monthly">
                        Monthly
                      </MenuItem>,
                    ]}
                  />

                  {values.frequency === "weekly" && (
                    <div>
                      {/* Week Day Select */}
                      <MuiSelect
                        label="Day of the Week"
                        sx={{ margin: 0 }}
                        error={touched.weekday && Boolean(errors.weekday)}
                        helperText={touched.weekday && errors.weekday}
                        value={values.weekday}
                        onChange={handleChange}
                        name="weekday"
                        menuItem={days.map((day) => (
                          <MenuItem key={day.value} value={day.value}>
                            {day.label}
                          </MenuItem>
                        ))}
                      />
                    </div>
                  )}

                  {values.frequency === "monthly" && (
                    <div>
                      {/* Month Day Select */}
                      <MuiSelect
                        label="Day of the month"
                        sx={{ margin: 0 }}
                        error={touched.monthDay && Boolean(errors.monthDay)}
                        helperText={touched.monthDay && errors.monthDay}
                        value={values.monthDay}
                        slotProps={{
                          select: {
                            MenuProps: {
                              PaperProps: {
                                sx: { maxHeight: 300 },
                              },
                            },
                          },
                        }}
                        onChange={handleChange}
                        name="monthDay"
                        menuItem={monthDays.map((day) => (
                          <MenuItem key={day.value} value={day.value}>
                            {day.label}
                          </MenuItem>
                        ))}
                      />
                    </div>
                  )}

                  {values.frequency && (
                    <>
                      {/* Time Select */}

                      <Box>
                        <DatePicker
                          selected={Date.parse(time)}
                          onChange={(time) => {
                            const currentTime = moment(time).format("hh:mm A");
                            timeChangeHandler(time, selectedTimezone);
                            setFieldValue("time", currentTime);
                          }}
                          showTimeSelect
                          value={time}
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select time"
                          autoComplete="off"
                          timeFormat="h:mm aa"
                          className={
                            touched.time && errors.time
                              ? "timePickerErr"
                              : "timePicker"
                          }
                        />
                        {touched.time && errors.time && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12px",
                              marginTop: "-10px",
                              marginLeft: "14px",
                            }}
                          >
                            {errors.time}
                          </span>
                        )}
                      </Box>

                      <Box>
                        <MuiSelect
                          label="Timezone"
                          sx={{ margin: 0, zIndex: 0 }}
                          value={selectedTimezone}
                          onChange={(selected) =>
                            timeChangeHandler(time, selected.target.value)
                          }
                          menuItem={timeZones?.map((tz) => (
                            <MenuItem key={tz.value} value={tz.value}>
                              {tz.label}
                            </MenuItem>
                          ))}
                        />
                      </Box>
                    </>
                  )}

                  {values.frequency === "daily" && (
                    <>
                      {/* Weekday Switch */}
                      <Box>
                        <FormControlLabel
                          label="Select Weekday"
                          name="weekdayLabel"
                          labelPlacement="start"
                          sx={{ margin: 0 }}
                          control={
                            <Switch
                              name="isWeekday"
                              checked={values.isWeekday}
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  backgroundColor: "#62CA9D",
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor: "#ccc",
                                  opacity: 0.5,
                                },
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    backgroundColor: "#62CA9D",
                                  },
                                  "& + .MuiSwitch-track": {
                                    backgroundColor: "#62CA9D",
                                    opacity: 0.7,
                                  },
                                },
                              }}
                              onChange={handleChange}
                            />
                          }
                        />
                        <label style={{ width: "200px" }}>
                          <small>
                            Report will only occured Monday through Friday
                          </small>
                        </label>
                      </Box>
                    </>
                  )}
                </Box>

                {/* Action Buttons */}
                <div className="modal__buttons">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    style={{ bottom: "10px" }}
                    className="modal__cancel-button"
                  >
                    Cancel
                  </button>

                  <MuiButton
                    type="submit"
                    className="saveBtn modal__confirm-button"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "20%",
                      width: "auto",
                    }}
                    disabled={isLoading}
                    endIcon={<AddIcon style={{ color: "#62CA9D" }} />}
                  >
                    {isLoading ? "Creating..." : "Create"}
                  </MuiButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default CreateScheduleReport;

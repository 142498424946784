import axios from "axios";
import ReactModal from "react-modal";
import { NotificationManager } from "react-notifications";

import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";

const DeleteScheduleReport = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  getAllScheduleReports,
  reportData,
}) => {
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "DELETE",
      url: `/reports/schedule/${reportData?.id}`,
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        getAllScheduleReports();
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error deleting webhooks.");
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <div className="modal__head">
                <h2 className="modal__heading">Delete Schedule Report</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  top: "24px",
                  cursor: "pointer",
                }}
              >
                {" "}
                <ClearIcon fontSize="small" />
              </button>
            </div>
            <div className="modal__form">
              <p>Are you sure you want to delete schedule for this report?</p>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
                style={{ zIndex: "auto" }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={submitHandler}
                style={{ zIndex: "auto" }}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default DeleteScheduleReport;

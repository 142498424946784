import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { CurrencyList } from "../../constants/currencylist";
import { countriesList } from "../../constants/countriesList";
import Sidebar from "../Profile/Sidebar";
import { colourStyles } from "../../constants/selectStyles";
// Redux
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../redux";

function CompanySettings(props) {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedFormat, setSelectedFormat] = useState("MM/DD/YYYY");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [isRoundRobin, setIsRoundRobin] = useState(false);
  const [isMultipleAccountOwner, setIsMultipleAccountOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tms, setTms] = useState({ value: null, label: "-" });
  const [mcleodToken, setmcleodToken] = useState(null);
  const [mcleodBaseUrl, setmcleodBaseUrl] = useState(null);
  const [taiEmail, setTaiEmail] = useState(null);

  const tmsOptions = [
    { value: null, label: "-" },
    // { value: "tai", label: "TAI" },
    { value: "mcleod", label: "McLeod" },
    // { value: "mercury", label: "Mercury" },
  ];

  const imageRef = useRef(null);
  const openFileSelect = () => imageRef.current.click();
  useEffect(() => {
    axios({
      method: "GET",
      url: `/company/settings`,
    }).then((res) => {
      setSelectedCurrency(res.data.settings.currency);
      setSelectedCountry(res.data.settings.country);
      setSelectedFormat(res.data.settings.date_format);
      setCompanyLogo(res.data.settings.logo);
      setEmail(res.data.settings.email);
      setMobile(res.data.settings.formatted_mobile);
      setIsMultipleAccountOwner(
        res.data.settings.is_multiple_account_owner_enabled
      );
      setTaiEmail(res.data.settings.tai_email);
      setTms(
        res.data.settings.tms_integrated
          ? {
              value: res.data.settings.tms_integrated,
              label: res.data.settings.tms_integrated,
            }
          : { value: null, label: "-" }
      );
      setmcleodBaseUrl(res.data.settings.mcleod_base_url);
      setmcleodToken(res.data.settings.mcleod_token);
    });
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios({
      method: "PATCH",
      url: `/company/settings`,
      data: {
        currency: selectedCurrency ? selectedCurrency : "USD",
        timezone,
        country: selectedCountry ? selectedCountry : "United States",
        date_format: selectedFormat ? selectedFormat : "MM/DD/YYYY",
        is_round_robin_enabled: isRoundRobin,
        is_multiple_account_owner_enabled: isMultipleAccountOwner,
        ...(companyLogo && { logo: companyLogo }),
        ...(email && { email }),
        ...(mobile && { formatted_mobile: mobile }),
        tms_integrated: tms.value,
        ...(tms.value === "mcleod" && { mcleod_token: mcleodToken }),
        ...(tms.value === "mcleod" && { mcleod_base_url: mcleodBaseUrl }),
      },
    })
      .then((res) => {
        NotificationManager.success("Company Info Updated.");
        setLoading(false);
        props.companyOnboardingSet({
          ...props.companyOnboarding,
          currency: selectedCurrency,
          country: selectedCountry,
          date_format: selectedFormat,
          logo: companyLogo,
          email,
          mobile,
          is_multiple_account_owner_enabled: isMultipleAccountOwner,
          tms_integrated: tms.value,
        });
        localStorage.setItem(
          "companyOnboarding",
          JSON.stringify({
            ...props.companyOnboarding,
            currency: selectedCurrency,
            country: selectedCountry,
            date_format: selectedFormat,
            logo: companyLogo,
            email,
            mobile,
            is_multiple_account_owner_enabled: isMultipleAccountOwner,
            tms_integrated: tms.value,
          })
        );
      })
      .catch((err) => {
        NotificationManager.error("Error updating company info");
        setLoading(false);
      });
  };
  // image uploader
  const handleImageChange = (event) => {
    submitAttachment(event.target.files[0]);
  };
  const submitAttachment = (selectedFile) => {
    setImageLoader(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    axios({
      method: "POST",
      url: `/company/upload-logo`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        setCompanyLogo(res.data.file.location);
        setImageLoader(false);
      })
      .catch((err) => {
        setImageLoader(false);
        NotificationManager.error("Error uploading company logo");
      });
  };
  return (
    <>
      <Sidebar />
      <div className="userProfile">
        <h2 className="userProfile-heading-main">Company Settings</h2>
        <form onSubmit={submitHandler}>
          <div className="userProfile-profile">
            <h3 className="userProfile-heading-sub">Company Logo</h3>
            <div className="userProfile-profile-flex">
              {imageLoader ? (
                <div class="load-wrapp">
                  <div class="load-3">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                  </div>
                </div>
              ) : companyLogo ? (
                <img
                  src={companyLogo}
                  alt=""
                  height="66px"
                  style={{ maxWidth: "40vw", borderRadius: "0" }}
                />
              ) : (
                <div className="userProfile-profile-substitute">
                  <div>
                    <i className="fa fa-camera"></i>
                  </div>
                </div>
              )}
              <p style={{ color: "#a7abaa" }}>
                This logo appears on Quotes/Invoices that are built within your
                deals.
              </p>
              <button
                className="button-md"
                type="button"
                onClick={openFileSelect}
              >
                {companyLogo ? "Change" : "Upload"}
              </button>
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleImageChange}
              ref={imageRef}
            />
          </div>
          <label>Company Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "97%" }}
          />
          <label>Company Phone Number</label>
          <input
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            style={{ width: "97%" }}
          />
          <label>Currency</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{ value: selectedCurrency, label: selectedCurrency }}
            onChange={(selected) => setSelectedCurrency(selected.value)}
            isClearable={false}
            isSearchable={true}
            name="currency"
            options={CurrencyList?.map((currency) => ({
              value: currency.code,
              label: currency.code,
            }))}
          />
          <label>Country</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{ value: selectedCountry, label: selectedCountry }}
            onChange={(selected) => setSelectedCountry(selected.value)}
            isClearable={false}
            isSearchable={true}
            name="country"
            options={countriesList?.map((country) => ({
              value: country,
              label: country,
            }))}
          />
          <label>Date Format</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{ value: selectedFormat, label: selectedFormat }}
            onChange={(selected) => setSelectedFormat(selected.value)}
            isClearable={false}
            isSearchable={true}
            name="selectedFormat"
            options={[
              {
                value: "MM/DD/YYYY",
                label: "MM/DD/YYYY",
              },
              {
                value: "DD/MM/YYYY",
                label: "DD/MM/YYYY",
              },
            ]}
          />
          <label>Select TMS</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={colourStyles}
            value={{ value: tms.value, label: tms.label }}
            onChange={(selected) => setTms(selected)}
            isClearable={false}
            isSearchable={true}
            name="tms"
            options={tmsOptions}
          />
          {tms.value === "mcleod" && (
            <>
              <label>McLeod Token</label>
              <input
                value={mcleodToken}
                onChange={(e) => setmcleodToken(e.target.value)}
                style={{ width: "97%" }}
              />
              <label>McLeod Base URL</label>
              <input
                value={mcleodBaseUrl}
                onChange={(e) => setmcleodBaseUrl(e.target.value)}
                style={{ width: "97%" }}
              />
            </>
          )}
          {tms.value === "tai" && <>{taiEmail}</>}
          <label
            style={{ fontSize: "16px", fontWeight: "500", marginTop: "10px" }}
          >
            Shared Account Owners
          </label>
          <label style={{ fontSize: "14px" }}>
            If you would like to have multiple team members have ownership and
            visibility over Accounts in your CRM, please check the box below.
          </label>
          <input
            id="isMultipleAccountOwner"
            type="checkbox"
            name="isMultipleAccountOwner"
            style={{ width: "20px", display: "block" }}
            value={isMultipleAccountOwner}
            checked={isMultipleAccountOwner}
            onChange={() => setIsMultipleAccountOwner(!isMultipleAccountOwner)}
          />
          {/* <label
            style={{ fontSize: "16px", fontWeight: "500", marginTop: "10px" }}
          >
            Round-Robin Leads
          </label>
          <label style={{ fontSize: "14px" }}>
            Select the users that you would like to have inbound leads
            distributed to from your Zapier integration.
          </label>
          <input
            id="isRoundRobin"
            type="checkbox"
            name="isRoundRobin"
            style={{ width: "20px", display: "block" }}
            value={isRoundRobin}
            checked={isRoundRobin}
            onChange={() => setIsRoundRobin(!isRoundRobin)}
          /> */}
          <button
            className="button-md"
            type="submit"
            disabled={loading}
            style={{ marginTop: "15px" }}
          >
            {loading ? "Saving" : "Save Changes"}
          </button>
        </form>
      </div>
    </>
  );
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(CompanySettings);

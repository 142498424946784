import React, { Component } from "react";
import "../sass/Reports.scss";
import ReportsCreateModal from "./Reports/ReportsCreateModal";
import axios from "axios";
import moment from "moment";
import Pagination from "react-js-pagination";
import { debounce } from "lodash";
import { Col, Hidden, Row } from "react-grid-system";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex, companyOnboardingSet } from "../redux";
import { Plus } from "lucide-react";

class Reports extends Component {
  state = {
    showOpportunityModal: false,
    reportsData: [],
    query: "",
    activePage: 1,
    total: 0,
    filter: "all",
    sortDirection: true,
  };
  handleOpenModal = () => {
    this.setState({
      showOpportunityModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showOpportunityModal: false,
    });
  };

  fetchReports = debounce((query, page = 1, sortKey = "last_viewed_at") => {
    let url = `/reports?_limit=30&_page=${page}&type=${this.state.filter}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (this.state.filter === "imports") {
      url += `&import_reports=true`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      console.log("res.data.total:", res.data.total);
      this.setState({ reportsData: res.data.reports, total: res.data.total });
    });
  }, 500);

  componentDidMount() {
    this.fetchReports();
  }
  deleteReport = (id, e) => {
    e.preventDefault();
    axios({
      method: "DELETE",
      url: `/reports/${id}`,
    }).then((res) => window.location.reload());
  };

  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="reports">
        <div
          style={{
            borderBottom: "1px solid #E3E3E3",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <h2 style={{ marginBottom: "0px" }}>Reports</h2>
            <p>
              Add reports related to accounts, carriers, contacts, deals &
              activities
            </p>
          </div>
        </div>
        <div style={{ marginTop: "14px" }}>
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search reports"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchReports(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <Hidden md sm xs>
            <button
              onClick={this.handleOpenModal}
              className="button-md"
              style={{ float: "right", marginRight: "20px" }}
            >
              Create Report
              <Plus
                size={14}
                strokeWidth={3}
                color="#62CA9D"
                style={{ transform: "translateY(2px)", marginLeft: "5px" }}
              />
            </button>
          </Hidden>
          <ReportsCreateModal
            showOpportunityModal={this.state.showOpportunityModal}
            setShowOpportunityModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            isFreight={
              this.props.companyOnboarding?.industry === "Freight Brokerage"
            }
          />
        </div>
        <div style={{ margin: "20px 0 10px 0" }}>
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "all" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "all"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            All Reports
          </button>
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "accounts" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "accounts"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            Accounts
          </button>
          {this.props.companyOnboarding?.industry === "Freight Brokerage" && (
            <button
              type="button"
              onClick={() => {
                this.setState({ filter: "carriers" });
                this.fetchReports();
              }}
              style={
                this.state.filter === "carriers"
                  ? {
                      border: "none",
                      fontSize: "15px",
                      lineHeight: "18px",
                      marginRight: "18px",
                      color: "#4A9876",
                      outline: "none",
                      cursor: "pointer",
                      background: "#F4F6F5",
                      padding: "5px 12px 5px 12px",
                      borderRadius: "9px",
                    }
                  : {
                      border: "none",
                      fontSize: "15px",
                      lineHeight: "18px",
                      marginRight: "18px",
                      color: "#657885",
                      background: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      padding: "5px 12px 5px 12px",
                      borderRadius: "9px",
                    }
              }
            >
              Carriers
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "opportunities" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "opportunities"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            Deals
          </button>
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "contacts" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "contacts"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            Contacts
          </button>
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "activities" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "activities"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            Activities
          </button>
          <button
            type="button"
            onClick={() => {
              this.setState({ filter: "imports" });
              this.fetchReports();
            }}
            style={
              this.state.filter === "imports"
                ? {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#4A9876",
                    outline: "none",
                    cursor: "pointer",
                    background: "#F4F6F5",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
                : {
                    border: "none",
                    fontSize: "15px",
                    lineHeight: "18px",
                    marginRight: "18px",
                    color: "#657885",
                    background: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "9px",
                  }
            }
          >
            Imports
          </button>
        </div>
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              <th>
                <Row>
                  <Col lg={9} xl={9}>
                    Report Name
                  </Col>
                  <Col lg={3} xl={3}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        color: "#657885",
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            sortDirection: !this.state.sortDirection,
                          },
                          this.fetchReports(
                            null,
                            this.state.activePage,
                            "report_name"
                          )
                        )
                      }
                    >
                      <i className="fa fa-long-arrow-up"></i>
                      <i className="fa fa-long-arrow-down"></i>
                    </button>
                  </Col>
                </Row>
              </th>
              <th>Description</th>
              <th>
                <Row>
                  <Col lg={9} xl={9}>
                    Category
                  </Col>
                  <Col lg={3} xl={3}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        color: "#657885",
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            sortDirection: !this.state.sortDirection,
                          },
                          this.fetchReports(null, this.state.activePage, "type")
                        )
                      }
                    >
                      <i className="fa fa-long-arrow-up"></i>
                      <i className="fa fa-long-arrow-down"></i>
                    </button>
                  </Col>
                </Row>
              </th>
              <th>
                <Row>
                  <Col lg={9} xl={9}>
                    Last Viewed
                  </Col>
                  <Col lg={3} xl={3}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        color: "#657885",
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            sortDirection: !this.state.sortDirection,
                          },
                          this.fetchReports(
                            null,
                            this.state.activePage,
                            "last_viewed_at"
                          )
                        )
                      }
                    >
                      <i className="fa fa-long-arrow-up"></i>
                      <i className="fa fa-long-arrow-down"></i>
                    </button>
                  </Col>
                </Row>
              </th>
              <th>
                <Row>
                  <Col lg={9} xl={9}>
                    Created Date
                  </Col>
                  <Col lg={3} xl={3}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        color: "#657885",
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            sortDirection: !this.state.sortDirection,
                          },
                          this.fetchReports(
                            null,
                            this.state.activePage,
                            "created_at"
                          )
                        )
                      }
                    >
                      <i className="fa fa-long-arrow-up"></i>
                      <i className="fa fa-long-arrow-down"></i>
                    </button>
                  </Col>
                </Row>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state?.reportsData.map((report) => {
              let newTabData = {
                type: "report",
                id: report.id,
                name: report.name,
              };
              const {
                id,
                name,
                type,
                description,
                created_at,
                last_viewed_at,
              } = report;
              return (
                <tr key={id}>
                  <td
                    style={{ cursor: "pointer", color: "#4A9876" }}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        this.props.setTab({ ...newTabData, blank: true });
                      } else {
                        this.props.setActiveTabIndex(this.props.totalTabs + 1);
                        this.props.setTab(newTabData);
                        this.props.history.push("/active-tabs");
                      }
                    }}
                  >
                    {name}
                  </td>
                  <td>
                    {description?.length > 140
                      ? description?.substring(0, 140)
                      : description}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {type === "opportunities" ? "Deals" : type}
                  </td>
                  <td>
                    {last_viewed_at &&
                      moment(last_viewed_at).format(dateFormat)}
                  </td>
                  <td>{created_at && moment(created_at).format(dateFormat)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchReports(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Reports);

import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
// import ReactTooltip from "react-tooltip";
import { colourStyles } from "../../constants/selectStyles";
import { allTimeZones } from "../../constants/timeZonesList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { DateTime } from "luxon";
import moment from "moment";

class ShotClockSettingsModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    userTimeZone: { label: "America/Chicago", value: "America/Chicago" },
    userTime: null,
    utcTime: null,
    isLoading: false,
    reminderType: "none",
    statusSelection: false,
    statusList: [],
    selectedStatus: "Long-term",
    allowGracePeriod: false,
    graceValue: "7",
  };

  allReminderTypes = [
    { value: "none", label: "No email reminders." },
    {
      value: "weekly",
      label:
        "Send an email every Monday with a list of all leads that are potentially going to be unassigned that week.",
    },
    {
      value: "daily",
      label:
        "Send an email to users 3 days out from their shot clock violation date with a list of all of the potential unassigned leads.",
    },
  ];

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  getStatuses = () => {
    axios({
      method: "GET",
      url: `/accounts/statuses`,
    }).then((res) => {
      this.setState({
        statusList: res.data.account_statuses?.filter(
          (status) =>
            status.name?.toLowerCase() !== "lead" &&
            status.name?.toLowerCase() !== "customer" &&
            status.name?.toLowerCase() !== "disqualified"
        ),
      });
    });
  };
  getShotClockSetting = () => {
    axios({
      method: "GET",
      url: `/admin/roles`,
    }).then((res) => {
      const settings = res.data?.shot_clock_settings;
      this.setState({
        userTimeZone: settings?.timezone
          ? { label: settings?.timezone, value: settings?.timezone }
          : { label: "America/Chicago", value: "America/Chicago" },
        userTime: settings?.time
          ? moment(settings?.time, "HH:mm:ss").format("hh:mm A")
          : null,
        reminderType:
          settings?.reminder_type === null ? "none" : settings?.reminder_type,
        statusSelection: settings?.account_status ? true : false,
        selectedStatus: settings?.account_status
          ? settings?.account_status
          : "Long-term",
        allowGracePeriod: settings?.grace_period ? true : false,
        graceValue: settings?.grace_period ? settings?.grace_period : "7",
      });
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getShotClockSetting();
    this.getStatuses();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.getShotClockSetting();
      this.getStatuses();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  timeChangeHandler = (
    time = this.state.userTime,
    timeZone = this.state.userTimeZone
  ) => {
    const inputDate = new Date(time);
    const formattedDate =
      inputDate.toISOString().split("T")[0] +
      "T" +
      inputDate.toTimeString().split(" ")[0];
    this.setState({
      userTime: time,
      userTimeZone: timeZone,
    });
    // Parse user-entered time with time zone
    const userDateTime = DateTime.fromISO(formattedDate, {
      zone: timeZone?.value,
    });
    // Convert user-entered time to UTC
    const utcDateTime = userDateTime.toUTC();
    const utcTime = utcDateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    this.setState({
      utcTime: utcTime,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const time =
      this.state.userTime !==
      moment(this.state.userTime, "HH:mm").format("HH:mm")
        ? this.state.userTime &&
          moment(this.state.userTime, "hh:mm A").format("HH:mm")
        : this.state.userTime && moment(this.state.userTime).format("HH:mm");
    let utcTime = this.state.utcTime;
    if (!utcTime) {
      const formatDate = DateTime.fromFormat(this.state.userTime, "hh:mm a");
      const inputDate = new Date(formatDate);
      const formattedDate =
        inputDate.toISOString().split("T")[0] +
        "T" +
        inputDate.toTimeString().split(" ")[0];
      // Parse user-entered time with time zone
      const userDateTime = DateTime.fromISO(formattedDate, {
        zone: this.state.userTimeZone.value,
      });
      // Convert user-entered time to UTC
      const utcDateTime = userDateTime.toUTC();
      utcTime = utcDateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    }
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/admin/shot-clock-settings`,
      data: {
        time,
        utc_time: utcTime,
        timezone: this.state.userTimeZone.value,
        reminder_type:
          this.state.reminderType === "none" ? null : this.state.reminderType,
        account_status: this.state.statusSelection
          ? this.state.selectedStatus
          : null,
        grace_period: this.state.allowGracePeriod
          ? this.state.graceValue
          : null,
      },
    })
      .then((res) => {
        NotificationManager.success("Shot Clock settings saved!");
        this.props.handleCloseModal();
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error updating shot clock settings");
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "75vh", top: "4%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Shot Clock Settings</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.submitHandler}>
                <p>
                  What time of day would you like the accounts to move to
                  Unassigned Leads?
                </p>
                <label style={{ fontWeight: "500" }}>Time</label>
                <DatePicker
                  selected={Date.parse(this.state.userTime)}
                  onChange={(time) =>
                    this.timeChangeHandler(time, this.state.userTimeZone)
                  }
                  value={this.state.userTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Time"
                  autoComplete="off"
                  style={{ height: "35px", border: "5px" }}
                />
                <div style={{ width: "100%" }}>
                  <label style={{ fontWeight: "500" }}>Timezone</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    styles={colourStyles}
                    value={{
                      value: this.state.userTimeZone.label,
                      label: this.state.userTimeZone.value,
                    }}
                    placeholder="-Select-"
                    onChange={(selected) =>
                      this.timeChangeHandler(this.state.userTime, selected)
                    }
                    isClearable={false}
                    isSearchable={true}
                    options={allTimeZones?.map((time) => ({
                      value: time,
                      label: time,
                    }))}
                  />
                </div>
                <label style={{ marginTop: "12px", fontWeight: "500" }}>
                  Would you like to send automated emails to your team when
                  leads are about to hit their shot clock violation?
                </label>
                {this.allReminderTypes.map((type) => (
                  <label style={{ marginBottom: "5px", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="reminderType"
                      value={type.value}
                      id={type.value}
                      className="radio-button"
                      onChange={() =>
                        this.setState({
                          reminderType: type.value,
                        })
                      }
                      checked={this.state.reminderType === type.value}
                      style={{
                        display: "inline-block",
                        width: "15px",
                        marginRight: "10px",
                        marginBottom: "0",
                      }}
                    />
                    {type.label}
                  </label>
                ))}
                <label
                  style={{
                    marginTop: "12px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="checkbox"
                    name="statusSelection"
                    value={this.state.statusSelection}
                    onChange={() =>
                      this.setState({
                        statusSelection: !this.state.statusSelection,
                      })
                    }
                    checked={this.state.statusSelection}
                    style={{
                      display: "inline-block",
                      width: "15px",
                      marginRight: "10px",
                      marginBottom: "0",
                    }}
                  />
                  Would you like to have a chosen status for accounts to NOT
                  count for the shot clock (i.e the Long-term status for those
                  that are 6+ months out)?
                </label>
                {this.state.statusSelection && (
                  <select
                    value={this.state.selectedStatus}
                    onChange={(e) =>
                      this.setState({
                        selectedStatus: e.target.value,
                      })
                    }
                  >
                    {this.state.statusList?.map((status) => (
                      <option key={status.id} value={status.name}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                )}
                <label
                  style={{
                    marginTop: "12px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="checkbox"
                    name="allowGracePeriod"
                    value={this.state.allowGracePeriod}
                    onChange={() =>
                      this.setState({
                        allowGracePeriod: !this.state.allowGracePeriod,
                      })
                    }
                    checked={this.state.allowGracePeriod}
                    style={{
                      display: "inline-block",
                      width: "15px",
                      marginRight: "10px",
                      marginBottom: "0",
                    }}
                  />
                  Would you like to set a grace period for how many days a user
                  is unable to move a lead back into their name after it has a
                  shot clock violation?
                </label>
                {this.state.allowGracePeriod && (
                  <label>
                    <input
                      type="number"
                      style={{
                        width: "25px",
                        display: "inline",
                        border: "none",
                        borderRadius: "0",
                        borderBottom: "1px solid black",
                      }}
                      value={this.state.graceValue}
                      onChange={(e) =>
                        this.setState({
                          graceValue: e.target.value,
                        })
                      }
                    />{" "}
                    days
                  </label>
                )}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.isLoading || !this.state.userTime}
                  >
                    {this.state.isLoading ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ShotClockSettingsModal;

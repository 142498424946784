import React, { Component } from "react";
import axios from "axios";
import { debounce } from "lodash";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  setReportsFilters,
} from "../../redux";

class TasksList extends Component {
  state = {
    tasks: [],
    activePage: 1,
    total: 0,
    sortDirection: false,
    taskType: this.props.taskType ? this.props.taskType : "",
    taskTime: "today",
    userId: this.props.userId ? this.props.userId : "",
    isApplying: true,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    categories: [],
    userName: this.props.authUser.name,
    user_ids: this.props.userId,
    displayMenu: false,
    displayUserName: this.props.userName,
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
      },
      () => this.fetchTasks()
    );
  };
  getAllCategories = () => {
    axios({
      method: "GET",
      url: `/tasks/categories`,
    }).then((res) => {
      const categories = res.data.task_categories;
      this.setState({
        categories,
      });
    });
  };
  fetchTasks = debounce((query, page = 1, sortKey) => {
    const { taskType, taskTime } = this.state;

    let url = `/tasks?_limit=30&_page=${page}&status=open`;
    if (this.state.user_ids) {
      url += `&user_ids=${this.state.user_ids}`;
    }
    if (query) {
      url += `&name=${query}`;
    }
    if (this.state.stageId !== "") {
      url += `&type=${this.state.taskType}`;
    }
    if (this.state.taskType !== "overdue") {
      url += `&time=${this.state.taskTime}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }

    this.props.setReportsFilters({
      id: "TasksList",
      filters: {
        query,
        activePage: page,
        sortKey,
        sortDirection: this.state.sortDirection,
        taskType,
        taskTime,
      },
    });
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        this.setState({
          tasks: res.data.tasks,
          total: res.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);

  fetchAllTasksFilters = () => {
    const selectedTasks = this.props.reportsFilters?.find(
      (report) => report.id === "TasksList"
    );
    if (this.props.reportsFilters && selectedTasks) {
      const { query, activePage, sortKey, sortDirection, taskType, taskTime } =
        selectedTasks.filters;
      this.setState(
        {
          query,
          activePage,
          sortKey,
          sortDirection,
          taskType,
          taskTime,
        },
        () =>
          this.fetchTasks(
            this.state.query,
            this.state.activePage,
            this.state.sortKey
          )
      );
    } else {
      this.fetchTasks();
    }
  };

  componentDidMount() {
    this.fetchTasks();
    this.getAllCategories();
    this.fetchAllTasksFilters();
  }
  taskChangeHandler = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value }, () => this.fetchTasks());
  };
  taskTimeChangeHandler = (e) => {
    const { value, name } = e.target;
    if (value === "upcoming") {
      this.setState({ taskTime: value, sortDirection: false }, () =>
        this.fetchTasks(null, 1, "date")
      );
    } else {
      this.setState({ taskTime: value }, () => this.fetchTasks());
    }
  };
  fieldChangeHandler = (task, name, value) => {
    let taskData = task;
    if (name === "date") {
      const offsetDate =
        value === null || value === ""
          ? new Date()
          : value.getTimezoneOffset() < 0
          ? new Date(value.getTime() - value.getTimezoneOffset() * 60000)
          : new Date(value.getTime() + value.getTimezoneOffset() * 60000);
      taskData = { ...task, date: offsetDate };
      this.setState({
        tasks: this.state.tasks?.map((t) => {
          if (t.id === task.id) {
            t = { ...task, date: offsetDate };
          }
          return t;
        }),
      });
    } else if (name === "category_id") {
      taskData = { ...task, [name]: value };
      this.setState({
        tasks: this.state.tasks?.map((t) => {
          if (t.id === task.id) {
            const category_name = this.state.categories.find(
              (cat) => cat.id.toString() === value.toString()
            );
            t = { ...task, [name]: value, category_name: category_name.name };
          }
          return t;
        }),
      });
    } else {
      taskData = { ...task, [name]: value };
      this.setState({
        tasks: this.state.tasks?.map((t) => {
          if (t.id === task.id) {
            t = { ...task, [name]: value };
          }
          return t;
        }),
      });
    }
    this.submitHandler(taskData, task);
  };
  submitHandler = debounce((taskData, task) => {
    const newDate =
      taskData.date === new Date(task.date).toLocaleDateString()
        ? new Date(taskData.date).getTimezoneOffset() < 0
          ? new Date(
              new Date(taskData.date).getTime() -
                new Date(taskData.date).getTimezoneOffset() * 60000
            ).toISOString()
          : new Date(
              new Date(taskData.date).getTime() +
                new Date(taskData.date).getTimezoneOffset() * 60000
            ).toISOString()
        : taskData.date;
    axios({
      method: "PUT",
      url: `/tasks/${task.id}`,
      data: {
        ...(task.account_id !== "" &&
          task.account_id !== null &&
          task.account_id !== undefined && {
            account_id: task.account_id,
          }),
        ...(task.contact_id !== "" &&
          task.contact_id !== null && {
            contact_id: task.contact_id,
          }),
        ...(task.opportunity_id !== "" &&
          task.opportunity_id !== null && {
            opportunity_id: task.opportunity_id,
          }),
        name: taskData.name,
        description: taskData.detail,
        date: newDate && moment(newDate).format("YYYY-MM-DD"),
        start_time:
          taskData.start_time !== null
            ? taskData.start_time !==
              moment(taskData.start_time, "HH:mm").format("HH:mm")
              ? taskData.start_time &&
                moment(taskData.start_time, "hh:mm A").format("HH:mm")
              : taskData.start_time &&
                moment(taskData.start_time).format("HH:mm")
            : null,
        end_time:
          taskData.end_time !== null
            ? taskData.end_time !==
              moment(taskData.end_time, "HH:mm").format("HH:mm")
              ? taskData.end_time &&
                moment(taskData.end_time, "hh:mm A").format("HH:mm")
              : taskData.end_time && moment(taskData.end_time).format("HH:mm")
            : null,
        category_id: taskData.category_id,
        assigned_to: taskData.assignedTo,
      },
    })
      .then((res) => {})
      .catch((err) => {
        NotificationManager.error("Error editing task. Refetching the tasks");
        this.fetchTasks();
      });
  }, 500);
  render() {
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1
          className="accountList__heading"
          style={{ textTransform: "capitalize" }}
        >
          {this.state.taskType} Tasks ({this.state.displayUserName}){" "}
          <span style={{ color: "#4A9876" }}>{this.state.total}</span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Tasks"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchTasks(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
        </div>
        <div style={{ margin: "10px 0" }}>
          <span style={{ fontWeight: "500" }}>
            Filter by Type : &nbsp;&nbsp;
          </span>
          <select
            value={this.state.taskType}
            onChange={this.taskChangeHandler}
            name="taskType"
            className="c-icon-1"
            style={{
              background: "#EEF6F2",
              color: "#FFB202",
              width: "130px",
              outline: "none",
            }}
          >
            <option value="">All Tasks</option>
            <option value="scheduled">Scheduled</option>
            <option value="open">Open</option>
            <option value="overdue">Overdue</option>
          </select>
          {this.state.taskType !== "overdue" && (
            <div className="dealsList-closeDate">
              <span style={{ fontWeight: "500" }}>
                Filter by Time : &nbsp;&nbsp;
              </span>
              <select
                value={this.state.taskTime}
                onChange={this.taskTimeChangeHandler}
                name="taskTime"
                className="c-icon-1"
                style={{
                  background: "#EEF6F2",
                  color: "#FFB202",
                  width: "130px",
                  outline: "none",
                }}
              >
                <option value="today">Today</option>
                <option value="upcoming">Upcoming</option>
              </select>
            </div>
          )}
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchTasks(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && this.state.tasks.length === 0 ? (
          <div
            style={{
              marginTop: "25px",
              fontSize: "16px",
              width: "auto",
              fontWeight: "500",
              marginLeft: "30vw",
            }}
          >
            No Task {this.state.taskType} at the moment.
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                <th>Task Owner</th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Task Name
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "task_name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>task assigned</th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Category
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "category_name"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Date
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(null, this.state.activePage, "date")
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      Start Time
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "start_time"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      End Time
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "end_time"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      City
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "account_city"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>

                <th>
                  <Row>
                    <Col lg={9} xl={9}>
                      State
                    </Col>
                    <Col lg={3} xl={3}>
                      <button
                        type="button"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                          color: "#657885",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              sortDirection: !this.state.sortDirection,
                            },
                            this.fetchTasks(
                              null,
                              this.state.activePage,
                              "account_state"
                            )
                          )
                        }
                      >
                        <i className="fa fa-long-arrow-up"></i>
                        <i className="fa fa-long-arrow-down"></i>
                      </button>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.tasks?.map((task) => {
                const {
                  name,
                  category_name,
                  category_id,
                  date,
                  start_time,
                  end_time,
                  account_name,
                  contact_name,
                  opportunity_name,
                  account_id,
                  contact_id,
                  opportunity_id,
                  assigned_to_name,
                  id,
                  type,
                  account_city,
                  account_state,
                } = task;
                let newTabData = {};
                let assignedTo = "";
                if (account_name !== null) {
                  assignedTo = account_name;
                  newTabData = {
                    type: "account",
                    id: account_id,
                    name: account_name,
                  };
                } else if (contact_name !== null) {
                  assignedTo = contact_name;
                  newTabData = {
                    type: "contact",
                    id: contact_id,
                    name: contact_name,
                  };
                } else {
                  assignedTo = opportunity_name;
                  newTabData = {
                    type: "opportunity",
                    id: opportunity_id,
                    name: opportunity_name,
                    account_id: account_id,
                  };
                }
                const newDate = date
                  ? new Date(date).getTimezoneOffset() < 0
                    ? new Date(
                        new Date(date).getTime() -
                          new Date(date).getTimezoneOffset() * 60000
                      )
                    : new Date(
                        new Date(date).getTime() +
                          new Date(date).getTimezoneOffset() * 60000
                      )
                  : new Date();
                const newStartTime =
                  start_time &&
                  moment(start_time, "HH:mm:ss").format("hh:mm A");
                const newEndTime =
                  end_time && moment(end_time, "HH:mm:ss").format("hh:mm A");
                console.log(
                  "hassan test on task",
                  newDate,
                  newStartTime,
                  newEndTime,
                  id
                );
                return (
                  <tr
                    key={id}
                    style={type === "overdue" ? { color: "red" } : {}}
                  >
                    <td>{assigned_to_name}</td>
                    <td className="inlineReportEdit">
                      <span
                        className="inlineReportEdit-span"
                        style={
                          this.state.inputFocused.id === id &&
                          this.state.inputFocused.isTrue &&
                          this.state.inputFocused.name === "name"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {name}
                      </span>
                      <input
                        className="inlineReportEdit-input"
                        value={name}
                        onChange={(e) =>
                          this.fieldChangeHandler(task, "name", e.target.value)
                        }
                        onFocus={() =>
                          this.setState({
                            inputFocused: {
                              id,
                              isTrue: true,
                              name: "name",
                            },
                          })
                        }
                        onBlur={() =>
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          })
                        }
                      />
                    </td>
                    <td
                      style={{ cursor: "pointer", color: "#4A9876" }}
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          this.props.setTab({ ...newTabData, blank: true });
                        } else {
                          this.props.setActiveTabIndex(
                            this.props.totalTabs + 1
                          );
                          this.props.setTab(newTabData);
                          this.props.history.push("/active-tabs");
                        }
                      }}
                    >
                      {assignedTo}
                    </td>
                    <td className="inlineReportEdit">
                      <span
                        className="inlineReportEdit-span"
                        style={
                          this.state.inputFocused.id === id &&
                          this.state.inputFocused.isTrue &&
                          this.state.inputFocused.name === "category_id"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {category_name}
                      </span>
                      <select
                        className="inlineReportEdit-input"
                        value={category_id}
                        onChange={(e) =>
                          this.fieldChangeHandler(
                            task,
                            "category_id",
                            e.target.value
                          )
                        }
                        onFocus={() =>
                          this.setState({
                            inputFocused: {
                              id,
                              isTrue: true,
                              name: "category_id",
                            },
                          })
                        }
                        onBlur={() =>
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          })
                        }
                      >
                        {this.state.categories?.map((cat) => (
                          <option value={cat.id}>{cat.name}</option>
                        ))}
                      </select>
                    </td>
                    <td className="inlineReportEdit">
                      <span
                        className="inlineReportEdit-span"
                        style={
                          this.state.inputFocused.id === id &&
                          this.state.inputFocused.isTrue &&
                          this.state.inputFocused.name === "date"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {date && moment(date).format(dateFormat)}
                      </span>
                      <DatePicker
                        onFocus={() =>
                          this.setState({
                            inputFocused: {
                              id,
                              isTrue: true,
                              name: "date",
                            },
                          })
                        }
                        onBlur={() =>
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          })
                        }
                        selected={newDate}
                        autoComplete="off"
                        todayButton="Today"
                        utcOffset={0}
                        value={newDate}
                        onChange={(date) => {
                          this.fieldChangeHandler(task, "date", date);
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          });
                        }}
                        placeholderText="Date"
                        dateFormat={
                          this.props.companyOnboarding?.date_format ===
                          "DD/MM/YYYY"
                            ? "dd/MM/yyyy"
                            : "MM/dd/yyyy"
                        }
                        className="inlineReportEdit-input"
                      />
                    </td>
                    <td className="inlineReportEdit">
                      <span
                        className="inlineReportEdit-span"
                        style={
                          this.state.inputFocused.id === id &&
                          this.state.inputFocused.isTrue &&
                          this.state.inputFocused.name === "start_time"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {start_time &&
                          moment(start_time, "HH:mm:ss").format("hh:mm A")}
                      </span>
                      <DatePicker
                        onFocus={() =>
                          this.setState({
                            inputFocused: {
                              id,
                              isTrue: true,
                              name: "start_time",
                            },
                          })
                        }
                        onBlur={() =>
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          })
                        }
                        selected={Date.parse(newStartTime)}
                        autoComplete="off"
                        onChange={(date) => {
                          this.fieldChangeHandler(task, "start_time", date);
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          });
                        }}
                        value={newStartTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Start Time"
                        className="inlineReportEdit-input"
                      />
                    </td>
                    <td className="inlineReportEdit">
                      <span
                        className="inlineReportEdit-span"
                        style={
                          this.state.inputFocused.id === id &&
                          this.state.inputFocused.isTrue &&
                          this.state.inputFocused.name === "end_time"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {end_time &&
                          moment(end_time, "HH:mm:ss").format("hh:mm A")}
                      </span>
                      <DatePicker
                        onFocus={() =>
                          this.setState({
                            inputFocused: {
                              id,
                              isTrue: true,
                              name: "end_time",
                            },
                          })
                        }
                        onBlur={() =>
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          })
                        }
                        selected={Date.parse(newEndTime)}
                        autoComplete="off"
                        onChange={(date) => {
                          this.fieldChangeHandler(task, "end_time", date);
                          this.setState({
                            inputFocused: {
                              id: "",
                              isTrue: false,
                              name: "",
                            },
                          });
                        }}
                        value={newEndTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="End Time"
                        className="inlineReportEdit-input"
                      />
                    </td>
                    <td className="inlineReportEdit">{account_city}</td>
                    <td className="inlineReportEdit">{account_state}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchTasks(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    reportsFilters: state.reportsFilters.reports,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(TasksList);

import React, { Component } from "react";
import axios from "axios";
import "../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import CustomerModal from "./CustomerModal";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { colourStyles } from "../constants/selectStyles";
// import GoogleAutocompleteComponent from "./GoogleComponents/GoogleAutocompleteComponent";
import DropdownDashboard from "./Dropdown/DropdownDashboard";
import CreateAccountModal from "./CreateModal/CreateAccountModal";
import { AsYouType } from "libphonenumber-js";
import {
  usStatesList,
  usStatesListWithTimeZones,
} from "../constants/usStatesList";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
  setReportsFilters,
} from "../redux";
import { getCurrencyValue } from "../utils/Helper/reusableFunctions";
import { CurrencyList } from "../constants/currencylist";
import { Plus } from "lucide-react";

class AccountsList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    showContactModal: false,
    showNewAccountModal: false,
    sortDirection: true,
    isApplying: true,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    userName: this.props.authUser.name,
    user_ids: this.props.userId || this.props.authUser.id,
    displayMenu: false,
    displayUserName: this.props.userName || this.props.authUser.name,
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    price: null,
    equipmentTypes: [],
    speciaRequirements: [],
    modes: [],
    daysOfWeek: [],
    sortKey: "created_date",
    customFieldArray: [],
    statesFilter: [],
    timezoneFilter: [],
    industryFilter: [],
    statusFilter: [],
  };
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewAccountModal = () => {
    this.setState({
      showNewAccountModal: true,
    });
  };
  handleCloseNewAccountModal = () => {
    this.setState({
      showNewAccountModal: false,
    });
  };

  fetchAccounts = debounce((query, page = 1, sortKey = "created_date") => {
    const {
      origin,
      destination,
      equipmentTypes,
      speciaRequirements,
      modes,
      price,
      daysOfWeek,
      statesFilter,
      timezoneFilter,
      industryFilter,
      user_ids,
      displayUserName,
      statusFilter,
    } = this.state;
    let url = `/accounts?_limit=30&_page=${page}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (user_ids) {
      url += `&user_ids=${user_ids}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (origin.city) {
      url += `&origin_city=${origin.city}`;
    } else if (origin.zip) {
      url += `&origin_zip=${origin.zip}`;
    } else if (origin.state) {
      url += `&origin_state=${origin.state}`;
    }
    if (destination.city) {
      url += `&destination_city=${destination.city}`;
    } else if (destination.zip) {
      url += `&destination_zip=${destination.zip}`;
    } else if (destination.state) {
      url += `&destination_state=${destination.state}`;
    }
    if (equipmentTypes && equipmentTypes.length > 0) {
      url += `&equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&equipment_type_ids=`;
    }
    if (speciaRequirements && speciaRequirements.length > 0) {
      url += `&requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&requirement_type_ids=`;
    }
    if (modes && modes.length > 0) {
      url += `&mode_ids=${modes?.map((type) => type.value).join(",")}`;
    } else {
      url += `&mode_ids=`;
    }
    if (daysOfWeek.length > 0) {
      url += `&days_of_week=${daysOfWeek.join(",")}`;
    } else {
      url += `&days_of_week=`;
    }
    if (price) {
      url += `&price=${price}`;
    }
    if (industryFilter?.length > 0) {
      url += `&cf_industry=${encodeURIComponent(
        industryFilter?.map((type) => type.value).join(",")
      )}`;
    }
    if (statusFilter?.length > 0) {
      url += `&status=${encodeURIComponent(
        statusFilter?.map((type) => type.value).join(",")
      )}`;
    }
    if (statesFilter?.length > 0 || timezoneFilter?.length > 0) {
      let states = [];
      if (timezoneFilter?.length > 0) {
        const arr = timezoneFilter?.flatMap((zone) => zone.value);
        states = arr.filter((item, index) => arr.indexOf(item) === index);
      }
      if (statesFilter?.length > 0) {
        const newStates = states.concat(
          statesFilter?.map((state) => state.value)
        );
        states = newStates.filter(
          (item, index) => newStates.indexOf(item) === index
        );
      }
      url += `&state=${encodeURIComponent(states.join(","))}`;
    }
    this.props.setReportsFilters({
      id: "AccountsList",
      filters: {
        query,
        activePage: page,
        sortKey,
        sortDirection: this.state.sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        industryFilter,
        statesFilter,
        timezoneFilter,
        user_ids,
        displayUserName,
        statusFilter,
      },
    });
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.accounts,
          total: response.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  getCustomFields = () => {
    this.setState({
      customFields: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field, index) => {
            const { label, name, id, type, choices } = field;
            return {
              name: label,
              key: name,
              custom: true,
              id,
              type,
              choices,
              editable: true,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState({
      // columnsArray: columnsArray.concat(customFieldArray),
      customFieldArray: customFieldArray,
      customFieldRevenueTypeCheck: objTypeCheck,
    });
  };
  logCall = (description, selectedAccount) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #4A9876;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        account_id: selectedAccount.id,
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedAccount = this.state.accounts?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedAccount) {
        this.logCall(data.call, selectedAccount);
      }
    }
  };
  daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [
        ...this.state.daysOfWeek.filter((day) => day !== ""),
        value,
      ];
      this.setState({
        daysOfWeek: newDays,
      });
    } else {
      const newDays = this.state.daysOfWeek.filter((pc) => pc !== value);
      this.setState({
        daysOfWeek: newDays,
      });
    }
  };
  fetchAllReportsFilters = () => {
    const selectedReport = this.props.reportsFilters?.find(
      (report) => report.id === "AccountsList"
    );
    if (this.props.reportsFilters && selectedReport) {
      const {
        query,
        activePage,
        sortKey,
        sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        timezoneFilter,
        industryFilter,
        statesFilter,
        user_ids,
        displayUserName,
        statusFilter,
      } = selectedReport.filters;
      this.setState(
        {
          query,
          activePage,
          sortKey,
          sortDirection,
          origin,
          destination,
          equipmentTypes,
          speciaRequirements,
          modes,
          price,
          daysOfWeek,
          timezoneFilter,
          industryFilter,
          statesFilter,
          user_ids,
          displayUserName,
          statusFilter,
        },
        () =>
          this.fetchAccounts(
            this.state.query,
            this.state.activePage,
            this.state.sortKey
          )
      );
    } else {
      this.fetchAccounts();
    }
  };
  componentDidMount() {
    window.addEventListener("message", this.handleClickToDial);
    this.getCustomFields();
    this.fetchAllReportsFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.origin.address !== prevState.origin.address ||
      this.state.destination.address !== prevState.destination.address ||
      this.state.price !== prevState.price ||
      this.state.equipmentTypes?.length !== prevState.equipmentTypes?.length ||
      this.state.speciaRequirements?.length !==
        prevState.speciaRequirements?.length ||
      this.state.modes?.length !== prevState.modes?.length ||
      this.state.industryFilter?.length !== prevState.industryFilter?.length ||
      this.state.statesFilter?.length !== prevState.statesFilter?.length ||
      this.state.statusFilter?.length !== prevState.statusFilter?.length ||
      this.state.timezoneFilter?.length !== prevState.timezoneFilter?.length ||
      this.state.daysOfWeek?.length !== prevState.daysOfWeek?.length
    ) {
      this.fetchAccounts(this.state.query, 1, this.state.sortKey);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleOriginPlaceSelect = (place) => {
    this.setState({
      origin: place,
    });
  };
  handleDestinationPlaceSelect = (place) => {
    this.setState({
      destination: place,
    });
  };
  changeFilterHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  tableHeaders = [
    {
      value: "owners",
      label: "Owners",
      sort: false,
      editable: false,
      type: "text",
    },
    {
      value: "account_name",
      label: "Name",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "status",
      label: "Status",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "website",
      label: "website",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "email",
      label: "email",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "formatted_phone",
      label: "phone",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "state",
      label: "state",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "created_date",
      label: "created date",
      sort: true,
      editable: false,
      type: "date",
    },
    {
      value: "last_activity_date",
      label: "last Activity Date",
      sort: true,
      editable: false,
      type: "date",
    },

    {
      value: "cf_employees",
      label: "Employees",
      sort: true,
      editable: true,
      type: "text",
      custom: true,
    },
    {
      value: "cf_annual_revenue",
      label: "Annual Revenue",
      sort: true,
      editable: true,
      type: "decimal",
      custom: true,
    },
  ];
  renderTableData = (accountData, tableData, name, value, dateFormat) => {
    let newTabData = {
      type: "account",
      id: accountData.id,
      name: accountData.name,
      phone: accountData.phone,
      formatted_phone: accountData.formatted_phone,
    };
    let renderItem = <td>{value}</td>;
    if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(accountData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "cf_annual_revenue") {
      const currency = this.props.companyOnboarding?.currency;
      const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
      renderItem = (
        <td>
          {value
            ? currencySymbol.symbol +
              getCurrencyValue(value)?.toLocaleString("en-US")
            : null}
        </td>
      );
    } else if (name === "owners") {
      renderItem = <td>{value?.map((val) => val.name)?.join(", ")}</td>;
    } else if (name === "website") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
            >
              <a
                href={
                  value
                    ? value.startsWith("https://") ||
                      value.startsWith("http://")
                      ? value
                      : "https://" + value
                    : null
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: "pointer",
                  color: "#4A9876",
                }}
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(accountData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { color: "#4A9876" }
              }
            >
              <a
                target="_blank"
                style={{ color: "#4A9876", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(accountData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
    } else if (tableData.type === "dropdown" && name === "status") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={this.state.status}
            onChange={(e) => {
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              );
              this.statusChangeHandler(e);
            }}
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {this.props.allAccountStatuses?.map((status) => (
              <option
                style={{ textTransform: "capitalize" }}
                key={status.id}
                value={status.name}
              >
                {status.name}
              </option>
            ))}
          </select>
        </td>
      );
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(accountData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {usStatesList?.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (account, name, value, isCustom) => {
    let accountData = account;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }

    //same approach used in unassigned leads page
    accountData = isCustom
      ? {
          ...account,
          custom_fields: {
            ...account.custom_fields,
            [name]: formattedValue === "" ? null : formattedValue,
          },
        }
      : {
          ...account,
          [name]: formattedValue === "" ? null : formattedValue,
        };

    //same approach used in unassigned leads page
    this.setState({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          if (isCustom) {
            acc = {
              ...account,
              custom_fields: {
                ...account.custom_fields,
                [name]: formattedValue === "" ? null : formattedValue,
              },
            };
          } else {
            acc = {
              ...account,
              [name]: formattedValue === "" ? null : formattedValue,
            };
          }
        }
        return acc;
      }),
    });
    this.submitHandler(accountData);
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
        query: "",
        activePage: 1,
        sortKey: "created_date",
      },
      () => this.fetchAccounts()
    );
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  statusChangeHandler = (e) => {
    const { value } = e.target;

    this.setState({
      status: value,
    });
    axios({
      method: "PUT",
      url: `/accounts/${this.state.inputFocused?.id}/status`,
      data: {
        status: value,
      },
    })
      .then((res) => {
        // NotificationManager.success("Successfully changed status");
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };
  submitHandler = debounce((accountData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      accountData.formatted_phone !== null &&
      accountData.formatted_phone !== "" &&
      !phoneValidator.test(accountData.formatted_phone)
    ) {
      return false;
    }
    if (
      accountData.email !== null &&
      accountData.email !== "" &&
      !emailValidator.test(accountData.email)
    ) {
      return false;
    }
    if (!accountData.name) {
      return false;
    }
    axios({
      method: "PUT",
      url: `/accounts/${accountData.id}`,
      data: {
        name: accountData.name,
        state: accountData.state,
        country: accountData.country,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        source: accountData.source,
        email: accountData.email,
        formatted_phone: accountData.formatted_phone,
        phone_ext: accountData.phone_ext,
        website: accountData.website
          ? accountData.website.startsWith("https://") ||
            accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website
          : null,
        description: accountData.description,
        preferred_communication: accountData.preferred_communication?.filter(
          (pref) => pref !== ""
        ),
        custom_fields: accountData.custom_fields,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  }, 500);

  render() {
    const accounts = this.state.accounts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const industryArray = this.state.customFieldArray?.find(
      (custom) => custom.key === "cf_industry"
    );
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Accounts <span style={{ color: "#4A9876" }}>{this.state.total}</span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Accounts"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchAccounts(value, 1, this.state.sortKey);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            className="button-md"
            type="button"
            onClick={
              this.props.isAccountFieldsModified
                ? this.handleOpenNewAccountModal
                : this.handleOpenContactModal
            }
            style={{ float: "right" }}
          >
            Add Account
            <Plus
              size={14}
              strokeWidth={3}
              color="#62CA9D"
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
        </div>
        {/* {this.props.companyOnboarding.industry === "Freight Brokerage" && (
          <>
            <div
              className="lanes-panel-form"
              style={{
                display: "grid",
                gridTemplateColumns: "310px 310px 310px",
              }}
            >
              <div>
                <label>Origin</label>
                <GoogleAutocompleteComponent
                  onPlaceSelect={this.handleOriginPlaceSelect}
                  valueProps={this.state.origin.address}
                />
              </div>
              <div>
                <label>Destination</label>
                <GoogleAutocompleteComponent
                  onPlaceSelect={this.handleDestinationPlaceSelect}
                  valueProps={this.state.destination.address}
                />
              </div>
            </div>
            <div className="lanes-panel-form-1">
              <div className="lanes-panel-form-1-div">
                <label>Equipment Types</label>
                <Select
                  value={this.state.equipmentTypes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={colourStyles}
                  options={this.props.allEquipmentTypes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    this.setState({
                      equipmentTypes: selectedOptions,
                    })
                  }
                />
              </div>
              <div className="lanes-panel-form-1-div">
                <label>Modes</label>
                <Select
                  value={this.state.modes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={colourStyles}
                  options={this.props.allModes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    this.setState({
                      modes: selectedOptions,
                    })
                  }
                />
              </div>
              <div className="lanes-panel-form-1-div">
                <label>Special Requirements</label>
                <Select
                  value={this.state.speciaRequirements}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={colourStyles}
                  options={this.props.allSpecialRequirements?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    this.setState({
                      speciaRequirements: selectedOptions,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <label
                style={{
                  color: "#586874",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Days of Week{" "}
                <span style={{ fontSize: "12px" }}>
                  (What days the freight ships out for this lane)
                </span>
              </label>
              <div
                className="modal-radioButton c-radioButton"
                style={{ marginTop: "5px" }}
              >
                {this.days.map((day) => (
                  <span key={day}>
                    <label
                      htmlFor={`week_${day}`}
                      style={
                        this.state.daysOfWeek.includes(day)
                          ? {
                              border: "2px solid #4a9876",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "8px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              textTransform: "uppercase",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "8px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                              textTransform: "uppercase",
                            }
                      }
                    >
                      {day}
                      <input
                        type="checkbox"
                        name="category"
                        id={`week_${day}`}
                        className="radio-button"
                        checked={this.state.daysOfWeek.includes(day)}
                        value={day}
                        onChange={(e) => this.daysOfWeekChangeHandler(e)}
                      />
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </>
        )} */}
        <div className="lanes-panel-form-1">
          <div className="lanes-panel-form-1-div">
            <label>Industry</label>
            <Select
              value={this.state.industryFilter}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={industryArray?.choices?.map((type) => {
                return { value: type.value, label: type.value };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  industryFilter: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>State</label>
            <CreatableSelect
              isMulti
              value={this.state.statesFilter}
              formatCreateLabel={(inputText) => `Add "${inputText}"`}
              onChange={(selectedOptions) =>
                this.setState({
                  statesFilter: selectedOptions,
                })
              }
              options={usStatesList?.map((status) => {
                return {
                  label: status,
                  value: status,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Timezone</label>
            <Select
              isMulti
              value={this.state.timezoneFilter}
              onChange={(selectedOptions) =>
                this.setState({
                  timezoneFilter: selectedOptions,
                })
              }
              options={usStatesListWithTimeZones?.map((time) => {
                return {
                  label: time.label,
                  value: time.states,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Status</label>
            <Select
              isMulti
              value={this.state.statusFilter}
              formatCreateLabel={(inputText) => `Add "${inputText}"`}
              onChange={(selectedOptions) =>
                this.setState({
                  statusFilter: selectedOptions,
                })
              }
              options={this.props.allAccountStatuses?.map((status) => {
                return {
                  label: status.name,
                  value: status.name,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(this.state.query, page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && this.state.total === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No accounts found
          </div>
        ) : (
          <div
            style={{
              width: "96vw",
              overflowX: "auto",
              height: "auto",
            }}
          >
            <table className="accountList__table">
              <thead>
                <tr>
                  {this.tableHeaders.map((headers) => (
                    <th key={headers.label}>
                      <Row>
                        <Col lg={9} xl={9}>
                          {headers.label}
                        </Col>
                        <Col lg={3} xl={3}>
                          {headers.sort && (
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                                color: "#657885",
                              }}
                              onClick={() => {
                                const sortKey = headers.value.startsWith("cf_")
                                  ? this.state.customFieldArray?.find(
                                      (item) => item.key === headers.value
                                    )?.id || headers.value
                                  : headers.value;

                                this.setState(
                                  {
                                    sortDirection: !this.state.sortDirection,
                                    sortKey: sortKey,
                                  },
                                  this.fetchAccounts(
                                    this.state.query,
                                    this.state.activePage,
                                    sortKey
                                  )
                                );
                              }}
                            >
                              <i className="fa fa-long-arrow-up"></i>
                              <i className="fa fa-long-arrow-down"></i>
                            </button>
                          )}
                        </Col>
                      </Row>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {accounts?.map((account) => {
                  return (
                    <tr key={account.id}>
                      {this.tableHeaders?.map((header) =>
                        this.renderTableData(
                          account,
                          header,
                          header.value === "account_name"
                            ? "name"
                            : header.value,
                          account[
                            header.value === "account_name"
                              ? "name"
                              : header.value
                          ] || account.custom_fields[header.value],
                          dateFormat
                        )
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {this.state.showContactModal && (
          <CustomerModal
            showOpportunityModal={this.state.showContactModal}
            setShowOpportunityModal={this.handleOpenContactModal}
            handleCloseModal={this.handleCloseContactModal}
          />
        )}
        {this.state.showNewAccountModal && (
          <CreateAccountModal
            showOpportunityModal={this.state.showNewAccountModal}
            setShowOpportunityModal={this.handleOpenNewAccountModal}
            handleCloseModal={this.handleCloseNewAccountModal}
          />
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(this.state.query, page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    isAccountFieldsModified: state.allAccountFields.isModified,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    reportsFilters: state.reportsFilters.reports,
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(AccountsList);

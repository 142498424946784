import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Plus } from "lucide-react";
import CreateScheduleReport from "./Modals/CreateScheduleReport";

import { useDispatch, useSelector } from "react-redux";
import DeleteScheduleReport from "./Modals/DeleteScheduleReport";
import EditScheduleReport from "./Modals/EditScheduleReport";
import { debounce } from "lodash";

import { setActiveTabIndex, setTab } from "../../redux";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#4A9876",
    boxShadow: "none",
    cursor: "pointer",
    ":active": {
      ...styles[":active"],
      border: "2px solid #4A9876",
      outline: "#4A9876",
    },
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #4A9876",
    },
    ":focus": {
      ...styles[":hover"],
      border: "1px solid #4A9876",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

const ScheduleReport = ({ history }) => {
  const [showCreateReortModal, setShowCreateReortModal] = useState(false);
  const [showEditReportModal, setShowEditReportsModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const allUsers = useSelector((state) =>
    state.allUsers.users.map((item) => ({
      id: item.id,
      name: item.name,
      type: "user",
    }))
  );
  const [teams, setTeams] = useState([]);
  const userTeamArr = [...allUsers, ...teams] || [];
  const [reportsList, setReportsList] = useState();
  const [allReports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [reportQuery, setReportQuery] = useState("");
  const [userReportQuery, setUserReportQuery] = useState("");

  const days = moment.weekdays().map((day, index) => ({
    value: index.toString(),
    label: day,
  }));

  const monthDays = [...Array(28)].map((_, i) => ({
    value: (i + 1).toString(),
    label: moment()
      .date(i + 1)
      .format("Do"),
  }));

  const convertToLocalTime = (utcTime) => {
    const today = moment.utc().format("YYYY-MM-DD");
    return moment.utc(`${today} ${utcTime}`).local().format("hh:mm A");
  };

  const dispatch = useDispatch();

  const totalTabs = useSelector((state) => state.tabsState.tabs.length);

  const tableHeaders = [
    "Name",
    "Report",
    "Audience",
    "Frequency",
    "Time",
    "Actions",
  ];

  const handleReportClick = (report, event) => {
    const newTabData = {
      type: "report",
      id: report?.report_id,
      name: report?.report_name,
    };

    if (event.metaKey || event.ctrlKey) {
      dispatch(setTab({ ...newTabData, blank: true }));
    } else {
      dispatch(setActiveTabIndex(totalTabs + 1));
      dispatch(setTab(newTabData));
      history.push("/active-tabs");
    }
  };

  const getAllScheduleReports = debounce((query) => {
    setLoading(true);

    let url = `/reports/schedule?`;
    if (query) {
      url += `name=${encodeURIComponent(query)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setReportsList(res.data);
      setLoading(false);
    });
  }, 500);

  const fetchTeams = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/dashboard/teams`,
    }).then((res) => {
      setTeams(
        res.data.teams.map((item) => ({
          id: item.team_id,
          name: item.team_name,
          type: "team",
        }))
      );
      setLoading(false);
    });
  };
  const fetchReports = debounce(() => {
    setIsReportsLoading(true);

    let url = `/reports?_limit=50&_page=1&type=all&sort_key=name&sort_dir=asc`;
    if (userReportQuery) {
      url += `&name=${encodeURIComponent(userReportQuery)}`;
    }

    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      if (userReportQuery) {
        setReports((prevReports) => {
          const newReports = res.data.reports.map((item) => ({
            id: item.id,
            name: item.name,
          }));

          // Combine arrays and remove duplicates based on id
          const combinedReports = [...prevReports, ...newReports];
          const uniqueReports = Array.from(
            new Map(
              combinedReports.map((report) => [report.id, report])
            ).values()
          );

          return uniqueReports;
        });
      } else {
        setReports(
          res.data.reports.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
      setIsReportsLoading(false);
    });
  }, 500);

  useEffect(() => {
    getAllScheduleReports();
    fetchTeams();
    fetchReports();
  }, []);
  useEffect(() => {
    if (userReportQuery) {
      fetchReports();
    }
  }, [userReportQuery]);

  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Schedule Report{" "}
          <span style={{ color: "#4A9876" }}>
            {(reportsList && reportsList?.total) || ""}
          </span>
        </h2>
        <button
          className="button-md"
          style={{ float: "right", marginTop: "10px" }}
          type="button"
          onClick={() => setShowCreateReortModal(true)}
        >
          Create{" "}
          <Plus
            size={14}
            strokeWidth={3}
            color="#62CA9D"
            style={{
              transform: "translateY(2px)",
              marginLeft: "5px",
            }}
          />
        </button>

        <input
          type="search"
          className="reports-search"
          placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Schedule Reports"
          style={{ marginTop: "10px", marginBottom: "5px" }}
          value={reportQuery}
          onChange={({ target: { value } }) => {
            getAllScheduleReports(value);
            setReportQuery(value);
          }}
        />
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : reportsList && reportsList?.scheduled_reports?.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
          }}
        >
          No records found.
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((headers) => (
                <th key={headers}>{headers}</th>
              ))}
            </tr>
          </thead>
          {reportsList && Array.isArray(reportsList?.scheduled_reports) && (
            <tbody style={{ fontSize: "16px" }}>
              {reportsList?.scheduled_reports?.map((report) => {
                const totalDays =
                  report.days_of_week !== null
                    ? report.days_of_week.split(",").length
                    : "";

                const users = report.audiences
                  .filter((a) => a.audience_type === "user")
                  .map(
                    (a) => userTeamArr.find((u) => u.id === a.audience_id)?.name
                  )
                  .filter(Boolean);

                const teams = report.audiences
                  .filter((a) => a.audience_type === "team")
                  .map(
                    (a) => userTeamArr.find((t) => t.id === a.audience_id)?.name
                  )
                  .filter(Boolean);

                return (
                  <tr key={report.id}>
                    <td>{report.name}</td>
                    <td
                      style={{ cursor: "pointer", color: "#4A9876" }}
                      onClick={(e) => handleReportClick(report, e)}
                    >
                      {report.report_name}
                    </td>
                    <td>
                      {" "}
                      {users.length > 0 && <div>Users: {users.join(", ")}</div>}
                      {teams.length > 0 && (
                        <div className="">Teams: {teams.join(", ")}</div>
                      )}
                    </td>

                    <td>
                      {totalDays === 7
                        ? "Daily: Monday - Sunday"
                        : totalDays === 5
                        ? "Daily: Monday - Friday"
                        : report.days_of_week !== null
                        ? `Weekly: ${
                            days.filter(
                              (item) => item.value === report.days_of_week
                            )?.[0]?.label || ""
                          }`
                        : report.day_of_month !== null
                        ? `Monthly: ${
                            monthDays.filter(
                              (item) =>
                                item.value === report.day_of_month.toString()
                            )?.[0]?.label || ""
                          }`
                        : ""}
                    </td>

                    <td>{convertToLocalTime(report?.utc_time)}</td>
                    <td>
                      <button
                        type="button"
                        className="button-sm"
                        onClick={() => {
                          setSelectedReport(report);
                          setShowEditReportsModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedReport(report);
                          setShowDeleteReportsModal(true);
                        }}
                        className="button-sm"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      )}
      {showCreateReortModal && (
        <CreateScheduleReport
          showModal={showCreateReortModal}
          handleCloseModal={() => setShowCreateReortModal(false)}
          modalHeight={`75`}
          modalTop={`15`}
          audience={userTeamArr}
          reports={allReports}
          colourStyles={colourStyles}
          getAllScheduleReports={getAllScheduleReports}
          days={days}
          monthDays={monthDays}
          setQuery={setUserReportQuery}
          isReportsLoading={isReportsLoading}
        />
      )}

      {showEditReportModal && (
        <EditScheduleReport
          showModal={showEditReportModal}
          handleCloseModal={() => setShowEditReportsModal(false)}
          modalHeight={`75`}
          modalTop={`15`}
          getAllScheduleReports={getAllScheduleReports}
          reportData={selectedReport}
          colourStyles={colourStyles}
          days={days}
          monthDays={monthDays}
          audience={userTeamArr}
          reports={allReports}
          setQuery={setUserReportQuery}
          isReportsLoading={isReportsLoading}
        />
      )}
      {showDeleteReportModal && (
        <DeleteScheduleReport
          showModal={showDeleteReportModal}
          handleCloseModal={() => setShowDeleteReportsModal(false)}
          modalHeight={`30`}
          modalTop={`25`}
          getAllScheduleReports={getAllScheduleReports}
          reportData={selectedReport}
        />
      )}
    </div>
  );
};

export default ScheduleReport;
